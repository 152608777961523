import { Injectable }               from '@angular/core';
import { ISortableFilter }          from '../common/sortable-filter';
import { Globals }                  from '../common/globals';
import { HttpClient } from '@angular/common/http';
import { Observable }               from 'rxjs';
import { CommonService }            from './common.service';
import { SurveyModel, SurveyQuestion } from '../survey/survey.model';

@Injectable()
export class SurveyService {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'survey');
    }

    public selectQuestions(filter: ISortableFilter): Observable<any> {
        return this._commonService.post('selectQuestions', filter);
    }

    public selectSurveys(filter: ISortableFilter): Observable<any> {
        return this._commonService.post('selectSurveys', filter);
    }

    public getQuestion(id: string): Observable<SurveyQuestion> {
        return this._commonService.get('getQuestion/' + id);
    }

    public getSurvey(id: string): Observable<SurveyModel> {
        return this._commonService.get('getSurvey/' + id);
    }

    public insertQuestion(model: SurveyQuestion): Observable<any> {
        return this._commonService.post('insertQuestion', model);
    }

    public insertSurvey(model: SurveyModel): Observable<any> {
        return this._commonService.post('insertSurvey', model);
    }

    public updateQuestion(model: SurveyQuestion, id: string): Observable<any> {
        return this._commonService.updateItem('updateQuestion/' + id, model);
    }

    public updateSurvey(id: string, model: SurveyModel): Observable<any> {
        return this._commonService.updateItem('updateSurvey/' + id, model);
    }

    public deleteQuestion(id: string): Observable<any> {
        return this._commonService.deleteItem('deleteQuestion/' + id);
    }

    public deleteSurvey(id: string): Observable<any> {
        return this._commonService.deleteItem('deleteSurvey/' + id);
    }

    public listQuestions(): Observable<SurveyQuestion[]> {
        return this._commonService.get('listQuestions');
    }

    public listSurveys(): Observable<SurveyModel[]> {
        return this._commonService.get('listSurveys');
    }

}
