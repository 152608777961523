export enum DateRangeEnum {
    undefined = 0,
    today = 1,
    last_days = 2,
    this_week = 3,
    last_week = 4,
    last_weeks = 5,
    this_month = 6,
    last_month = 7,
    last_months = 8,
    this_year = 9,
    last_year = 10,
    last_years = 11,
    custom = 12,
}
