export enum FilterEditorType {
    none = 0,
    text = 1,
    number = 2,
    numberRange = 3,
    date = 4,
    dateRange = 5,
    codeList = 6,
    productSuggester = 7,
    userSuggester = 8,
    userJobSuggester = 9,
    bit = 10
}
