import { ISortableFilter }          from '../common/sortable-filter';
import { Globals }                  from '../common/globals';
import { HttpClient }               from '@angular/common/http';
import { Observable }               from 'rxjs';
import { CommonService }            from './common.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ComponentService {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'component');
    }

    public getItems(filter: ISortableFilter): Observable<any> {
        return this._commonService.get('list', filter);
    }

    public getDetail(id: number): Observable<any> {
        return this._commonService.get('detail/' + id);
    }

    public getMenu(): Observable<any> {
        return this._commonService.get('menu');
    }

    public insertItem(item: any): Observable<any> {
        return this._commonService.insertItem(item);
    }

    public updateItem(id: string, item: any): Observable<any> {
       return this._commonService.updateItem(id, item);
    }

    public deleteItem(id: string): Observable<any> {
        return this._commonService.deleteItem(id);
    }
}
