import { Component, OnInit, Renderer2, forwardRef, ViewChild, Input, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ViewContainerRef, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Honeycomb } from 'src/app/shared/honeycomb-api/honeycomb-api';

@Component({
  selector: 'hc-grid-multiselect-editor',
  templateUrl: './grid-multiselect-editor.component.html',
  styleUrls: ['./grid-multiselect-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => GridMultiselectEditorComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridMultiselectEditorComponent implements OnInit, ICellEditorAngularComp, AfterViewInit {

  @ViewChild('container', { read: ViewContainerRef, static: true }) public container;

  private params: any;
  public items: any[];

  public valueStr: string = null;
  public valueObj: any = null;
  public meta: any = null;

  @ViewChild('codeListSelect', {static: false}) codeListSelect: ElementRef;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
      window.setTimeout(() => {
          this.container.element.nativeElement.focus();
      });
      this.cd.detectChanges();
  }

  async agInit(params: any) {
      this.params = params;
      this.valueObj = params.value;

      this.meta = this.params.node.data[-1];

      this.items = this.meta.items.map(i => {
        let res = {
          id: null,
          name: null,
          selected: false
        };
        res.id = i[this.meta.valueProp];
        res.name = i[this.meta.displayProp];
        res.selected = params.value.jobPermissionTypeIDs.indexOf(i[this.meta.valueProp]) > -1
        return res;
     })
      
      this.setCodeLists();
    
      this.cd.detectChanges();
  }

  setCodeLists() {
    //  this.codeValues = this.codeChacheService.getCodeListItems(this.paramsData.codeListId);
//      this.codeListReady = (this.codeValues != null);
      window.setTimeout(() => {
          if (!!this.codeListSelect) {
              this.codeListSelect.nativeElement.focus();
          }
          //   this.codeListSelect.nativeElement.click();
      });
  }

  getValue(): any {
    this.valueObj.jobPermissionTypeIDs = this.items.filter(i => !!i.selected).map(i => i.id);
    return this.valueObj;
  }

  isPopup(): boolean {
      return true;
  }

  public boolCheckedChanged($event: MatCheckboxChange) {
      this.valueStr = $event.checked ? 'True' : 'False';
  }

  public close() {
      this.params.api.stopEditing();
  }

  public listOptionSelected($event, option: any) {
      $event.stopPropagation();
      this.valueStr = option.codeKey;
  }

  public focusIn() {
      console.log('FocusIn');
  }

  public focusOut() {
      console.log('FocusOut');
  }

  public optionSelected($event) {
      console.log($event);
  }

  onKeyDown(event): void {
      //   let key = event.which || event.keyCode;
      //   if (key === 37 ||  // left
      //       key === 39) {  // right
      event.stopPropagation();
      //   }
  }

}
