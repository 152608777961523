import { OnInit,
         Component,
         Inject,
         ChangeDetectorRef}                 from '@angular/core';
import { FilterDialog }                     from './models/filter-dialog.model';
import { FilterDialogConfig }               from './models/filter-dialog-config.model';
import { TranslationService }               from '../../shared/translation.service';
                  
import { FilterDialogItem }                 from './models/filter-dialog-item.model';
import { FilterViewModel }                  from '../../setting/filter/filter-view.model';
import { FilterDialogService }              from '../../shared/filter-dialog-service';
import { DataTypeEnum }                     from '../data-type.enum';
import { LocationService }                  from '../../shared/location.service';
import { SortableFilter,
         SortDirection }                    from '../sortable-filter';
import { CategoryMenuNodeModel }            from '../../product/category-node-model';
import { SavingDialogComponent }            from '../saving-dialog.component';
import { ProductsService }                     from '../../shared/products.service';
import { FilterDialogItemValues }          from './models/filter-dialog-filter-value.model';
import { Observable }                       from 'rxjs';
import { CodeListService } from '../../shared/code-list.service';
import { CodeList } from '../code-list.model';
import { PromptDialogComponent } from '../dialog.prompt.component';
import { MediaModel } from '../media.model';
import { MediaService } from '../../shared/media.service';
import { MediaListFilter } from '../../communication/media/media-list.filter';
import { SurveyService } from '../../shared/survey.service';
import { SurveyQuestion, QuestionAnswerType } from '../../survey/survey.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNullOrUndefined } from '../functions';

@Component({
    selector: 'hc-filter-dialog',
    templateUrl: './filter-dialog.component.html',
    styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {

    public model: FilterDialog;
    public filterTypes: FilterViewModel[];
    public selectedFilterType: FilterViewModel = null;
    public isNewFilter = false;
    public dataType = DataTypeEnum; // Enum
    public maxWindowHeight: string = Math.ceil(window.screen.height * 0.5) + 'px';
    public filterMethod: Function;
    public onSavedCallback: Function;
    public onSearchCallback: Function;
    public locations: any[];
    public categories: CategoryMenuNodeModel[];
    public codeLists: any = {};    // Key value map where key is codeListID and value are code list items
    public media: MediaModel[];
    public surveyQuestions: SurveyQuestion[];
    public answerType = QuestionAnswerType;

    constructor(
        public trans: TranslationService,
        public dialogRef: MatDialogRef<FilterDialogComponent>,
        private service: FilterDialogService,
        private productsService: ProductsService,
        private locationService: LocationService,
        private codeListService: CodeListService,
        private mediaService: MediaService,
        private surveyService: SurveyService,
        private dialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef,
        private snackbar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: FilterDialogConfig
    ) {
        this.filterTypes = data.filterTypes;
        dialogRef.disableClose = true;
        this.isNewFilter = data.isNew === true;
        this.model = data.filterDialog;
        this.filterMethod = data.filterMethod;
        this.onSavedCallback = data.onSaved || null;
        this.onSearchCallback = data.onSearch || null;
        this.locations = [];
        this.media = [];
        this.surveyQuestions = [];
        this.cacheDataTypes(() => this.checkPrefilledTypes());
    }

    ngOnInit(): void {
        if (this.isNewFilter) {
            this.model.name = this.trans.instant('admin.web.new-filter');
        }
    }

    public async add(dialogItem: FilterDialogItem) {
        if (!dialogItem) {
            dialogItem = FilterDialogItem.parseFromFilter(this.selectedFilterType);
            dialogItem.filterDialogID = this.model.id;
        }
        this.model.add(dialogItem);
        this.cacheDataTypes(() => {
            if (dialogItem.filterDialogItemValues.some(cdfv => cdfv.filterInputType.dataType === DataTypeEnum.Categories)) {
                dialogItem.categories = this.categories.slice(0);
            }
        });
    }

    public remove(dialogItem: FilterDialogItem) {
        this.model.remove(dialogItem);
    }

    public search() {
        this.onSearchCallback(this.model.getFilter());
        this.close();
    }

    public async saveAndSearch() {
        this.model.fixSurveyValues();
        if (this.isNewFilter) {
            let result: FilterDialog = await this.service.insertItem(this.model.serverModel).toPromise();
            if (result) {
                this.model = FilterDialog.parse(result, this.filterTypes);
                this.isNewFilter = false;
                this.onSavedCallback(this.model.id);
            } else {
                this.snackbar.open(this.trans.instant('admin.web.error-saving'), '', {duration: 2000});
            }
        } else {
            let result: FilterDialog = await this.service.updateItem(this.model.id, this.model.serverModel).toPromise();
            if (result) {
                // this.model = FilterDialog.parse(result, this.filterTypes);
                this.onSavedCallback(this.model.filterDialogID);
            } else {
                this.snackbar.open(this.trans.instant('admin.web.error-saving'), '', {duration: 2000});
            }
        }
        this.search();
    }

    public close() {
        this.dialogRef.close();
    }

    private getRequiredDataTypes(): DataTypeEnum[] {
        let required: DataTypeEnum[] = [];
        const requiredTypes: DataTypeEnum[] = [
            DataTypeEnum.Location,
            DataTypeEnum.Categories,
            DataTypeEnum.CodeList,
            DataTypeEnum.Media,
            DataTypeEnum.Survey
        ];
        this.model.filterDialogItems.forEach(filterDialogItem => {
            filterDialogItem.filterDialogItemValues.forEach(filterDialogFilterValue => {
                if (requiredTypes.indexOf(filterDialogFilterValue.filterInputType.dataType) > -1) {
                    if (!required.some(r => r === filterDialogFilterValue.filterInputType.dataType)) {
                        required.push(filterDialogFilterValue.filterInputType.dataType);
                    }
                }
            });
        });
        return required;
    }

    private async cacheDataTypes(callback: Function) {
        let requiredDataTypes = this.getRequiredDataTypes(),
            cloakRef = null;
        if (requiredDataTypes.length) {
            cloakRef = this.dialog.open(SavingDialogComponent);
        }
        for (let i = 0; i < requiredDataTypes.length; i++) {
            let dataType = requiredDataTypes[i];
            switch (dataType) {
                case DataTypeEnum.Categories:
                    let result = await this.productsService.getCategories('eshop').toPromise();
                    if (result.items) {
                        this.categories = result.items.map(cmnm => new CategoryMenuNodeModel(cmnm));
                    }
                    break;
                case DataTypeEnum.Location:
                    if (this.locations.length === 0) {
                        this.locations = await this.locationService
                            .getItems(new SortableFilter('LocationNumber', SortDirection.asc))
                            .toPromise();
                    }
                    break;
                case DataTypeEnum.CodeList:
                    await this.cacheCodeLists().toPromise();
                    break;
                case DataTypeEnum.Media:
                    if (this.media.length === 0) {
                        this.media = await this.mediaService
                            .getItems(new MediaListFilter('name', SortDirection.asc))
                            .toPromise();
                    }
                    break;
                case DataTypeEnum.Survey:
                    if (this.surveyQuestions.length === 0) {
                        this.surveyQuestions = await this.surveyService
                            .listQuestions().toPromise();
                    }
                    break;
            }
        }
        if (cloakRef !== null) {
            cloakRef.close();
        }
        if (callback) {
            callback();
        }
    }

    public delete() {
        let promptRef = this.dialog.open(PromptDialogComponent, {
            data: (this.trans.instant('admin.web.delete') + this.model.name + '?')
        });
        promptRef.afterClosed().subscribe(
            async res => {
                if (res === true) {
                    let cloakRef = this.dialog.open(SavingDialogComponent);
                    this.service.deleteItem(this.model.id).subscribe(
                        () => {
                            this.onSavedCallback();
                            this.close();
                            cloakRef.close();
                        },
                        error => {
                            cloakRef.close();
                            this.snackbar.open(this.trans.instant('admin.web.error-saving'), '', {duration: 2000});
                        });
                }
            }
        );
    }

    private cacheCodeLists(): Observable<any> {
        return new Observable(observer => {
            let codeListIDs: number[] = [];
            this.model.filterDialogItems.forEach(i => {
                i.filterDialogItemValues.forEach(v => {
                    if (v.filterInputType.dataType === DataTypeEnum.CodeList && !isNullOrUndefined(v.filterInputType.codeListID)) {
                        if (!this.codeLists.hasOwnProperty(v.filterInputType.codeListID)) {
                            codeListIDs.push(v.filterInputType.codeListID);
                        }
                    }
                });
            });
            if (codeListIDs.length) {
                this.codeListService.list(codeListIDs).subscribe(
                    (codeLists: CodeList[]) => {
                        codeLists.forEach(codeList => this.codeLists[codeList.codeListId] = codeList);
                        observer.complete();
                    }
                );
            } else {
                observer.complete();
            }
            return {unsubscribe() {}};
        });
    }

    private checkPrefilledTypes() {
        this.model.filterDialogItems.forEach(dialogItem => {
            dialogItem.filterDialogItemValues.forEach(filterValue => {
                switch (filterValue.filterInputType.dataType) {
                    case DataTypeEnum.Categories:
                        if (!dialogItem.categories || !dialogItem.categories.length) {
                            dialogItem.categories = this.categories.slice(0);
                            let values = filterValue.value.split(',');
                            filterValue.setValues(values, dialogItem.categories);
                            filterValue.onModelChanged(dialogItem.categories);
                        }
                        break;
                    case DataTypeEnum.Location:
                        filterValue.selectedModel = this.locations.find(loc => ('' + loc.locationID) === filterValue.value);
                        break;
                    case DataTypeEnum.CodeList:
                        filterValue.selectedModel = this.codeLists[filterValue.filterInputType.codeListID].codes
                            .find(c => c.codeKey === filterValue.value);
                        break;
                    case DataTypeEnum.Media:
                        filterValue.selectedModel = this.media.find(m => ('' + m.mediaID) === filterValue.value);
                        break;
                    case DataTypeEnum.Survey:
                        let value = JSON.parse(filterValue.value);
                        filterValue.surveyQuestion = this.surveyQuestions.find(q => q.surveyQuestionId === +Object.keys(value)[0]);
                        filterValue.selectedModel = filterValue.surveyQuestion;
                        if ([QuestionAnswerType.OneToTen, QuestionAnswerType.Stars5].indexOf(filterValue.surveyQuestion.answerType) > -1) {
                            // Stars or 1-10 rating
                            if (value[filterValue.surveyQuestion.surveyQuestionId]) {
                                filterValue.range = value[filterValue.surveyQuestion.surveyQuestionId];
                            }
                        } else if (filterValue.surveyQuestion.answerType === QuestionAnswerType.YesNo) {
                            // Boolean
                            filterValue.surveyQuestion.questionAnswers[0].isActive = value[filterValue.surveyQuestion.surveyQuestionId];
                        } else {
                            // Other
                            filterValue.surveyQuestion.questionAnswers.forEach(qa => {
                                qa.isActive = value[filterValue.surveyQuestion.surveyQuestionId].indexOf(qa.questionAnswerId) > -1;
                            });
                        }
                }
            });
        });
        this.changeDetectorRef.detectChanges();
    }

    public get codeListsLoaded(): boolean {
        return Object.keys(this.codeLists).length > 0;
    }

    public async loadSurveyQuestionAnswers(surveyQuestionId: string, filterDialogFilterValue: FilterDialogItemValues) {
        filterDialogFilterValue.value = surveyQuestionId;
        let cloakRef = this.dialog.open(SavingDialogComponent);
        filterDialogFilterValue.surveyQuestion = <SurveyQuestion>Object.assign( new SurveyQuestion(),
                                                        await this.surveyService.getQuestion(surveyQuestionId).toPromise());
        cloakRef.close();
    }
}
