import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import { TreeComponent, TreeNode } from '../../../../node_modules/angular-tree-component';
import { CategoryMenuNodeModel } from '../../product/category-node-model';

enum NodeState {
  UNECHKED,
  CHECKED,
  MARKED
}

@Component({
  selector: 'hc-expandable-tree',
  templateUrl: './expandable-tree.component.html',
  styleUrls: ['./expandable-tree.component.scss']
})
export class ExpandableTreeComponent {

  @ViewChild(TreeComponent, {static: true})
  private tree: TreeComponent;

  @Input() treeOptions;

  @Input() disabled = false;

  @Output() modelChanged = new EventEmitter();

  private _nodes;

  get nodes() {
    return this._nodes;
  }

  @Input() set nodes(nodes) {
    this._nodes = nodes;
    setTimeout(() => {
      this.tree.treeModel.nodes.forEach(n => this.checkSubNodes(n));
      this.modelChanged.emit(this.nodes);
    }, 100);
  }

  constructor() {
  }

  public toggleNode(node: TreeNode) {
    if (this.disabled) { return; }

    this.toggleNodeChildren(node.data);
    this.tree.treeModel.nodes.forEach(n => this.checkSubNodes(n));
    this.modelChanged.emit(this.nodes);
  }

  private toggleNodeChildren(node: CategoryMenuNodeModel) {
    if (this.disabled) { return; }

    if (node.items && node.items.length > 0) {
      node.items.forEach(i => {
        i.isSelected = node.isSelected;
        i.isMarked = false;
        this.toggleNodeChildren(i);
      });
    }
  }

  private checkSubNodes(node: CategoryMenuNodeModel): NodeState {
    let state = this.getStateFromNode(node);
    if (node.items.length > 0) {
      let checked = 0, marked = 0;
      node.items.forEach(n => {
        switch (this.checkSubNodes(n)) {
          case NodeState.CHECKED:
            checked++;
            break;
          case NodeState.MARKED:
            marked++;
            break;
        }
      });
      if (checked + marked === 0) {
        state = NodeState.UNECHKED;
      }
      if (checked < node.items.length && checked + marked > 0) {
        state = NodeState.MARKED;
      }
      if (checked === node.items.length) {
        state = NodeState.CHECKED;
      }
    }
    return this.setNodeByState(node, state);
  }

  private setNodeByState(node: CategoryMenuNodeModel, nodeState: NodeState): NodeState {
    node.isSelected = false;
    node.isMarked = false;
    switch (nodeState) {
      case NodeState.CHECKED:
        node.isSelected = true;
        break;
      case NodeState.MARKED:
        node.isMarked = true;
        break;
    }
    return nodeState;
  }

  private getStateFromNode(node: CategoryMenuNodeModel): NodeState {
    if (node.isSelected) {
      return NodeState.CHECKED;
    }
    if (node.isMarked) {
      return NodeState.MARKED;
    }
    return NodeState.UNECHKED;
  }

  onNodeLabelClick(n) {
    if (n.isExpanded) {
      n.collapse();
    } else {
      n.expand();
    }
  }

  expandSelected() {
    let allNodes = this.tree.treeModel.roots.map(i => {
      if (i) {
        return i;
      }
    });
    allNodes.forEach(node => {
      node.setActiveAndVisible();
      node.setIsActive(false);
    });
    this.tree.treeModel.collapseAll();
    allNodes.forEach(mainNode => {
      mainNode.doForAll(node => {
        if (node.data.isSelected) {
          node.setActiveAndVisible(true);
        }
      });
    });
  }

  public ensureSelectedVisible() {
    let allNodes = this.tree.treeModel.roots.map(i => {
      if (i) {
        return i;
      }
    });
    allNodes.forEach(mainNode => {
      mainNode.doForAll(node => {
        if (node.data.isSelected) {
          node.setActiveAndVisible(true);
        }
      });
    });
  }
}


