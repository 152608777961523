import { Injectable }               from '@angular/core';
import { Globals }                  from '../common/globals';
import { HttpClient }               from '@angular/common/http';
import { Observable }               from 'rxjs';
import { CommonService }            from './common.service';
import { Report }                   from '../common/report-grid/model/report.model';
import { ReportContext }            from '../common/report-grid/model/report-context.model';
import { ReportResult }             from '../common/report-grid/model/report-result.model';
import { ReportExportRequest } from '../common/report-grid/model/report-export-request.model';
import { ReportExportResult } from '../common/report-grid/model/report-export-result.model';
import { SavedReport } from '../common/report-grid/model/saved-report.model';
import { ElasticFilterRequest } from '../common/elastic-filter-request.model';
import { BulkInsertTableResult } from '../common/report-grid/model/bulk-insert-table-result.model';
import { RunUpdateQueryRequest } from '../common/report-grid/model/run-update-query.model';
import { isNullOrUndefined } from '../common/functions';
@Injectable()
export class ReportService {

    public readonly longProcessTimeout = 5 * 60 * 1000;
    public readonly localPort = 8377;
    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'report', this.localPort, 'TenantReports', true);
        // the authorozatopm is removed by the interceptor when accessing different domain
        // this issue has no other solution then this one, the whitelisted domain property can't be used
        this._commonService.getCustomHeaders = () => {
            return { 'Authorization': globals.getAuthorizationHeaderValue() };
        };
    }

    public getUrlPrefix(): string {
        return this._commonService.getUrlPrefix();
    }

    public getReport(reportID: number): Observable<Report> {
        return this._commonService.get(reportID.toString());
    }

    public getReportByName(reportName: string): Observable<Report> {
        return this._commonService.get('byName/' + reportName);
    }

    public getReportsList(): Observable<Report[]> {
        return this._commonService.get('');
    }

    public getReportsListForRoles(): Observable<Report[]> {
        return this._commonService.get('listForRoles');
    }

    public getReportsListSimple(): Observable<Report[]> {
        return this._commonService.get('simpleList');
    }

    public getReportsListWithSaved(): Observable<Report[]> {
        return this._commonService.get('listWithSaved');
    }

    public getReportsListWithSavedForRoles(): Observable<Report[]> {
        return this._commonService.get('listWithSavedForRoles');
    }

    public getCustomFunctionsReport(): Observable<Report[]> {
        return this._commonService.get('listCustomFunctionsReport');
    }

    public runReport(context: ReportContext): Observable<ReportResult> {
        return this._commonService.post('run', context, this.getTimeout(context)); // remove 1s safety timeout for communication
    }

    public runUpdateQuery(request: RunUpdateQueryRequest): Observable<void> {
        return this._commonService.post('runUpdateQuery', request, this.longProcessTimeout);
    }

    public printReport(context: ReportContext): Observable<ReportExportResult> {
        return this._commonService.post('print', context, this.getTimeout(context));
    }

    private getTimeout(context: ReportContext): number {
        let timeout = (isNullOrUndefined(context.commandTimeout)) ? this.longProcessTimeout : context.commandTimeout * 1000;
        if (timeout < 10000) { // add some safety boundary for communication
            timeout = timeout * 0.95;
        } else {
            timeout = timeout - 1000;
        }
        return timeout;
    }

    public saveReport(report: Report): Observable<number> {
        return this._commonService.post('', report);
    }

    public deleteReport(id: number): Observable<void> {
        return this._commonService.deleteItem( id.toString() );
    }

    public import(files: File[]): Observable<void> {
        return this._commonService.uploadFile('import', files, null, null, 120000);
    }

    public export(request: ReportExportRequest): Observable<ReportExportResult> {
        return this._commonService.post('export', request );
    }

    public saveReportState(request: SavedReport): Observable<void> {
        return this._commonService.post('saveReport', request );
    }

    public deleteSavedReport(request: SavedReport): Observable<void> {
        return this._commonService.post('deleteSavedReport', request );
    }

    public createBulkTable(request: ElasticFilterRequest): Observable<BulkInsertTableResult>  {
        return this._commonService.post('createBulkTable', request, this.longProcessTimeout );
    }

    public dropBulkTable(name: string): Observable<void> {
        return this._commonService.post('dropBulkTable/' + name, null);
    }

    public getAllDisplayRulesSetting(): Observable<any> {
        return this._commonService.get('getAllDisplayRulesSetting', null);
    }
}
