import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnInit,
  AfterViewInit
} from '@angular/core';
import { TreeComponent, TreeNode } from 'angular-tree-component';
import { CategoryMenuNodeModel } from '../../product/category-node-model';

enum NodeState {
  UNECHKED,
  CHECKED,
  MARKED
}

@Component({
  selector: 'hc-filtered-tree',
  templateUrl: './filtered-tree.component.html',
  styleUrls: ['./filtered-tree.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilteredTreeComponent implements OnInit, AfterViewInit {

  @ViewChild(TreeComponent, {static: false})
  public tree: TreeComponent;

  @Input() showSelectedTexbox = false;

  @Input() treeOptions;

  @Input() disabled = false;

  @Output() modelChanged = new EventEmitter();

  private _nodes;
  public filterText: string;
  public selectedText: string;

  get nodes() {
    return this._nodes;
  }

  @Input() set nodes(nodes) {
    this._nodes = nodes;
    setTimeout(() => {
      this.tree.treeModel.nodes.forEach(n => this.checkSubNodes(n));
      this.modelChanged.emit(this.nodes);
    }, 100);
  }

  ngAfterViewInit(): void {
  }

  async ngOnInit() {
  }

  constructor() {
  }

  public sizeChanged() {
    this.tree.sizeChanged();
    if (this.showSelectedTexbox) {
      this.updateSelectedText();
    }
  }

  public update() {
    this.tree.treeModel.update();
    if (this.showSelectedTexbox) {
      this.updateSelectedText();
    }
  }

  public toggleNode(node: TreeNode) {
    if (this.disabled) { return; }

    this.toggleNodeChildren(node.data);
    this.tree.treeModel.nodes.forEach(n => this.checkSubNodes(n));
    this.modelChanged.emit(this.nodes);

    if (this.showSelectedTexbox) {
      this.updateSelectedText();
    }
  }

  private toggleNodeChildren(node: CategoryMenuNodeModel) {
    if (this.disabled) { return; }

    if (node.items && node.items.length > 0) {
      node.items.forEach(i => {
        i.isSelected = node.isSelected;
        i.isMarked = false;
        this.toggleNodeChildren(i);
      });
    }
  }

  private checkSubNodes(node: CategoryMenuNodeModel): NodeState {
    let state = this.getStateFromNode(node);
    if (node.items.length > 0) {
      let checked = 0, marked = 0;
      node.items.forEach(n => {
        switch (this.checkSubNodes(n)) {
          case NodeState.CHECKED:
            checked++;
            break;
          case NodeState.MARKED:
            marked++;
            break;
        }
      });
      if (checked + marked === 0) {
        state = NodeState.UNECHKED;
      }
      if (checked < node.items.length && checked + marked > 0) {
        state = NodeState.MARKED;
      }
      if (checked === node.items.length) {
        state = NodeState.CHECKED;
      }
    }
    return this.setNodeByState(node, state);
  }

  private setNodeByState(node: CategoryMenuNodeModel, nodeState: NodeState): NodeState {
    node.isSelected = false;
    node.isMarked = false;
    switch (nodeState) {
      case NodeState.CHECKED:
        node.isSelected = true;
        break;
      case NodeState.MARKED:
        node.isMarked = true;
        break;
    }
    return nodeState;
  }

  private getStateFromNode(node: CategoryMenuNodeModel): NodeState {
    if (node.isSelected) {
      return NodeState.CHECKED;
    }
    if (node.isMarked) {
      return NodeState.MARKED;
    }
    return NodeState.UNECHKED;
  }

  filterTextChange() {
    if (!this.filterText) {
      this.tree.treeModel.filterNodes((node) => {
        return true;
      });
      this.tree.treeModel.update();
      return;
    }
    const t = this.filterText.toLocaleLowerCase();
    this.tree.treeModel.filterNodes((node) => {
      return (node.data.caption.toLowerCase().indexOf( t ) !== -1);
    });
    this.tree.treeModel.update();
  }

  onNodeLabelClick(n) {
    if (n.isExpanded) {
      n.collapse();
    } else {
      n.expand();
    }
  }

  expandSelected() {
    let allNodes = this.tree.treeModel.roots.map(i => {
      if (i) {
        return i;
      }
    });
    allNodes.forEach(node => {
      node.setActiveAndVisible();
      node.setIsActive(false);
    });
    this.tree.treeModel.collapseAll();
    allNodes.forEach(mainNode => {
      mainNode.doForAll(node => {
        if (node.data.isSelected) {
          node.setActiveAndVisible(true);
        }
      });
    });
  }

  public updateSelectedText() {
    const selNodes = [];
    let allNodes = this.tree.treeModel.roots.map(i => {
      if (i) {
        return i;
      }
    });
    allNodes.forEach( n => {
      this.updateSelectedTextR( selNodes, n );
    });
    this.selectedText = selNodes.join(',');
  }

  private updateSelectedTextR(selNodes: string[], node ) {
    if (node.data.isSelected) {
      selNodes.push( node.data.caption );
    }
    if (node.children) {
      node.children.forEach( n => {
        this.updateSelectedTextR( selNodes, n );
      });
    }
  }

  public ensureSelectedVisible() {
    let allNodes = this.tree.treeModel.roots.map(i => {
      if (i) {
        return i;
      }
    });
    allNodes.forEach(mainNode => {
      mainNode.doForAll(node => {
        if (node.data.isSelected) {
          node.setActiveAndVisible(true);
        }
      });
    });
  }
}


