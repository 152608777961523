import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from '../common/functions';

@Pipe({
    name: 'fromUtc'
})

export class FromUtcPipe implements PipeTransform {

    transform(value: any, args: any): any {
        if (isNullOrUndefined(value)) {
            return null;
        }

        // JSON format 2021-05-10T11:48:08.47
        if (typeof(value) === 'string') {
            var b = value.split(/\D+/);
            return new Date(Date.UTC(Number(b[0]), Number(b[1]) - 1, Number(b[2]), Number(b[3]), Number(b[4]), Number(b[5]), Number(b[6])));
        }

        // Should never get here, but can handle the rest
        const tmpDate = new Date((Date.parse(value)));
        tmpDate.setMinutes(tmpDate.getMinutes() - tmpDate.getTimezoneOffset());
        return tmpDate;
    }
}