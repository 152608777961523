import { Injectable }               from '@angular/core';
import { ISortableFilter }          from '../common/sortable-filter';
import { Globals }                  from '../common/globals';
import { HttpClient } from '@angular/common/http';
import { Observable }               from 'rxjs';
import { CommonService }            from './common.service';
import { ContactModel }             from '../common/contact.model';
import { IBaseService }             from './service.interface';

@Injectable()
export class ContactService implements IBaseService<ContactModel> {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'user/contact');
    }

    public getItems(filter: ISortableFilter): Observable<any> {
        return this._commonService.get('list', filter);
    }

    public select(filter: ISortableFilter): Observable<any> {
        return this._commonService.post('select', filter);
    }

    public getDetail(id: string): Observable<ContactModel> {
        return this._commonService.get('detail/' + id);
    }

    public insertItem(item: ContactModel): Observable<any> {
        return this._commonService.insertItem(item);
    }

    public updateItem(id: string, item: ContactModel): Observable<any> {
       return this._commonService.updateItem(id, item);
    }

    public deleteItem(id: string): Observable<any> {
        return this._commonService.deleteItem(id);
    }

    public deleteItemWithUserId(userId: string, id: string): Observable<any> {
        return this._commonService.deleteItem(userId + '/' + id);
    }
}
