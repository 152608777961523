import { Injectable }               from '@angular/core';
import { ISortableFilter }          from '../common/sortable-filter';
import { Globals }                  from '../common/globals';
import { HttpClient }               from '@angular/common/http';
import { Observable }               from 'rxjs';
import { CommonService }            from './common.service';
import { DocumentModel }            from '../common/document.model';
import { IBaseService }             from './service.interface';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class OrderDocumentService implements IBaseService<DocumentModel> {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals, snackbar: MatSnackBar) {
        this._commonService = new CommonService(http, globals, 'order/document');
    }

    public getItems(filter: ISortableFilter): Observable<any> {
        return this._commonService.get('list', filter);
    }

    public getDetail(id: string): Observable<DocumentModel> {
        return this._commonService.get('detail/' + id);
    }

    public insertItem(item: DocumentModel): Observable<any> {
        return this._commonService.insertItem(item);
    }

    public updateItem(id: string, item: DocumentModel): Observable<any> {
       return this._commonService.updateItem(id, item);
    }

    public deleteItem(id: string): Observable<any> {
        return this._commonService.deleteItem(id);
    }
}
