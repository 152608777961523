import { Component, Input, Optional, Host, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SatPopover } from '@ncstate/sat-popover';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'hc-inline-edit',
  styleUrls: ['inline-edit.component.scss'],
  templateUrl: 'inline-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineEditComponent implements OnInit {

  @Input()
  get value(): string {
    return this._value;
  }
  set value(x: string) {
    this.editVal = this._value = x;
  }
  private _value = '';

  @Input()
  set type(x: string) {
    this.editType = x;
  }

  /** Form model for the input. */
  editVal = '';
  editType = '';

  constructor(
      @Optional()
      @Host()
      private popover: SatPopover,
      private changeRef: ChangeDetectorRef,
    ) {

  }

  ngOnInit() {
    // subscribe to cancellations and reset form value
    if (this.popover) {
      this.popover.closed
        .pipe(filter(val => val == null))
        .subscribe(() => {
          this.editVal = this.value || '';
          this.changeRef.markForCheck();
        });
    }
  }

  onSubmit() {
    if (this.popover) {
      this.popover.close(this.editVal);
    }
  }

  onCancel() {
    if (this.popover) {
      this.popover.close();
    }
  }
}
