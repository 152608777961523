// app/translate/translate.pipe.ts

import { Pipe,
         PipeTransform }		    from '@angular/core';
import { formatMoney }              from '../common/functions';

// tslint:disable-next-line:pipe-naming
@Pipe({ name: 'hcurrency', // currency is already used, so we must use hcurrency
        // impure pipe - the pipe is called every time change detection runs (which is usually quite often).
        // pure: false // impure pipe, update value when we change language
        pure: true // better UI performance
})

export class HCurrencyPipe implements PipeTransform {

    private currencyMap =  {
        'CZK' : 'Kč',
        'EUR' : '€',
        'USD' : '$'
    };

     transform(value: any, currencyIsoCode: string): any {
        if (isNaN(value)) { return value; }

        let symbol = this.currencyMap[currencyIsoCode];

        return formatMoney(value, symbol);
     }
}
