import { KeyValuePair }         from '../shared/keyValuePair.model';
import { format }               from 'date-fns';
export function isJsObject(o) {
    return o !== null && (typeof o === 'function' || typeof o === 'object');
}

/**
 * Converts an object to a parametrised string.
 * @param object
 * @returns {string}
 */
export function objectToParams(object): string {
    if (!object) {
        return '';
    }

    return Object.keys(object).map((key) => isJsObject(object[key]) ?
        subObjectToParams(encodeURIComponent(key), object[key]) :
        `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`
    ).join('&');
}

export function isFuckingIE(): boolean {
    return (navigator.appName === 'Microsoft Internet Explorer' ||
    !!(navigator.userAgent.match(/Trident/) ||
    navigator.userAgent.match(/rv:11/)));
}

export function isFuckingEdge(): boolean {
    return (/Edge/.test(navigator.userAgent));
}

export function isFirefox(): boolean {
    return /firefox/i.test(navigator.userAgent);
}

/**
 * Converts a sub-object to a parametrised string.
 * @param object
 * @returns {string}
 */
function subObjectToParams(key, object): string {
    if (!object) {
        return '';
    }

    return Object.keys(object).map((childKey) => isJsObject(object[childKey]) ?
            subObjectToParams(`${key}[${encodeURIComponent(childKey)}]`, object[childKey]) :
            `${key}.${encodeURIComponent(childKey)}=${encodeURIComponent(object[childKey])}`
    ).join('&');
}

export function toNetTime(parameter: string): string {
    return '\/Date(' + new Date(parameter).getTime() + ')\/';
}

export function toNetParameterDate(parameter: Date): string {
    return parameter.getFullYear() + '-' + (parameter.getMonth() + 1) + '-' + parameter.getDate();
}

export function toLocalTime(d: Date): string {
    return d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear() + ' ' +
            padLeft(d.getHours(), 2) + ':' + padLeft(d.getMinutes(), 2) + ':' + padLeft(d.getSeconds(), 2) + ' (CET)';
}

/** Because FF cannot parse stardard ISO format '1990-01-01 10:00:00.00 +02:00' */
export function getDateFromString(dStr: string): Date {
    return new Date(dStr.replace(' ', 'T').replace(' ', ''));
}

export function getStringFromDate(d: Date | any, langISO = 'cs'): string {
    return getStringFromDateFormat(d, 'dd.MM.yyyy', langISO);
}

export function getStringFromDateTimeFormat(d: Date | any, dateFormat = 'd.M.yyyy HH:mm:ss',  langISO = 'cs'): string {
    return getStringFromDateFormat(d, dateFormat, langISO);
}

export function getStringFromDateFormat(d: Date | any, dateFormat = 'dd.MM.yyyy',  langISO = 'cs'): string {
    if (isNullOrUndefined(d)) {
        return '';
    }

    if (typeof(d) === 'string') {
        d = new Date(d);
    }

    switch (langISO) {
        case 'cs':
        default:
            return format(d, dateFormat);
    }
}

Object.defineProperty(Object.prototype, 'getProp', {
    value: function (prop) {
        let key, self = this;
        for (key in self) {
            if (key.toLowerCase() === prop.toLowerCase()) {
                return self[key];
            }
        }
    },
    // this keeps jquery happy
    enumerable: false
});


export function padLeft(input: Number, length: number, str: string = null) {
    return Array(length - String(input).length + 1).join(str || '0') + input;
}

export function createGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        // tslint:disable-next-line:no-bitwise
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function compareBitwise(model: number, value: number): boolean {
    // tslint:disable-next-line:no-bitwise
    return (model & value) > 0;
}

export function moveInArray(input_array: Array<any>, old_index, new_index) {
    if (new_index >= input_array.length) {
        let k = new_index - input_array.length;
        while ((k--) + 1) {
            input_array.push(undefined);
        }
    }
    input_array.splice(new_index, 0, input_array.splice(old_index, 1)[0]);
    return input_array; // for testing purposes
}

export function trimChar(str: string, charToRemove: string) {
    while (str.charAt(0) === charToRemove) {
        str = str.substring(1);
    }
    while (str.charAt(str.length - 1) === charToRemove) {
        str = str.substring(0, str.length - 1);
    }
    return str;
}

export function copyToClipboard(text: string, success: () => void, error: () => void) {
    let textArea = document.createElement('textarea');

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a flash,
    // so some of these are just precautions. However in IE the element
    // is visible whilst the popup box asking the user for permission for
    // the web page to copy to the clipboard.
    //

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = '0';

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';
    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.select();

    try {
        let successful = document.execCommand('copy');
        if (successful) {
            success();
        } else {
            error();
        }
    } catch (err) {
        error();
    }

    document.body.removeChild(textArea);
}

/**
 * https://github.com/danguer/blog-examples/blob/master/js/base64-binary.js
 */
export const Base64Binary = {
    _keyStr : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

    /* will return a  Uint8Array type */
    decodeArrayBuffer: function(input) {
        let bytes = (input.length / 4) * 3;
        let ab = new ArrayBuffer(bytes);
        this.decode(input, ab);
        return ab;
    },

    removePaddingChars: function(input) {
        let lkey = this._keyStr.indexOf(input.charAt(input.length - 1));
        if (lkey === 64) {
            return input.substring(0, input.length - 1);
        }
        return input;
    },

    decode: function (input, arrayBuffer) {
        // get last chars to see if are valid
        input = this.removePaddingChars(input);
        input = this.removePaddingChars(input);
        let bytes = parseInt(((input.length / 4) * 3) + '', 10);

        let uarray;
        let chr1, chr2, chr3;
        let enc1, enc2, enc3, enc4;
        let i = 0;
        let j = 0;

        if (arrayBuffer) {
            uarray = new Uint8Array(arrayBuffer);
        } else {
            uarray = new Uint8Array(bytes);
        }

        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

        for (i = 0; i < bytes; i += 3) {
            // get the 3 octects in 4 ascii chars
            enc1 = this._keyStr.indexOf(input.charAt(j++));
            enc2 = this._keyStr.indexOf(input.charAt(j++));
            enc3 = this._keyStr.indexOf(input.charAt(j++));
            enc4 = this._keyStr.indexOf(input.charAt(j++));

            // tslint:disable:no-bitwise
            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            uarray[i] = chr1;
            if (enc3 !== 64) { uarray[i + 1] = chr2; }
            if (enc4 !== 64) { uarray[i + 2] = chr3; }
        }
        return uarray;
    }
};

/**
 * Number.prototype.format(n, x, s, c)
 * @param numeric input: input number
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
export function formatMoney(input: number, symbol = '', n = 2, x = 3, s = ' ', c = ','): string {

    if (isNullOrUndefined(input)) { return ''; }

    let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = input.toFixed(Math.max(0, ~~n));

    if (!symbol) { symbol = ''; }

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ',')) + (symbol.length > 0 ? (' ' + symbol) : '');
}


export function enumToHashMap(enumType): Map<string, any> {
    let types = Object.keys(enumType);
    let result = new Map<string, any>();
    let typeNames = types.slice(types.length / 2);
    typeNames.forEach(n => {
        result.set(n, enumType[n]);
    });

     return result;
}

export function enumToValues(enumType): Array<any> {
    let types = Object.values(enumType);
    let result = types.slice(types.length / 2);
    return result;
}

export function enumToStrings(enumType): Array<string> {
    let types = Object.keys(enumType);
    let result = types.slice(types.length / 2);
    return result;
}

export function isNullOrWhitespace(input: string): boolean {
    if (input === null || input === undefined) { return true; }
    if (typeof input !== 'string') { return false; }
    input = input.replace(' ', '');
    return input.length === 0;
}

export function isNullOrUndefined(input: any) {
    if (input === null || input === undefined) { return true; }
}

export function stripHtmlFromTags(htmlString = ''): string {
    if (isNullOrWhitespace(htmlString)) {
        return htmlString;
    }
    return htmlString.replace(/<(?:.|\n)*?>/gm, '');
}

export function insertAtCursor(myField, myValue) {
    // IE support
    if ((<any>document).selection) {
        myField.focus();
        let sel = (<any>document).selection.createRange();
        sel.text = myValue;
    // Microsoft Edge
    } else if (window.navigator.userAgent.indexOf('Edge') > -1) {
      let startPos = myField.selectionStart;
      let endPos = myField.selectionEnd;
      myField.value = myField.value.substring(0, startPos) + myValue
             + myField.value.substring(endPos, myField.value.length);
      let pos = startPos + myValue.length;
      myField.focus();
      myField.setSelectionRange(pos, pos);
    // MOZILLA and others
    } else if (myField.selectionStart || myField.selectionStart === '0') {
        let startPos = myField.selectionStart;
        let endPos = myField.selectionEnd;
        myField.value = myField.value.substring(0, startPos)
            + myValue
            + myField.value.substring(endPos, myField.value.length);
    } else {
        myField.value += myValue;
    }
}

export function toKeyValuePairs(data: any): Array<KeyValuePair> {
    let keyValuePairs = [];
    if (data) {
        keyValuePairs = Object.keys(data).map(key => <KeyValuePair>{
         [key]: data[key]
        });
    }

    return keyValuePairs;
}

/*
 * Method for JSON.stringify() that removes functions and circular references
 */
export function circularRefReplacer(key: string, value) {
    const cache = new Set();

    if (typeof value === 'function') {
        return;
    }

    if (typeof value === 'object' && value !== null) {
        if (cache.has(value)) {
          // Circular reference found, discard key
          return;
        }
        // Store value in our set
        cache.add(value);
      }
      return value;
}


export function deepCopy(obj) {
    if (obj === null || obj === undefined) { return obj; }
    return Object.keys(obj).reduce((v, d) => Object.assign(v, {
        // JSO added condition to obj[d] exists, it happened that it was null and then it creashed
        [d]: (obj[d] && obj[d].constructor === Object) ? deepCopy(obj[d]) : obj[d]
      }), {});
}

export function isPromise(p) {
    return p && Object.prototype.toString.call(p) === '[object Promise]';
}

export function routeToUIRole(path: string): string {
    if (!path) {
        return null;
    }
    let roleBase: string = path.replace(/(^\/)|((\/list)|(\/new)|(\/detail\/[A-Z0-9]+)|(\/\d+)?\/?$)/gi, '').replace(/\//g, '_');
    if (roleBase.indexOf('setting_attributes') === 0) {
        roleBase = 'setting_attributes';
    } else if (roleBase.indexOf('setting_configuration') === 0) {
        roleBase = 'setting_components';
    }
    return roleBase;
}