import { Injectable }                   from '@angular/core';
import { ISortableFilter }              from '../common/sortable-filter';
import { Globals }                      from '../common/globals';
import { HttpClient } from '@angular/common/http';
import { Observable }                   from 'rxjs';
import { CommonService }                from './common.service';
import { DataSourceInput,
         EntityDataType }               from '../common/media.model';
import { TemplateTypeSelect, TenantTemplate }           from '../setting/template/template.model';
import { PagingModel }                  from '../common/paging-model';
import { DistributionBatchItemModel }   from '../communication/media/distribution-items/distribution-batch-item.model';
import { DistributionHistoryModel } from '../communication/media/distribution-history/distribution-history.model';

@Injectable()
export class TemplateService {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'template');
    }

    public getItems(filter: ISortableFilter): Observable<any> {
        return this._commonService.get('list', filter);
    }

    public jsReportList(unused = false): Observable<Array<TemplateTypeSelect>> {
        return this._commonService.get('jsReportList?unused=' + unused);
    }

    public select(filter: ISortableFilter): Observable<any> {
        return this._commonService.post('select', filter);
    }

    public insertItem(item: any): Observable<any> {
        return this._commonService.insertItem(item);
    }

    public cloneItem(id: string, targetName: string): Observable<any> {
        return this._commonService.post('clone/' + id + '?targetName=' + targetName, {});
    }

    public getDetail(id: number): Observable<TenantTemplate> {
        return this._commonService.get('detail/' + id);
    }

    public getDataSourceInputs(entityDataType: EntityDataType): Observable<Array<DataSourceInput>> {
        return this._commonService.get('GetDataSourceInputs/' + entityDataType);
    }

    public updateItem(id: string, item: any): Observable<any> {
       return this._commonService.updateItem(id, item);
    }

    public deleteItem(id: string): Observable<any> {
        return this._commonService.deleteItem(id);
    }

    public batchesHistory(mediaID: number, searchBase): Observable<PagingModel<DistributionHistoryModel>> {
        return this._commonService.post('BatchesHistory/' + mediaID, searchBase );
    }

    public batchHistoryItems(batchID: number, searchBase): Observable<PagingModel<DistributionBatchItemModel>> {
        return this._commonService.post('BatchHistoryItems/' + batchID, searchBase );
    }
}
