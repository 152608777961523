import { Component,
         Input,
         Optional,
         Host,
         OnInit,
         ChangeDetectorRef,
         ChangeDetectionStrategy } from '@angular/core';
import { SatPopover }       from '@ncstate/sat-popover';
import { filter }           from 'rxjs/operators';
import { CommonService }    from '../shared/common.service';
import { HttpClient } from '@angular/common/http';
import { Globals }          from './globals';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'hc-inline-codelist',
  styleUrls: ['inline-codelist.component.scss'],
  templateUrl: 'inline-codelist.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineCodeListComponent implements OnInit {

  @Input()
  public value: string;

  @Input()
  public codeListId: number;

  public service: CommonService;
  public codes: any[];

  constructor(
    @Optional()
    @Host()
    private popover: SatPopover,
    private changeRef: ChangeDetectorRef,
    private http: HttpClient,
    private globals: Globals,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    this.service = new CommonService(this.http, this.globals, 'codelist');
    this.service.get('detail/' + this.codeListId).toPromise()
    .then(v => this.codes = v.codes);
    // subscribe to cancellations and reset form value
    if (this.popover) {
      this.popover.closed.pipe(filter(val => val == null))
          .subscribe(() => {
            this.changeRef.markForCheck();
          });
    }
  }

  onSubmit() {
    if (this.popover) {
      this.popover.close(this.value);
    }
  }

  onCancel() {
    if (this.popover) {
      this.popover.close();
    }
  }
}
