import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * @title Dialog Overview
 */
@Component({
  selector: 'hc-info-dialog',
  template:
  `
  <div fxLayout="column" style="height: 150px;">
        <p fxFlex>
            {{ (message | trans ) }}
        </p>
        <div fxFlex="none"  fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-around center">
            <button mat-raised-button color="primary" (click)="clickOk()" >{{ 'admin.web.ok' | trans }}</button>
        </div>
  </div>
   `
})

export class InfoDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

    clickOk(): void {
        this.dialogRef.close(true);
    }
}
