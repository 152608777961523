import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'toArray', pure: true })
export class EnumToArrayPipe implements PipeTransform {
  transform(enumeration: object, prefix?: string): any[] {
    return Object.keys(enumeration).filter(k => isNaN(Number(k)) === false)
    .map(k => ({value: +k, text: (prefix || '') + enumeration[k]}));
  }
}
