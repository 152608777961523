import { ReportPagination } from './report-pagination.model';
import { ReportSorting } from './report-sorting.model';
import { ColumnFilter } from './column-filter.model';
import { BulkInsertTableResult } from './bulk-insert-table-result.model';
import { ReportPrintSettings } from './report-print-settings.model';

export class ReportContext {
    reportID: number;
    pagination: ReportPagination;
    sorting: ReportSorting[];
    groupByColumns: string[];
    groupByValuesFilter: string[];
    columnFilters: ColumnFilter[];
    needTotalCount: boolean;
    filterTempTables: BulkInsertTableResult[];
    groupCountRequired: boolean;
    variables: {};

    resultToTempTable: boolean;
    resultColumnIDName: string;
    fulltextFilter: string;

    maxRecordCount: number;
    commandTimeout: number;

    printSettings: ReportPrintSettings;

    public constructor() {
        this.pagination = new ReportPagination();
        this.sorting = [];
        this.groupByColumns = [];
        this.groupByValuesFilter = [];
        this.columnFilters = [];
        this.filterTempTables = [];
        this.variables = {};
        this.printSettings = new ReportPrintSettings();
    }
}
