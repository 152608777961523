import { OnInit,
         Component,
         Input,
         ViewEncapsulation }            from '@angular/core';
import { SortableFilter }               from '../sortable-filter';
import { Globals }                      from '../globals';


@Component({
    selector: 'hc-toolbar',
    templateUrl: './toolbar-component.html',
    styleUrls: [ './toolbar-component.scss' ],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit {

    @Input() performSearch;
    @Input() public filter: SortableFilter;
    @Input() showAdditionalFilter = false;
    @Input() showFilter = false;

    @Input() enableTextSearch = true;

    constructor(private globals: Globals) {

    }

    ngOnInit() {

    }

    public search() {
        this.filter.page = 1; // reset page number after new search
//        console.log(Array(20).join('='), this.globals.getLocalStorage().setLastUsedSearchPhrase(this.globals.currentNavItem().path, ''));
        this.saveSearchPhrase();
        this.performSearch.emit(this.filter);
    }

    public clearSearchPhrase() {
        if (this.filter) {
            this.filter.searchString = '';
        }
        this.saveSearchPhrase();
    }

    public saveSearchPhrase() {
        if (this.filter) {
            this.globals.getLocalStorage().setLastUsedSearchPhrase(this.globals.currentNavItem().path, this.filter.searchString);
        }
    }
}
