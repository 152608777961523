import {
    Component, OnInit, Input, AfterViewInit, ViewChild, ViewContainerRef,
    ChangeDetectionStrategy, ChangeDetectorRef, ElementRef
} from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ProductAttributeTypeWithValue } from '../product.model';
import { DataTypeEnum } from '../data-type.enum';
import { Code } from '../code.model';
import { FormControl } from '@angular/forms';
import { MediaModel } from '../media.model';
import { CodeLookupCacheService } from 'src/app/shared/code-lookup-cache-service';
import { MatCalendar, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'hc-grid-typed-editor',
    templateUrl: './grid-typed-editor.component.html',
    styleUrls: ['./grid-typed-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridTypedEditorComponent implements OnInit, ICellEditorAngularComp, AfterViewInit {

    @ViewChild('container', { read: ViewContainerRef, static: true }) public container;

    private params: any;
    private paramsData: ProductAttributeTypeWithValue;

    public dataTypes = DataTypeEnum;
    public dataType: DataTypeEnum = DataTypeEnum.NotSet;
    public valueStr: string = null;
    public valueObj: any = null;
    public codeListID: number = null;
    public codeValues: Array<Code> = [];
    public codeListReady = false;
    public mediaListReady = false;
    public mediaList: Array<MediaModel> = [];
    public codeListControl = new FormControl();

    @ViewChild('calendarSince', {static: true})
    calendarSince: MatCalendar<Date>;

    @ViewChild('calendarTill', {static: true})
    calendarTill: MatCalendar<Date>;

    @ViewChild('auto', {static: true})
    autocomplete: MatAutocomplete;

    @ViewChild('codeListSelect', {static: false}) codeListSelect: ElementRef;

    constructor(private cd: ChangeDetectorRef,
        private codeChacheService: CodeLookupCacheService) {
    }

    ngOnInit(): void {
    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
        if (this.dataType === DataTypeEnum.DateRange) {
            this.calendarSince.selected = this.valueObj.Since;
            this.calendarTill.selected = this.valueObj.Till;
            this.setCalendarView();
        }
        window.setTimeout(() => {
            this.container.element.nativeElement.focus();
        });
        this.cd.detectChanges();
    }

    async agInit(params: any) {
        this.params = params;
        this.paramsData = params.node.data as ProductAttributeTypeWithValue;
        this.dataType = this.paramsData.dataType;
        this.valueStr = this.paramsData.value;
        this.codeChacheService.cacheReady.subscribe(() => {
            if (this.dataType === DataTypeEnum.CodeList) {
                this.setCodeLists();
            }
            if (this.dataType === DataTypeEnum.Media || this.dataType === DataTypeEnum.VoucherTemplate) {
                this.setMedia();
            }
            this.cd.detectChanges();
        });
        if (this.dataType === DataTypeEnum.DateRange) {
            let val = JSON.parse(this.valueStr);
            let valObj = {
                Since: new Date(val.Since),
                Till: new Date(val.Till)
            };
            this.valueObj = valObj;
        }
        if (this.dataType === DataTypeEnum.NumberRange) {
            let val = JSON.parse(this.valueStr);
            let valObj = {
                From: Number(val.From),
                To: Number(val.To)
            };
            this.valueObj = valObj;
        }
        if (this.dataType === DataTypeEnum.CodeList) {
            this.setCodeLists();
        }
        if (this.dataType === DataTypeEnum.Media || this.dataType === DataTypeEnum.VoucherTemplate) {
            this.setMedia();
        }
        if (this.dataType === DataTypeEnum.Dimensions) {
            try {
                let val = JSON.parse(this.params.value);
                let valObj = {
                    DepthCM: Number(val.DepthCM),
                    HeightCM: Number(val.HeightCM),
                    WidthCM: Number(val.WidthCM)
                };
                this.valueObj = valObj;
            } catch {
                this.valueObj = {
                    DepthCM: null,
                    HeightCM: null,
                    WidthCM: null
                };
            }
        }
        if (this.dataType === DataTypeEnum.DateTime || this.dataType === DataTypeEnum.ShortDate) {
            try {
                this.valueObj = new Date(this.valueStr);
            } catch {
                this.valueObj = null;
            }
        }
        this.cd.detectChanges();
    }

    setCodeLists() {
        this.codeValues = this.codeChacheService.getCodeListItems(this.paramsData.codeListId);
        this.codeListReady = (this.codeValues != null);
        window.setTimeout(() => {
            if (!!this.codeListSelect) {
                this.codeListSelect.nativeElement.focus();
            }
            //   this.codeListSelect.nativeElement.click();
        });
    }

    setMedia() {
        this.mediaList = this.codeChacheService.getMediaList();
        this.mediaListReady = (this.mediaList != null);
        this.cd.detectChanges();
        window.setTimeout(() => {
            this.codeListSelect.nativeElement.focus();
        });

    }

    getValue(): any {
        if (this.dataType === DataTypeEnum.CodeList) {
            return this.valueStr;
        }
        if (this.dataType === DataTypeEnum.DateTime ||
            this.dataType === DataTypeEnum.ShortDate) {
                if (isNaN(this.valueObj)) {
                    return null;
                }
            return this.valueObj.toISOString();
        }
        return this.valueObj === null ? this.valueStr : JSON.stringify(this.valueObj);
    }

    isPopup(): boolean {
        return true;
    }

    onClick($event) {
        console.log($event);
        // this.params.api.stopEditing();
    }

    public setCalendarSinceToday() {
        this.valueObj.Since = new Date(new Date().toDateString());
        this.setCalendarView();
    }

    public setCalendarTillToday() {
        this.valueObj.Till = new Date(new Date().toDateString());
        this.setCalendarView();
    }

    private setCalendarView() {
        if (this.calendarSince) { this.calendarSince._goToDateInView(this.valueObj.Since, 'month'); }
        if (this.calendarTill) { this.calendarTill._goToDateInView(this.valueObj.Till, 'month'); }
    }

    public boolCheckedChanged($event: MatCheckboxChange) {
        this.valueStr = $event.checked ? 'True' : 'False';
    }

    public onDateSinceChanged($event: Date) {
        this.valueObj.Since = $event;
        this.valueStr = JSON.stringify(this.valueObj);
    }

    public onDateTillChanged($event: MatDatepickerInputEvent<any>) {
        this.valueObj.Till = $event;
        this.valueStr = JSON.stringify(this.valueObj);
    }

    public onDateChanged($event: Date) {
        this.valueObj = $event;
        this.valueStr = this.valueObj.toLocaleString();
    }

    public close() {
        this.params.api.stopEditing();
    }

    public listOptionSelected($event, option: Code) {
        $event.stopPropagation();
        this.valueStr = option.codeKey;

    }

    public focusIn() {
        console.log('FocusIn');
    }

    public focusOut() {
        console.log('FocusOut');
    }

    public optionSelected($event) {
        console.log($event);
    }

    onKeyDown(event): void {
        //   let key = event.which || event.keyCode;
        //   if (key === 37 ||  // left
        //       key === 39) {  // right
        event.stopPropagation();
        //   }
    }

}
