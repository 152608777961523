import { Directive, Renderer2, ElementRef, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { showCustomUI } from '../common/functions-position';
import { PlaceholderModel } from '../common/media.model';

@Directive({
  selector: '[hcPlaceholderSuggester]'
})
export class PlaceholderSuggesterDirective implements OnInit {

  @Input() placeholders: Array<PlaceholderModel>;

  @Output() valueUpdated: EventEmitter<string> = new EventEmitter<string>();

  constructor(private renderer: Renderer2,
    private el: ElementRef,
    private cd: ChangeDetectorRef) {
  }

  ngOnInit() {

    if (!this.placeholders || this.placeholders.length === 0) {
      return;
    }

    if (this.el.nativeElement instanceof HTMLDivElement) { // HTML editor
      this.renderer.addClass(this.el.nativeElement, 'placeholder-editor');
    } else { // Textarea or input
      this.renderer.addClass(this.el.nativeElement, 'placeholder-input');
    }


    this.renderer.listen(this.el.nativeElement, 'keypress', (e) => showCustomUI(e, this.placeholders, this.el.nativeElement.id,
      this.afterUpdateCallback.bind(this)));
    this.renderer.listen(this.el.nativeElement, 'keydown', (e) => showCustomUI(e, this.placeholders, this.el.nativeElement.id,
      this.afterUpdateCallback.bind(this)));
    this.renderer.listen(this.el.nativeElement, 'keyup', (e) => showCustomUI(e, this.placeholders, this.el.nativeElement.id,
      this.afterUpdateCallback.bind(this)));
  }

  private afterUpdateCallback() {
    this.valueUpdated.emit(this.el.nativeElement.value);
  }
}
