import { Injectable }                   from '@angular/core';
import { ImportExportFileFormat }       from '../interface/interface.model';
import { isNullOrWhitespace } from './functions';

@Injectable()
export class HcLocalStorage {

    private localStorageKey = 'hcSettings';
    private hcUIRoles = 'hcuiroles';

    private settings: any;

    private settingsLoaded = false;

    public key: any = {
        companyCurrencyISOCode: 'companyCurrencyISOCode',
        companyCountryISOCode: 'companyCountryISOCode',
        lastUsedExportFileFormat: 'lastUsedExportFileFormat',
        tenantId: 'tenantId',
        gridPageSize: 'gridPageSize',
        lastUsedSearchPhrase: 'lastUsedSearchPhrase',
        customData: 'customData',
        lastUsedFilterID: 'lastUsedFilterID'
    };

    private getSettings(settingKey: string = null) {
        if (settingKey !== null) {
            this.settingsLoaded = !!this.settings && !!this.settings[settingKey];
        }

        if (!this.settingsLoaded) {
            let settings: string = localStorage.getItem(this.localStorageKey);
            if (settings && settings.length > 0) {
                this.settings = JSON.parse(settings);
                this.settingsLoaded = true;
            } else {
                this.settings = this.settings || {};
            }
        }
    }

    private saveSettings() {
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.settings || {}));
    }

    public get lastUsedExportFileFormat(): ImportExportFileFormat {
        this.getSettings();
        return this.settings[this.key.lastUsedExportFileFormat] || ImportExportFileFormat.XLSX;
    }

    public set lastUsedExportFileFormat(fileFormat: ImportExportFileFormat) {
        this.getSettings();
        this.settings[this.key.lastUsedExportFileFormat] = fileFormat;
        this.saveSettings();
    }

    public get tenantId(): number {
        this.getSettings();
        return this.settings.tenantId || 1;
    }

    public set tenantId(tenantId: number) {
        this.getSettings();
        this.settings[this.key.tenantId] = tenantId;
        this.saveSettings();
    }

    public get companyCurrencyISOCode(): string {
        this.getSettings();
        return this.settings[this.key.companyCurrencyISOCode] || '';
    }

    public set companyCurrencyISOCode(iso: string) {
        this.getSettings();
        this.settings[this.key.companyCurrencyISOCode] = iso;
        this.saveSettings();
    }

    public get companyCountryISOCode(): string {
        this.getSettings(this.key.companyCountryISOCode);
        return this.settings[this.key.companyCountryISOCode] || '';
    }

    public set companyCountryISOCode(iso: string) {
        this.getSettings();
        this.settings[this.key.companyCountryISOCode] = iso;
        this.saveSettings();
    }

    public getPageSizeForPath(route: string): string {
        this.getSettings();
        let gridPageSize = this.settings[this.key.gridPageSize] || {};
        return gridPageSize[route] || '10';
    }

    public setPageSizeForPath(path: string, pageSize) {
        this.getSettings(this.key.gridPageSize);
        let pageSizeSettings = this.settings[this.key.gridPageSize];
        if (!pageSizeSettings) {
            this.settings[this.key.gridPageSize] = {};
        }
        this.settings[this.key.gridPageSize][path] = pageSize;
        this.saveSettings();
    }

    public getCustomDataForPath(route: string): any {
        this.getSettings();
        let customData = this.settings[this.key.customData] || {};
        return customData[route] || {};
    }

    public setCustomDataForPath(path: string, customDataInput: any) {
        this.getSettings(this.key.gridPageSize);
        let customData = this.settings[this.key.customData];
        if (!customData) {
            this.settings[this.key.customData] = {};
        }
        this.settings[this.key.customData][path] = customDataInput;
        this.saveSettings();
    }

    public getLastUsedSearchPhrase(route: string): string {
        this.getSettings(this.key.lastUsedSearchPhrase);
        let lastUsedSearchPhrase = this.settings[this.key.lastUsedSearchPhrase] || {};
        return lastUsedSearchPhrase[route] || '';
    }

    public setLastUsedSearchPhrase(path: string, searchPhrase): void {
        this.getSettings();
        let lastUsedSearchPhrase = this.settings[this.key.lastUsedSearchPhrase];
        if (!lastUsedSearchPhrase) {
            this.settings[this.key.lastUsedSearchPhrase] = {};
        }
        this.settings[this.key.lastUsedSearchPhrase][path] = searchPhrase;
        this.saveSettings();
    }

    public getLastUsedFilterID(route: string): string {
        this.getSettings(this.key.lastUsedFilterID);
        let lastUsedFilterID = this.settings[this.key.lastUsedFilterID] || {};
        return lastUsedFilterID[route] || '';
    }

    public setLastUsedFilterID(path: string, filterID: string): void {
        this.getSettings();
        let lastUsedFilterID = this.settings[this.key.lastUsedFilterID];
        if (!lastUsedFilterID) {
            this.settings[this.key.lastUsedFilterID] = {};
        }
        this.settings[this.key.lastUsedFilterID][path] = filterID;
        this.saveSettings();
    }

    public getUIRoles(): Array<string> {
        let uiRolesString = sessionStorage.getItem(this.hcUIRoles);
        if (isNullOrWhitespace(uiRolesString)) {
            return null;
        }
        return JSON.parse(uiRolesString);
    }

    public setUIRoles(uiroles: Array<string>): void {
        sessionStorage.setItem(this.hcUIRoles, JSON.stringify(uiroles));
    }
}
