import { Component, OnInit, AfterViewInit, Output, EventEmitter, ViewChild, Renderer2, Input, ChangeDetectorRef } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';
import { MatCalendar } from '@angular/material/datepicker';

@Component({
  selector: 'hc-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements AfterViewInit  {

  @Output()
  dateSelected: EventEmitter<Date> = new EventEmitter();

  @Input()
  selectedDate: Date;

  // @ViewChild('calendar', { static: true })
  @ViewChild('calendar', {static: false})
  calendar: MatCalendar<Date>;

  constructor(private renderer: Renderer2,
              private cd: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this.calendar.activeDate = this.selectedDate;
    // this.cd.detectChanges();
  }

  dateChanged() {
    this.calendar.activeDate = this.selectedDate;
    this.dateSelected.emit(this.selectedDate);
  }
}
