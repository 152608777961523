
import { Report } from '../model/report.model';
import { GridDataSource } from '../tools/report-grid.datasource';
import { GridOptions,
    ColumnApi,
    GridApi,
    ColDef,
    RowNode,
    CellClickedEvent,
    RowGroupOpenedEvent,
    ValueGetterParams,
    SideBarDef,
    GetContextMenuItemsParams,
    CellClassParams,
    RowDataTransaction,
    IServerSideDatasource,
    ValueFormatterParams}     from '@ag-grid-enterprise/all-modules';
import { ReportDataModel } from '../model/report-data-model.enum';
import { ReportColumnSortDirection } from '../model/report-column-sort-direction.enum';
import { AggregateFunction } from '../model/aggregate-function.enum';
import { ReportColumnPinType } from '../model/report-column-pin-type.enum';
import { TranslationService } from 'src/app/shared/translation.service';
import { SavedReportContent } from '../model/saved-report-content.model';
import { ReportGridSavedState } from '../model/report-grid-savedState-model';
import { Observable } from 'rxjs';
import { ReportResult } from '../model/report-result.model';
import { ReportGridComponent } from '../report-grid.component';
import { DataTypeEnum } from '../../data-type.enum';
import { Globals } from '../../globals';
import { DetailRouting } from './detail-routing';
import { ReportColumn } from '../model/report-column.model';
import { ReportAction } from '../model/report-action-model';
import { DetailReportGridHandler } from './detail-report-grid-handler';
import { CreateClubDialogComponent } from 'src/app/user/club/create-club-dialog.component';
import { ClubCreationDataModel } from 'src/app/user/club/club-creation-data-model';
import { CreateProductSetDialogComponent } from 'src/app/product/product-set/creation/create-product-set-dialog.component';
import { EmailDialogComponent } from '../../dialog.email.component';
import { EmailDialogModel } from '../../email-dialog-model';
import { ReportFilter } from '../model/report-filter.model';

import { AuthService } from 'src/app/shared/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Honeycomb } from 'src/app/shared/honeycomb-api/honeycomb-api';
import { MultiLang } from '../../multi-lang/multi-lang.model';
import { MatDialog } from '@angular/material/dialog';

const jwtHelper = new JwtHelperService();

export class ReportGridHandler {

    public reportLoaded = false;
    public gridOptions: GridOptions;
    public gridApi: GridApi;
    public columnApi: ColumnApi;
    public paginationPageSize = 1000;
    public currentRecordCount = 0;

    public dataSource: GridDataSource;
    public columns: ColDef[] = [];

    public detailReportGridHandler: DetailReportGridHandler;


    private ensureVisibleRowIndex: number;
    private expandedRows: number[] = [];
    public onGridBuild: Function;
    public onInitialized: Function;

    constructor(
        private component: ReportGridComponent,
        private report: Report,
        private onDataLoaded: Function,
        private onDataLoadError: Function,
        private globals: Globals,
        private trans: TranslationService,
        private dialog: MatDialog,
        private authService: AuthService
        ) {
            this.gridOptions = this.createGridOptions();
            if (this.component.onOptionsInitialized) {
                this.component.onOptionsInitialized.emit(this.gridOptions);
            }
    }

    public columnTypes(): any {
        return this.component.gridSetup.getColumnTypes();
    }

    public canRun(): boolean {
        if (!this.dataSource) {
            return false;
        }
        return !this.report.reportFilters.find(f => f.required && !this.hasFilledValue( f ) );
    }

    public runReport( forceServerSideRowCount = false ) {
        if (!this.canRun()) {
            return;
        }

        this.saveGridState();
        // cleanup all seleted rows prior the run
        this.gridApi.getSelectedNodes().forEach( n => n.setSelected(false));

        if (this.report.reportDataModel === ReportDataModel.serverSide) {
            if (forceServerSideRowCount) {
                this.dataSource.lastQueryPattern = ''; // clear last query pattern to force the row count query
            }
            this.gridApi.setServerSideDatasource(this.dataSource as IServerSideDatasource);
        } else {
            // cleanup before loading
            this.gridApi.setRowData([]);
            this.gridApi.showLoadingOverlay();
            this.dataSource.getAllData( (data) => {
                this.gridApi.setRowData(data);
                this.gridApi.refreshCells();
            });
        }
    }

    public updatePagination(pageSize: number) {
        this.paginationPageSize = pageSize;
        if (this.paginationPageSize === this.component.gridSetup.allRecordsSize) {
            let size = this.dataSource.lastKnownTotalCount;
            if (size === 0) {
                // this may happen when the report is setup to show all pages
                // and it hasn't run yet, in such a case the final count is unknown
                // the right solution would be to run an empty count only query
                // as a default use 100 000 for the moment, the reason why don't use more is,
                // that the grid prealocate memory caches for the expected page and going over severla
                // hundred thousands costs lots of memory...
                size = 100000;
            }
            this.gridOptions.cacheOverflowSize = 1;
            this.gridOptions.maxBlocksInCache = 1;
            this.gridOptions.infiniteInitialRowCount = size;
            this.gridOptions.paginationPageSize = size;
            this.gridOptions.cacheBlockSize = size;
        } else {
            this.gridOptions.cacheOverflowSize = 2;
            this.gridOptions.maxBlocksInCache = 10;
            this.gridOptions.infiniteInitialRowCount = this.paginationPageSize;
            this.gridOptions.paginationPageSize = this.paginationPageSize;
            this.gridOptions.cacheBlockSize = this.paginationPageSize;
        }
        this.runReport();
    }

    public createIDsTempTable(): Observable<ReportResult> {
        return this.dataSource.createIDsTempTable();
    }

    public addNewRow() {
        if (this.report.reportDataModel !== ReportDataModel.clientSide) {
            throw 'Add new row only supported for the client side model';
        }
        // get the last row
        let nrObject = {};
        if (this.dataSource.lastData && this.dataSource.lastData.length > 0) {
            nrObject = Object.assign({}, this.dataSource.lastData[this.dataSource.lastData.length - 1]);
            Object.keys(nrObject).forEach(k => {nrObject[k] = null; });
        } else {
            this.report.reportColumns.forEach( c => {
                nrObject[c.uniqueColumnName] = null;
            });
        }
        let uidCol = this.report.reportColumns.find( c => c.rowIDColumn);
        nrObject[uidCol.columnName] = 0;            

        this.gridApi.updateRowData(<RowDataTransaction> {
            add: [nrObject]
        });
    }

    private dataLoadError(error) {
        if (this.report.reportDataModel === ReportDataModel.clientSide) {
            this.gridApi.setRowData([]);
            this.gridApi.refreshCells();
        }
        if (this.onDataLoadError) {
            this.onDataLoadError(error);
        }
    }

    private createGridOptions(): GridOptions {
        let serverSideModel = (this.report.reportDataModel === ReportDataModel.serverSide);
        let options = Object.assign( this.component.gridSetup.getDefaultOptions(), <GridOptions> {
            animateRows: true,
            rowSelection: 'multiple',
            defaultColDef: {
                resizable: true,
                sortable: true,
                enableRowGroup: true
            },
            onGridReady : (params) => {
                // ModuleRegistry.register(ClipboardModule);

                this.gridApi = params.api;
                this.columnApi = params.columnApi;
                if (this.component.onBeforeColumnsInitialized.observers.length > 0) {
                    this.component.onBeforeColumnsInitialized.emit( () => {
                        this.finalizeGridBuild();
                    });
                } else {
                    this.finalizeGridBuild();
                }
            },
            getChildCount : (data) => {
                if (data) {
                    return data['_count'];
                }
                return 0;
            },
            onRowGroupOpened: (params) => {
                let e = <RowGroupOpenedEvent>params;
                if (e.node.expanded) {
                    this.expandedRows.push(e.rowIndex);
                } else {
                    this.expandedRows = this.expandedRows.splice(this.expandedRows.indexOf(e.rowIndex), 1);
                }
            },
            onCellClicked: (event) => {
                let e = <CellClickedEvent>event;
                if (e.colDef.type && e.colDef.type.indexOf( 'detail' ) > 0) {
                    this.saveGridState();
                    this.processCellClickEvent(event);
                }
            },
            getContextMenuItems: (params: GetContextMenuItemsParams) => {
                let items = this.component.gridSetup.getContextMenuItems(undefined, params);
                if (this.report.reportActions) {
                    this.report.reportActions.forEach( a => {
                        let type = Honeycomb.Common.Enums.ReportActionType;
                        let ids = this.getSelectedRowIDs();
                        if (this.isSingleRowAction(a.action)) {
                            items.push({
                                name: this.trans.instant('admin.web.rep.action.' + type[a.action].toString()),
                                action: () => {
                                    this.processContextMenuActionForSingle(a);
                                }
                            });
                        } else {
                            if (ids.length > 0) {
                                items.push({
                                    name: this.trans.instant('admin.web.rep.action.' + type[a.action].toString()) +
                                            ' (' + ids.length.toString() + ')',
                                    action: () => {
                                        this.processContextMenuActionForSelected(a);
                                    }
                                });
                            }
                            items.push({
                                name: this.trans.instant('admin.web.rep.action.' + type[a.action].toString() + '.all') +
                                            ' (' + this.dataSource.lastKnownTotalCount + ')',
                                action: () => {
                                    this.processContextMenuActionForAll(a);
                                }
                            });
                        }
                    });
                }

                return items;
            },
            columnTypes : this.columnTypes(),
            rowGroupPanelShow : 'always',
            rowModelType: serverSideModel ? 'serverSide' : 'clientSide',
            //serverSideStoreType: ServerSideStoreType.Partial,
            serverSideStoreType: 'partial',
            cacheOverflowSize : 2,
            maxConcurrentDatasourceRequests : 1,
            infiniteInitialRowCount : 1000,
            paginationPageSize : this.paginationPageSize,
            cacheBlockSize : this.paginationPageSize,
            blockLoadDebounceMillis: 500,
            maxBlocksInCache : 10,
            groupIncludeFooter: this.report.showTotals,
            groupIncludeTotalFooter: this.report.showTotals,

            masterDetail: (!serverSideModel && this.report.detailReport) ? true : false,
            context: this.report
        });
        if (this.report.rowHeight && this.report.rowHeight !== 0) {
            options.rowHeight = this.report.rowHeight;
        }
        if (this.report.detailReport) {
            let map = {};
            this.report.reportDetailFilters.forEach( f => {
                let col = this.report.reportColumns.find( c => c.queryableColumnID === f.masterQueryableColumnID);
                if (col) {
                    map[col.uniqueColumnName] = f.detailQueryableColumnID;
                }
            });

            this.detailReportGridHandler = new DetailReportGridHandler( map,
                                                                        this.component,
                                                                        this.globals,
                                                                        this.report.detailReport,
                                                                        this.trans,
                                                                        this.dialog);
            options.detailCellRendererParams = {
                detailGridOptions: this.detailReportGridHandler.createGridOptions(),
                getDetailRowData: this.detailReportGridHandler.getDetailRowData.bind(this.detailReportGridHandler)
            };
        }
        return options;
    }

    public getSelectedRowIDs(): any[] {
        if (!this.gridApi) {
            return [];
        }
        return this.gerRowIDsInternal(this.gridApi.getSelectedNodes());
    }

    public getAllRowIDs(): any[] {
        if (!this.gridApi) {
            return [];
        }
        let nodes = [];
        this.gridApi.getModel().forEachNode( n => {
            nodes.push(n);
        });
        return this.gerRowIDsInternal(nodes);
    }

    private gerRowIDsInternal(nodes: RowNode[]) {
        let idcol = this.report.reportColumns.find( c => c.rowIDColumn );
        if (!idcol) {
            throw 'the report with no ID column specified is being asked for selected IDs';
        }
        let res = [];
        nodes.forEach( n => {
            res.push( n.data[idcol.uniqueColumnName] );
        });
        return res;
    }

    public createSavedReportContent(): SavedReportContent {
        let filterValues = {};
        this.report.reportFilters.forEach( f => {
            if (f.dateRange || f.value1) {
                const name = f.queryableColumn ? f.queryableColumn.sqlName : f.queryableParam.name;
                if (f.dateRange) {
                    // save only the period to always refres correct range values
                    filterValues[name] = f.dateRange;
                } else {
                    filterValues[name] = {value1: f.value1, value2: f.value2};
                }
            }
        });

        let res = () => 
            <SavedReportContent>{
                // don't include internal ag grid columns
                    columnsState: this.columnApi.getColumnState().filter( c => !c.colId.startsWith('ag-Grid')),
                    columnsGroupState: this.columnApi.getColumnGroupState(),
                    sortModel: this.columnApi.getColumnState(),
                    filterModel: this.gridApi.getFilterModel(),
                    filterValues: filterValues,
                    fulltextFilter: this.report.fulltextSearch,
                    sideBar: this.gridApi.getSideBar(),
                    pivotMode: this.columnApi.isPivotMode()
        };

        if (!this.columnApi) { // column api not ready yet
            setTimeout(() => {
                console.log('ag grid report not ready, wait 300ms');
                return res();
            }, 300);
        }

        return res();
    }

    public restoreSavedReportContent( content: SavedReportContent ) {
        // this.columnApi.setColumnState(content.columnsState);
        // doesn't work correctly for the automatic group column this.columnApi.setColumnGroupState(content.columnsGroupState);
        this.columnApi.applyColumnState({ state: content.sortModel });
        this.gridApi.setFilterModel(content.filterModel);
        if (content.sideBar) {
            // in the serverside mode, always ensure that the pivot will be disabled
            if (this.report.reportDataModel === ReportDataModel.serverSide) {
                // let sd = <SideBarDef>content.sideBar;
                let sd = <any>content.sideBar;
                if (sd.toolPanels) {
                    let tp = sd.toolPanels.find( p => p.id === 'columns');
                    if (tp) {
                        tp.toolPanelParams = {
                            suppressPivots: false,
                            suppressPivotMode: false,
                            suppressValues: false
                        };
                    }
                }
                this.gridApi.setSideBar( sd );
            } else {
                this.gridApi.setSideBar( content.sideBar);
            }
        }

        this.gridApi.setSideBar(<SideBarDef>{toolPanels: ['columns', 'filters']});

        if (content.pivotMode) {
            if (this.report.reportDataModel === ReportDataModel.serverSide) {
                this.columnApi.setPivotMode( false );
            } else {
                this.columnApi.setPivotMode( content.pivotMode );
            }
        }
    }

    public deleteGridState() {
        localStorage.removeItem( 'savedReportState_' + this.report.name );
    }

    public saveGridState() {
        let state = <ReportGridSavedState> {
            reportContent: this.createSavedReportContent(),
            firstDisplayRowIndex: this.gridApi.getFirstDisplayedRow(),
            expandedRows: this.expandedRows
        };
        localStorage.setItem( 'savedReportState_' + this.report.name, JSON.stringify(state) );
    }

    private parsedState: ReportGridSavedState;
    public loadGridState(): ReportGridSavedState {
        if (this.parsedState) {
            return this.parsedState;
        }
        let state = localStorage.getItem( 'savedReportState_' + this.report.name );
        if (state) {
            this.parsedState = JSON.parse( state );
            return this.parsedState;
        }
        return null;
    }

    public deleteSavedGridState() {
        this.parsedState = null;
        localStorage.removeItem( 'savedReportState_' + this.report.name );
    }

    private finalizeGridBuild() {
        // build columns
        this.columns = [];
        let aggFunction = AggregateFunction;
        this.report.reportColumns.forEach((e, index) => {
            if (!e.hidden) {
                let cd = <ColDef>{
                    headerName: this.component.trans.instant( e.description ),
                    field: e.uniqueColumnName,
                    editable: e.editable,
                    rowGroup: e.defaultGroupBy,
                    autoHeight: e.dynamicHeight,
                    type: this.component.gridSetup.getColumnType(e.dataType)
                };
                if (e.defaultValue) {
                    // when the default value is specified, setup a getter function
                    // which first checks the value and when empty, returns the default value
                    // watch out! the getter fuction is always called prior the formatter function
                    cd.valueGetter = (params: ValueGetterParams) => {
                        if (!params.data) {
                            return;
                        }
                        let val = params.data[params.colDef.field];
                        if (!val) {
                            return e.defaultValue;
                        }
                        return val;
                    };
                }
                if (e.reportDetailType) {
                    cd.type += ',detail';
                }
                if (e.aggregateFunction !== AggregateFunction.none &&
                    e.aggregateFunction !== AggregateFunction.groupBy &&
                    e.aggregateFunction !== AggregateFunction.hide) {
                    cd.aggFunc = aggFunction[e.aggregateFunction];
                }
                if (e.pinType !== ReportColumnPinType.none) {
                    let pt = ReportColumnPinType;
                    cd.pinned = pt[e.pinType];
                }
                if (e.dataType === DataTypeEnum.CodeList) {
                    let refData = this.component.gridSetup.getCodeListRefData(e.codeListID);
                    cd.refData = refData;
                    cd.cellEditorParams = {
                        values: this.component.gridSetup.getCodeListKeys(e.codeListID),
                        formatValue: (v) => {
                            return refData[v];
                        }
                    };
                }
                cd.cellStyle = (params: any) => {
                    if (!this.dataSource) {
                        return null;
                    }
                    return this.dataSource.getStyle( params.data, params.colDef.field );
                };
                let origClass = cd.cellClass;
                if (!origClass) {
                    origClass = '';
                } else {
                    origClass += ',';
                }
                origClass += this.component.gridSetup.getColumnTypeClasses( cd.type );
                cd.cellClass = (params: CellClassParams) => {
                    if (!this.dataSource) {
                        return origClass;
                    }
                    let res = this.dataSource.getClass( params.data, params.colDef.field, origClass );
                    if (res) {
                        return res;
                    }
                    return origClass;
                };

                // support for filtering after the formated value
                cd.filterParams = {
                    textFormatter: (p) => {
                        const col = this.columnApi.getColumn( cd.field ).getColDef();
                        if (col && col.valueFormatter && (typeof col.valueFormatter === 'function') ) {
                            const valueFormatterParams: ValueFormatterParams = {
                                ...p,
                                value: p,
                                data: p,
                                colDef: cd
                            };
                            return col.valueFormatter(valueFormatterParams);
                        }
                        return p;
                    }
                };
                this.columns.push(cd);
            }
        });
        if (this.report.detailReport) {
            this.columns[0].cellRenderer = 'agGroupCellRenderer';
        }
        this.paginationPageSize = this.report.defaultPageSize;
        if (this.report.showSelectionCheckbox) {
            this.columns[0].checkboxSelection = true;
            if (this.report.reportDataModel === ReportDataModel.clientSide) {
                this.columns[0].headerCheckboxSelection = true;
            }
        }
        if (this.component.onColumnsInitialized) {
            this.component.onColumnsInitialized.emit(this.columns);
        }
        this.gridApi.setColumnDefs( this.columns );
        this.gridApi.sizeColumnsToFit();

        if (this.onGridBuild) {
            this.onGridBuild();
        }
        this.dataSource = new GridDataSource( this.component,
            this.report,
            this.paginationPageSize,
            this.dataLoaded.bind(this),
            this.dataLoadError.bind(this) );
        let savedState = this.loadGridState();
        this.ensureVisibleRowIndex = 0;
        this.expandedRows = [];
        if (savedState) {
            this.ensureVisibleRowIndex = savedState.firstDisplayRowIndex;
            this.expandedRows = savedState.expandedRows;
            this.deleteSavedGridState();
        }
        
        let sortByColumns = this.report.reportColumns.filter( c => c.defaultSortDirection !== ReportColumnSortDirection.undefined )
                                                     .sort( c => c.defaultSortOrder );

        let sortDir = ReportColumnSortDirection;
        if (sortByColumns.length > 0) {
            var colState = this.columnApi.getColumnState();
            // let defaultSortModel = [];
            sortByColumns.forEach( c => {
                colState.push( {
                    colId: c.uniqueColumnName,
                    sort: (sortDir[c.defaultSortDirection] === 'asc') ? 'asc' : 'desc',
                });
            });
            this.columnApi.applyColumnState({ state: colState });
        }

        if (this.onInitialized) {
           this.onInitialized();
        }
    }

    private dataLoaded() {
        this.currentRecordCount = this.dataSource.lastKnownTotalCount;
        if (this.expandedRows && this.expandedRows.length > 0) {
            this.expandedRows.forEach( i => {
                let row = this.gridApi.getModel().getRow(i);
                if (row) {
                    row.expanded = true;
                }
            });
            if (this.report.reportDataModel === ReportDataModel.clientSide) {
                this.gridApi.onGroupExpandedOrCollapsed();
            }
        }

        if (this.ensureVisibleRowIndex !== 0) {
            this.gridApi.ensureIndexVisible(this.ensureVisibleRowIndex, 'top');
        }

        if (this.onDataLoaded) {
            this.onDataLoaded();
        }
    }

    private isSingleRowAction( action: Honeycomb.Common.Enums.ReportActionType): boolean {
        if (action === Honeycomb.Common.Enums.ReportActionType.showEmailDetail) {
            return true;
        }
        return false;
    }

    private async processCellClickEvent(clickEvent: CellClickedEvent) {
        let repCol = this.report.reportColumns.find( rc => rc.uniqueColumnName === clickEvent.colDef.field);
        if (!repCol.reportDetailType) {
            return;
        }

        let dr = DetailRouting.getDetailRoute( repCol.reportDetailType );
        let row = this.gridApi.getModel().getRow(clickEvent.rowIndex);

        let tenantColumn = this.report.reportColumns.find( rc => rc.dataType === DataTypeEnum.TenantHash);
        if (tenantColumn) {
            // Switch tenant
            let hashCol = this.findColumnByType(DataTypeEnum.TenantHash);
            let tenantHash = row.data[hashCol.uniqueColumnName];

            let tenant = this.globals.getMultiloginTenants().find(t => {
                let tokenDecoded = jwtHelper.decodeToken(t.jwt);
                return tenantHash === tokenDecoded.TenantHash;
            });
            await this.authService.setToken(tenant.jwt, true);
        }

        let idCol = this.findColumnByColName( dr.idCol );
        if (!idCol) {
            throw 'The required detail column: ' + dr.idCol + ' not found';
        }

        let id = row.data[idCol.uniqueColumnName];
        let url = dr.url.replace('{id}', id);

        let breadcrumbLocKey = row.data[repCol.uniqueColumnName] + '';
        if (!!clickEvent.colDef.refData && !!clickEvent.colDef.refData.breadcrumb) {
            breadcrumbLocKey = clickEvent.colDef.refData.breadcrumb;
        } else if (repCol.dataType === DataTypeEnum.MultiLang) {
            breadcrumbLocKey = MultiLang.defaultText(breadcrumbLocKey);
        }

        this.globals.navigateTo(url, { locKey: breadcrumbLocKey, customData: row.data });
    }

    private processContextMenuActionForSingle( action: ReportAction ) {
        let cell = this.gridApi.getFocusedCell();
        let node = this.gridApi.getModel().getRow(cell.rowIndex);

        switch (action.action) {
            case Honeycomb.Common.Enums.ReportActionType.showEmailDetail:
                this.showEmailDetail(node.data);
                break;
        }
    }

    private processContextMenuActionForSelected( action: ReportAction ) {
        let count = this.getSelectedRowIDs().length;
        if (count === 0) {
            let cell = this.gridApi.getFocusedCell();
            let node = this.gridApi.getModel().getRow(cell.rowIndex);
            node.setSelected(true);
            count++;
        }

        switch (action.action) {
            case Honeycomb.Common.Enums.ReportActionType.addUserToClub:
                this.addUsersToClub(count, false);
                break;

            case Honeycomb.Common.Enums.ReportActionType.addProductToSet:
                this.addProductsToSet(count, false);
                break;

            case Honeycomb.Common.Enums.ReportActionType.removeUsersFromClub:
                if (this.component.onDelete) {
                    this.component.onDelete.emit(false);
                }
                break;
        }
    }

    private processContextMenuActionForAll( action: ReportAction ) {
        let count = this.dataSource.lastKnownTotalCount;

        switch (action.action) {
            case Honeycomb.Common.Enums.ReportActionType.addUserToClub:
                this.addUsersToClub(count, true);
                break;

            case Honeycomb.Common.Enums.ReportActionType.addProductToSet:
                this.addProductsToSet(count, true);
                break;

            case Honeycomb.Common.Enums.ReportActionType.removeUsersFromClub:
                if (this.component.onDelete) {
                    this.component.onDelete.emit(true);
                }
                break;
        }
    }

    private addUsersToClub(count: number, addAll: boolean) {
        let ids: any[];
        if (!addAll) {
            ids = this.getSelectedRowIDs();
        }
        let dialogRef = this.dialog.open(CreateClubDialogComponent,
                                        { data: <ClubCreationDataModel>{ createTempTableDatasource: this.dataSource,
                                                                         createTempTable: addAll,
                                                                         rowIDs: ids }});
    }

    private addProductsToSet(count: number, addAll: boolean) {
        let dialogRef = this.dialog.open(CreateProductSetDialogComponent);
        if (addAll) {
            dialogRef.componentInstance['productIDs'] = this.getAllRowIDs();
        } else {
            dialogRef.componentInstance['productIDs'] = this.getSelectedRowIDs();
        }
    }

    private showEmailDetail(rowData: any) {
        let col = this.findColumnByColName('DistributionBatchItemID');
        if (!col) {
            console.log('Can not show email detail beacuse the DistributionBatchItemID column is missing');
        }
        let itemId = rowData[col.uniqueColumnName];
        this.component.mediaService.distributedMediaDetail(itemId).subscribe( r => {
            let dialogRef = this.dialog.open(EmailDialogComponent,
                {data: <EmailDialogModel>{
                    subject: r.subject,
                    sender: r.sender,
                    htmlContentSrc: this.component.sanitizer.bypassSecurityTrustResourceUrl(// 'url(\'' +
                                        this.component.mediaService.getUrlPrefix() +
                                        'distributedMediaContent/' +
                                        r.encryptedDistributionItemID +
                                        '?tenantID=' + this.globals.getTenantID() )// + '\')')
                }});
        });
    }

    private findColumnByColName(name: string): ReportColumn {
        return this.report.reportColumns.find(c => c.queryableColumn.name.toLowerCase() === name.toLowerCase());
    }

    private findColumnByType(dataType: DataTypeEnum): ReportColumn {
        return this.report.reportColumns.find(c => c.dataType === dataType);
    }


    public hasFilledValue( filter: ReportFilter ): boolean {
        if (!filter.value1) {
            return false;
        }
        let s = String(filter.value1).trim();
        if (s.startsWith('{') && s.endsWith('}')) { // it's a variable reference
            let v = s.substr( 1, s.length - 2);
            if (!this.component.variables[v]) {
                return false;
            }
        }
        return true;
    }

}
