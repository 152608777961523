import { Injectable }                               from '@angular/core';
import { ISortableFilter }                          from '../common/sortable-filter';
import { Globals }                                  from '../common/globals';
import { HttpClient } from '@angular/common/http';
import { Observable }                               from 'rxjs';
import { CommonService }                            from './common.service';
import { IBaseService }                             from './service.interface';
import { MediaModel,
         MediaDistributionResultModel,
         MediaExportRequest,
         MediaExportResult}              from '../common/media.model';
import { TenantTemplate } from '../setting/template/template.model';
import { DistributionBatchCreateRequest } from '../communication/media/distribution-batch-create-request.model';
import { DistributionBatchItemModel } from '../communication/media/distribution-items/distribution-batch-item.model';
import { RenameGroupDialogModel } from '../communication/media/rename-group-dialog/rename-group-dialog.model';
import { isNullOrUndefined } from '../common/functions';


@Injectable()
export class MediaService implements IBaseService<MediaModel> {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'media');
    }

    public getUrlPrefix(): string {
        return this._commonService.getUrlPrefix();
    }

    public getItems(filter: ISortableFilter): Observable<any> {
        return this._commonService.get('list', filter);
    }

    public getGroups(): Observable<any> {
        return this._commonService.get('groups');
    }

    public renameGroup(renameModel: RenameGroupDialogModel): Observable<any> {
        return this._commonService.post('RenameGroup', renameModel);
    }

    public select(filter: ISortableFilter): Observable<any> {
        return this._commonService.post('select', filter);
    }

    public renderViewPdf(item: MediaModel): Observable<any> {
        return this._commonService.post('renderViewPdf', item);
    }

    public renderViewHtml(item: MediaModel): Observable<any> {
       return this._commonService.post('renderViewHtml', item);
    }

    public getVoucherTemplate(id: string): Observable<MediaModel> {
        return this._commonService.get('getVoucherTemplate/' + id);
    }

    public getVoucherTemplateByName(name: string): Observable<MediaModel> {
        return this._commonService.get('getVoucherTemplateByName/' + name);
    }

    public updateVoucherTemplateFromMedia(model: MediaModel): Observable<number> {
        return this._commonService.post('updateVoucherTemplateFromMedia', model);
    }

    public getDetail(id: string): Observable<MediaModel> {
        return this._commonService.get('detail/' + id);
    }

    public getTemplate(templateID: number): Observable<TenantTemplate> {
        return this._commonService.get('MediaTemplate/' + templateID);
    }

    public checkName(name: string, mediaID: number = null): Observable<any> {
        let queryParams = '';
        if (!isNullOrUndefined(mediaID)) {
            queryParams = '?mediaID=' + mediaID;
        }
        return this._commonService.get('CheckName/' + encodeURI(name) + queryParams);
    }

    public getWalletTemplates(): Observable<any> {
        return this._commonService.get('GetWalletTemplates');
    }

    public distribute(item: MediaModel): Observable<any> {
        return this._commonService.post('Distribute', item);
    }

    public distributeToGroups(request: DistributionBatchCreateRequest): Observable<any> {
        return this._commonService.post('DistributeToGroups', request);
    }

    public distributeToEmails(request: DistributionBatchCreateRequest): Observable<any> {
        return this._commonService.post('DistributeToEmails', request);
    }

    public insertItem(item: MediaModel): Observable<any> {
        return this._commonService.insertItem(item);
    }

    public cloneItem(item: MediaModel): Observable<any> {
        return this._commonService.post('Clone', item);
    }

    public mediaFromLibrary(model: MediaModel): Observable<any> {
        return this._commonService.post('MediaFromLibrary', model);
    }

    public updateItem(id: string, item: MediaModel): Observable<any> {
       return this._commonService.updateItem(id, item);
    }

    public deleteItem(id: string): Observable<any> {
        return this._commonService.deleteItem(id);
    }

    public getUserDistributionResult(userId: number): Observable<MediaDistributionResultModel[]> {
        return this._commonService.get('GetUserDistribution/' + userId, null );
    }

    public distributedMediaDetail(distributionBatchItemID: Number): Observable<DistributionBatchItemModel> {
        return this._commonService.get('distributedMediaDetail/' + distributionBatchItemID.toString(), null );
    }

    public import(files: File[]): Observable<void> {
        return this._commonService.uploadFile('import', files, null, null, 120000);
    }

    public export(request: MediaExportRequest): Observable<MediaExportResult> {
        return this._commonService.post('export', request );
    }

    public importVoucherCodes(id: number, files: File[]): Observable<void> {
        return this._commonService.uploadFile('importVoucherCodes/' + id, files, null, null, 120000);
    }

    public updateCreatedVouchersValidity(id: number): Observable<void> {
        return this._commonService.post('updateCreatedVouchersValidity/' + id, null);
    }
}
