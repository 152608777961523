export enum ComponentType {
    Unset = 0,
    // tslint:disable:no-bitwise
    ProductCatalog  = 1 << 0,
    Basket          = 1 << 1,
    ProductSearch   = 1 << 2,
    ProductDetail   = 1 << 3,
    DynamicMenu     = 1 << 4,
    LevelMenu       = 1 << 5,
    FilterPanel     = 1 << 6,
    TemplateRenderer    = 1 << 7,
    UserInfoLine    = 1 << 8,
    UserAccount     = 1 << 9,
    General         = 1 << 10,
    RatingStars     = 1 << 11,
    Survey          = 1 << 12,
    MediaRenderer   = 1 << 13
}
