import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputDialogContent } from './dialog-input-content.model';
import { EmailDialogModel } from './email-dialog-model';

/**
 * @title Dialog Overview
 */
@Component({
  selector: 'hc-email-dialog',
  template:
  `
  <div fxLayout="column" style="height: 450px; width:640px">
        <p fxFlex style="argin-block-end: 0px;">
            {{'admin.web.from'|trans}}: <b>{{ data.sender }}</b>
        </p>
        <p fxFlex style="argin-block-end: 0px;">
            {{'admin.web.subject'|trans}}: <b>{{ data.subject }}</b>
        </p>
        <iframe [src]="data.htmlContentSrc" style="width:640px; height:300px;">
        </iframe>
        <div style="margin-top: 10px; margin-bottom: 10px" fxFlex="none"
                    fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-around center">
            <button mat-raised-button color="primary" (click)="clickOk()" >{{ 'admin.web.ok' | trans }}</button>
        </div>
  </div>
   `
})

export class EmailDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<EmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EmailDialogModel
    ) {
        console.log('src parameter is: ' + data.htmlContentSrc);

    }

    clickOk(): void {
        this.dialogRef.close(true);
    }
    clickCancel(): void {
        this.dialogRef.close(false);
    }
}
