
import { throwError as observableThrowError,
         Observable, 
         lastValueFrom}           from 'rxjs';
import { Injectable }           from '@angular/core';
import { Globals }              from '../common/globals';
import { catchError, finalize, map, share }                from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

const jwtHelper = new JwtHelperService();

@Injectable()
export class TranslationService {
    private _currentLang: string;

    private _dict: any = null;

    apiCall;

    constructor(
        private http: HttpClient,
        private globals: Globals) {

        this.apiCall = Observable.create(observer => {
            observer.next();
        }).pipe(share());
    }

    public async get(cultureCode: string, noCache = false, disableContentUpdate = false) {
        let request: Observable<any>;

        let param = '';
        if (noCache) {
            param = '?noCache=1';
        }

        if (this.loggedIn()) {
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            let options = { headers: headers };
            request = this.http.get(this.globals.GetUrlPrefix() + '/api/Localization/CultureShort/' + cultureCode + param, options);
        } else {
            // previous tenant ID, not necessary for login
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            headers.append('TenantID', localStorage['tenantId'] || 1);
            let options = { headers: headers };
            request = this.http.get(this.globals.GetUrlPrefix() + '/api/Localization/CultureShort/' + cultureCode + param, options);
        }

        var res = await lastValueFrom(request.pipe(
                map(data => {
                    let translations = {};
                    data.map(m => translations[m.k.trim().toLowerCase()] = m.v);
                    this._dict = translations;
                    sessionStorage.setItem('Honeycomb.Translation.' + cultureCode, JSON.stringify(translations));
                    console.log('Translation for "' + cultureCode + '" loaded');
                    if (!disableContentUpdate) {
                        this.globals.forceUpdateContent.emit();
                    }
                }),
                catchError(err => err.error || err), 
                finalize(() => {this.globals.isTranslationsDownloadRunning = false; }))
            );
    }

    loggedIn() {
        return !jwtHelper.isTokenExpired(this.globals.getToken());
      }

    public use(lang: string): void {
        this._currentLang = lang;
    }

    private translate(key: string): string {
        if (!key) {
            return '';
        }

        if (typeof key !== 'string') {
            return key + '';
        }

        let k = key.trim().toLowerCase();
        if (!this._dict) {
            let dict = sessionStorage['Honeycomb.Translation.' + this.globals.getLanguage()];
            if (!!dict) {
                this._dict = !JSON.parse(dict);
            }
            if (!this.globals.isTranslationsDownloadRunning) {
                this.globals.isTranslationsDownloadRunning = true;
                this.get(this.globals.getLanguage());
            }
        } else {
            // tslint:disable-next-line:curly
            let res = this._dict[k];
            if (!res)  {
                let missingKeys = sessionStorage['Honeycomb.Translation.Missing.' + this.globals.getLanguage()];
                if (!missingKeys) { missingKeys = '[]'; }
                 let missingKeysArray = <Array<string>>JSON.parse(missingKeys);
                 if (missingKeysArray.indexOf(k) === -1) {
                    missingKeysArray.push(k);
                    sessionStorage['Honeycomb.Translation.Missing.' + this.globals.getLanguage()] = JSON.stringify(missingKeysArray);
                 }

                return key;
            }
            return res;
        }
        return key;
    }

    public instant(key: string): string {
        // public perform translation
        return this.translate(key);
    }
}
