import { isNullOrWhitespace } from '../functions';

export class MultiLang {
    static checker = /\s*\{\s*("[a-z]{2}"\s*:\s*"([^"\\]|\\.)*"\s*,?\s*)+\s*\}\s*/;
    private langs: string[] = [];
    private singleMode: boolean;
    private text: string;
    private locales: Array<{ lang: string, text: string }> = [];

    public get isSingle(): boolean {
        return this.singleMode;
    }

    public get canBeMulti(): boolean {
        return this.langs.length > 1;
    }

    public get displayValue(): string {
        if (this.isSingle) { return this.text; }
        let firstValue = this.locales[0];
        return `${firstValue.text} (${firstValue.lang}+${this.langs.length - 1})`;
    }

    constructor(public supportedLanguages: string[]) {
        this.langs = supportedLanguages;
        this.singleMode = true;
        this.text = '';
        this.locales = this.langs.map(l => ({ lang: l, text: '' }));
    }

    parse(multiText: string): void {
        let dict = null;
        try {
            let obj = JSON.parse(multiText);
            dict = (typeof(obj) === 'object') ? obj : null;
        } catch {
            dict = null;
        }
        this.singleMode = (dict == null) || (this.langs.length < 2);
        this.text = (dict && (dict[this.langs[0]] || dict[Object.keys(dict)[0]])) || multiText;
        this.locales = this.langs.map(l => ({ lang: l, text: (dict && dict[l]) || '' }));
    }

    toSingle(): void {
        if (this.singleMode) { return; }
        let firstValue = this.locales.find(loc => !isNullOrWhitespace(loc.text));
        this.text = (firstValue && firstValue.text) || '';
        this.singleMode = true;
    }

    toMulti(): void {
        if (!this.singleMode || this.langs.length < 2) { return; }
        this.locales.filter(loc => isNullOrWhitespace(loc.text)).forEach(loc => loc.text = this.text);
        this.singleMode = false;
    }

    toString(): string {
        if (this.singleMode) { return this.text; }
        let locs = this.locales.filter(loc => !isNullOrWhitespace(loc.text));
        let dict = locs.reduce((acc, cur) => { acc[cur.lang] = cur.text; return acc; }, {});
        if (!Object.keys(dict).length) { return ''; }
        return JSON.stringify(dict);
    }

    static defaultText(val: string): string {
        if (!val || !MultiLang.checker.test(<string>val)) { return val; }
        try {
            const obj = JSON.parse(val);
            return <string>obj[Object.keys(obj)[0]];
        } catch { return val; }
    }
}
