import { Component, OnInit, ViewEncapsulation, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { TranslationPipe } from 'src/app/shared/translation.pipe';
import { TranslationService } from 'src/app/shared/translation.service';
import { Globals } from '../globals';
import { objectToParams } from '../functions';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'hc-rich-editor-dialog',
  template: `
  <h2 mat-dialog-title>{{header}}</h2>
    <mat-dialog-content>
      <div class="html-editor-frame" style="">
        <iframe id="rich-editor" [src]="safeUrl" width="100%" style="height: 100%; border: none;">
        </iframe>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions fxLayoutAlign="end center">
      <button mat-raised-button mat-dialog-close (click)="close()">{{'admin.web.close' | trans}}</button>
      <button mat-raised-button color="primary" (click)="save()">{{'admin.web.ok' | trans}}</button>
    </mat-dialog-actions>
  `,
  styles: [],
  encapsulation: ViewEncapsulation.None
})
export class RichEditorDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  public safeUrl: SafeResourceUrl;
  public header: string;
  private language = 'cs';
  private content = '';

  private textAreaRef: HTMLTextAreaElement;

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<RichEditorDialogComponent>,
    private globals: Globals
  ) {
    this.language = this.globals.getLanguage().split('-')[0];
    this.content = data.content;
    data.content = null; // avoid serialization to URL
    data.language = this.language;
    let params = objectToParams(data);
    let url = `/rich_editor.htm?${params}`;
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.header = data.header;
  }

  reassingHtmlContent() {
    let dataContent = this.content;
    let fra = document.getElementById('rich-editor') as HTMLIFrameElement;
    if (!fra.contentDocument && !fra.contentWindow) {
      return; // Event of closed iframe
    }
    let mcebody = this.getMceBody();
    mcebody.innerHTML = dataContent;
  };

  ngAfterViewInit() {
    // set data when tinymce loaded
    window.document.addEventListener('tinymce-initialized', this.reassingHtmlContent.bind(this));
  }

  ngOnDestroy(): void {
    window.document.removeEventListener('tinymce-initialized', this.reassingHtmlContent.bind(this));
  }

  ngOnInit() {
    this.dialogRef.backdropClick().subscribe(_ => {
      let fra = document.getElementById('rich-editor') as HTMLIFrameElement;
    (<any>fra.contentWindow.window).tinymce.remove();
    })
  }

  save() {
    let mcebody = this.getMceBody();
    this.dialogRef.close({
      action: 'save',
      content: mcebody.innerHTML
    });
  }

  close() {
    let mcebody = this.getMceBody();
    let fra = document.getElementById('rich-editor') as HTMLIFrameElement;
    (<any>fra.contentWindow.window).tinymce.remove();

    this.dialogRef.close({
      action: 'close',
      content: mcebody.innerHTML
    });
  }

  private getMceBody() {
    let fra = document.getElementById('rich-editor') as HTMLIFrameElement;
    let fraContent = fra.contentDocument || fra.contentWindow.document;
    let mceiframe = fraContent.getElementById('myTextarea_ifr') as HTMLIFrameElement;
    let mcebody = mceiframe.contentDocument.getElementById('tinymce');
    return mcebody;
  }

}
