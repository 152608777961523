import { RequestParamType } from './request-param-type.enum'

export class RequestParam {
    name: string;
    type: RequestParamType;
    value: any;
    constructor(name: string, type: RequestParamType, value: any) {
        this.name = name;
        this.type = type;
        this.value = value;
    }
}