import { SettingService } from '../../../shared/setting.service';
import { LicenseManager } from 'ag-grid-enterprise';
import { catchError, share } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AGGridLicenser {
    constructor(
        private settingService: SettingService
    ) {

    }
    public setAGGridLicense() {
        let license = sessionStorage.getItem('glcs');
        if (license) {
            LicenseManager.setLicenseKey(license);
            return;
        }

        // Set ag grid license
        this.settingService.getCentralServiceDetail('agGridLicense')
        .pipe(
            catchError(err => of({ settingValue: null })),
            share()
        )
        .subscribe(s => {
            if (!s.settingValue) {
                console.error('The AG grid license is missing!');
                return;
            }
            sessionStorage.setItem('glcs', s.settingValue);
            LicenseManager.setLicenseKey(s.settingValue);
        }, err => {
            console.error('The AG grid license request failed!');
            console.error(err);
        });
    }
}
