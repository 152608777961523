export class SurveyQuestion {

    public surveyQuestionId: number;
    public question: string;
    public description: string;
    public answerType: QuestionAnswerType;
    public answerRequired: boolean;
    public questionAnswers: AnswerModel[];
    public surveyResult: any[];
    public name: string;
    public group: string;
    public ordinal: number;
    public created: Date;
    public updated: Date;
    public hasAnyResult: boolean;
}

export class AnswerModel {
    public answer: string;
    public name: string;
    public answerNumber: number;
    public questionAnswerId: number;
    public isActive: boolean;
    public hasAnswer: boolean;

    // for editor only
    public isSelected: boolean;
}

export class SurveyModel {
    public surveyId: number;
    public isActive: boolean;
    public name: string;
    public group: string;
    public refName: string;
    public description: string;
    public type: any;
    public saveSnapshot: boolean;
    public status: number;
    public motivationType: any;
    public numberOfBonusPoints: number;
    public voucherTemplateId: number;
    public layoutTemplateId: number;
    public layoutTemplateParameters: string;
    public created: Date;
    public updated: Date;
    public redirectScreen: string;
    public redirectDelay: number;
    public redirectUrl: string;
    public showEmailRegistration: boolean;

    public questionsInSurvey: QuestionsInSurvey[];
    public surveyQuestions: SurveyQuestion[];
    public surveyResult: any[];
    public surveyDistributions: any[];
}

export class QuestionsInSurvey {
    questionInSurveyId: number;
    surveyQuestionId: number;
    surveyId: number;
    ordinal: number;
    surveyQuestion: SurveyQuestion;
}

export enum QuestionAnswerType {
    YesNo = 0,
    OneToTen = 1,
    Stars5 = 2,
    OneOfOptions = 3,
    MoreOfOptions = 4,
    FreeText = 5,
    Number = 6,
    DateTime = 7,
    ShortText = 8,
    Time = 9
}

export enum SurveyType {
    Permanent = 0,
    OneTimeFill = 1
}

export enum MotivationType {
    None = 0,
    DiscountVoucher = 1,
    BonusPoints = 2
}

