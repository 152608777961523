import { Component,
         Inject }               from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'hc-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent {

  public saveUrl: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
  }
}
