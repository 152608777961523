import { Queryable } from './queryable.model';
import { ReportColumn } from './report-column.model';
import { FilterViewModel } from './filter-view.model';
import { ReportType } from './report-type.enum';
import { ReportFilter } from './report-filter.model';
import { ReportQueryable } from './report-queryable.model';
import { ReportDataModel } from './report-data-model.enum';
import { ReportDetailFilter } from './report-detail-filter.model';
import { SavedReport } from './saved-report.model';
import { FilterPurpose } from '../../filter-purpose.enum';
import { ReportAction } from './report-action-model';

export class Report {
    reportID: number;
    name: string;
    description: string;

    created: Date | string;
    updateTime: Date | string;

    reportQueryables: ReportQueryable[];
    reportFilters: ReportFilter[];
    reportColumns: ReportColumn[];
    reportDetailFilters: ReportDetailFilter[];
    savedReports: SavedReport[];
    reportActions: ReportAction[];
    reportRoles: ReportRole[] = [];
    showSelectionCheckbox: boolean;
    defaultPageSize: number;
    showTotals: boolean;

    reportGroup: string;
    reportType =  ReportType.Public;
    detailReportID: number;
    detailReport: Report;
    rowHeight: number;
    reportDataModel = ReportDataModel.clientSide;
    advancedFilterPurpose: FilterPurpose;
    showFulltextFilter: boolean;
    maxRecordCount: number;
    commandTimeout: number;

    enableUpdate: boolean;
    enableDelete: boolean;
    enableInsert: boolean;
    enableImport: boolean;
    updateQuery: string;
    deleteQuery: string;
    updateExchange: string;
    updateMessage: string;
    updateMessageTemplate: string;
    updateMessageObjectType: string;

    // not mapped from the backend model, used internaly
    fulltextSearch: string;

    constructor() {
        this.reportColumns = [];
        this.reportFilters = [];
        this.reportQueryables = [];
        this.savedReports = [];
        this.reportActions = [];
        this.defaultPageSize = 10000000; // default is client model with all record size
    }
}

export class ReportRole {
    reportRoleID: number;
    roleID: number;
    reportID: number;
}
