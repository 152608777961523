import { Injectable }       from '@angular/core';
import { ISortableFilter }  from '../common/sortable-filter';
import { Globals }          from '../common/globals';
import { HttpClient }       from '@angular/common/http';
import { Observable }       from 'rxjs';
import { CommonService }    from './common.service';
import { SettingModel }     from '../setting/keyvalue/setting.model';
import { Certificate,
         CertificateType }  from './certificate.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SettingService {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals, snackbar: MatSnackBar) {
        this._commonService = new CommonService(http, globals, 'setting');
    }

    public filter(filter: ISortableFilter): Observable<Array<SettingModel>> {
        return this._commonService.post('filter', filter);
    }

    public save(model: SettingModel) {
        return this._commonService.updateItem(model.settingKey, model);
    }

    public getCentralServiceDictionary() {
        return this._commonService.get('centralServiceDictionary');
    }

    public getCentralServiceDetail(key: String): Observable<SettingModel> {
        return this._commonService.get('centralServiceDetail/' + key);
    }

    public getCentralServiceCertificates(type: CertificateType): Observable<Certificate[]> {
        return this._commonService.get('centralServiceCertificates/' + type);
    }

}
