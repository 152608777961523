import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    OnDestroy
} from '@angular/core';
import { forkJoin } from 'rxjs';
import {
    SortDirection,
} from '../sortable-filter';
import { SavingDialogComponent } from '../saving-dialog.component';
import { Globals } from '../globals';
import { TranslationService } from '../../shared/translation.service';
import { BaseComponent } from '../base.component';
import { FilterViewModel } from '../../setting/filter/filter-view.model';
import { FilterService } from '../../shared/filter.service';
import { FilterPurpose } from '../filter-purpose.enum';
import { FilterDialogService } from '../../shared/filter-dialog-service';
import { FilterDialog } from '../filter-dialog/models/filter-dialog.model';
import { FilterDialogConfig } from '../filter-dialog/models/filter-dialog-config.model';
import { FilterDialogType } from '../filter-dialog/enums/filter-dialog-type.enum';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
import { ElasticFilterRequest } from '../elastic-filter-request.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { isNullOrUndefined } from '../functions';

@Component({
    selector: 'hc-filter-selector',
    templateUrl: './filter-selector.component.html',
    styleUrls: ['./filter-selector.component.scss']
})
export class FilterSelectorComponent implements OnInit, OnDestroy {
    ngOnDestroy(): void {
        // Has to be defined, don't know why right now
    }

    @Input() public filterPurpose = FilterPurpose.undefined;
    @Output() public performSearch = new EventEmitter();
    @Output() public onInitialized = new EventEmitter();
    @Output() public filter = new ElasticFilterRequest('UserId', SortDirection.desc);

    public customFilters: any[];
    public customFilter: any;
    public filterTypes: FilterViewModel[] = [];
    public selectedFilterType: FilterViewModel;

    constructor(
        private filterService: FilterService,
        private filterDialogService: FilterDialogService,
        private globals: Globals,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
        private trans: TranslationService,
        private cd: ChangeDetectorRef
    ) {
        // super(globals);
    }

    ngOnInit() {
        let cloakRef = this.dialog.open(SavingDialogComponent);
        forkJoin(
            this.filterService.getItemsForPurpose(this.filterPurpose),
            this.filterDialogService.getItemsForPurpose(this.filterPurpose)
        ).subscribe(
            responses => {
                this.filterTypes = responses[0];
                this.customFilters = [];
                this.fillCustomFilters(responses[1]);
                this.selectSavedFilterDialogFilter();
            },
            error => {
                this.snackbar.open(this.trans.instant('admin.web.data-download-error'), '', { duration: 2000 });
            },
            () => {
                cloakRef.close();
            }
        );
    }

    private fillCustomFilters( cfs: []) {
        this.customFilters = [];
        cfs.forEach(e => {
            this.customFilters.push(e);
        });
        this.customFilters.push({
            name: '--- ' + this.trans.instant('admin.web.add-new') + ' ---',
            internalAddNew: true
        });
    }

    private selectSavedFilterDialogFilter() {
        let lastUsedFilterID = this.globals.getLocalStorage().getLastUsedFilterID(this.globals.currentNavItem().path);
        let customFilter = this.customFilters.find(cf => cf.filterDialogID === lastUsedFilterID);
        if (customFilter) {
            this.customFilter = customFilter;
            let cloakRef = this.dialog.open(SavingDialogComponent);
            forkJoin(
                this.filterDialogService.getDetail(customFilter.filterDialogID),
                this.filterDialogService.getFilterInputs()
            ).subscribe(
                results => {
                    cloakRef.close();
                    let filterDialog = FilterDialog.parse(results[0], this.filterTypes);
                    this.filter = filterDialog.getFilter();
                    this.filter.filterPurpose = this.filterPurpose;
                    if (this.onInitialized) {
                        this.onInitialized.emit(this.filter);
                    }
                },
                error => {
                    this.snackbar.open(this.trans.instant('admin.web.data-download-error'), '', { duration: 2000 });
                    cloakRef.close();
                });
        } else {
            if (this.onInitialized) {
                this.onInitialized.emit(null);
            }
        }
    }

    private loadFilterDialogs(filterDialogID?: number) {
        let cloakRef = this.dialog.open(SavingDialogComponent);
        this.filterDialogService.getItems(null).subscribe(
            response => {
                this.fillCustomFilters(response);
                if (!isNullOrUndefined(filterDialogID)) {
                    this.customFilter = this.customFilters.find(cf => cf.filterDialogID === filterDialogID) || '';
                } else {
                    this.customFilter = null;
                }
                this.cd.detectChanges();
            },
            error => {
                console.warn(error);
                this.snackbar.open(this.trans.instant('admin.web.data-download-error'), '', { duration: 2000 });
            },
            () => {
                cloakRef.close();
            }
        );
    }

    public newFilter() {
        this.openFilterDialog();
    }

    public search() {
        if (this.filter != null) {
            this.filter.filterPurpose = this.filterPurpose;
        }
        this.performSearch.emit(this.filter);
    }

    public filterSelectionChange() {
        if (!isNullOrUndefined(this.customFilter) && typeof (this.customFilter) !== 'string') {
            if (this.customFilter.internalAddNew) {
                this.newFilter();
            } else {
                this.openFilterDialog(FilterDialog.parse(this.customFilter, this.filterTypes));
            }
        } else {
            // only happens when "not filter" is selected
            this.filter = null;
            this.globals.getLocalStorage().setLastUsedFilterID(this.globals.currentNavItem().path, '');
            this.search();
        }
    }

    private openFilterDialog(filterDialog?: FilterDialog) {
        let filterDialogConfig: FilterDialogConfig = {
            filterDialog: null,
            filterTypes: this.filterTypes,
            isNew: !filterDialog,
            filterMethod: this.search,
            onSearch: (filter) => {
                this.filter = filter;
                this.globals.getLocalStorage().setLastUsedFilterID(this.globals.currentNavItem().path, this.customFilter.filterDialogID);
                this.search();
            },
            onSaved: (filterID?: number) => {
                this.loadFilterDialogs(filterID);
            }
        };
        if (filterDialogConfig.isNew) {
            filterDialogConfig.filterDialog = new FilterDialog(null, FilterDialogType.ContactFilter, '', null, [], this.filterTypes);
            this.dialog.open(FilterDialogComponent, { data: filterDialogConfig });
        } else {
            let cloakRef = this.dialog.open(SavingDialogComponent);

            forkJoin(
                this.filterDialogService.getDetail(filterDialog.id),
                this.filterDialogService.getFilterInputs()
            ).subscribe(
                results => {
                    filterDialogConfig.filterDialog = FilterDialog.parse(results[0], this.filterTypes);
                    filterDialogConfig.filterInputTypes = results[1];
                    this.dialog.open(FilterDialogComponent, { data: filterDialogConfig });
                    cloakRef.close();
                },
                error => {
                    this.snackbar.open(this.trans.instant('admin.web.data-download-error'), '', { duration: 2000 });
                    cloakRef.close();
                }
            );
        }
    }
}

