export enum SortDirection {
    asc,
    desc
}

export interface ISortableFilter {
    sortColumn: string;
    sortDirection: SortDirection;
    page: number;
    pageSize: number;

    noCache: number; // IE fix

    searchString: string;
}

export class SortableFilter implements ISortableFilter {
    sortColumn: string;
    sortDirection: SortDirection;
    page = 1;
    pageSize = 10;

    searchString = '';

    noCache: number; // IE fix

    constructor(sortColumn: string, sortDirection: SortDirection = SortDirection.asc) {
        this.sortColumn = sortColumn;
        this.sortDirection = sortDirection;
    }
}
