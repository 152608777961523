import { FilterInputType }          from './filter-input-type.model';
import { DataTypeEnum }             from '../../data-type.enum';
import { CategoryMenuNodeModel }    from '../../../product/category-node-model';
import { AnswerModel,
         SurveyQuestion,
         QuestionAnswerType }       from '../../../survey/survey.model';
import { FilterViewModel } from '../../../setting/filter/filter-view.model';

export class FilterDialogItemValues {

    private filterDialogFilterValueID: number;
    public filterDialogItemID: number;
    public placeholderName: string;
    public filterInputTypeID: number; // fix
    public value: string;
    public displayOrder: number;
    public filterInputType: FilterInputType;
    private _categoryNames: string;
    private _selectedModel: any;
    private _surveyQuestion: SurveyQuestion;
    public filterName: any;
    private _range: number[] = [null, null];

    constructor(
        filterDialogFilterValueID: number,
        filterDialogItemID: number,
        placeholderName: string,
        filterInputTypeID: number,
        value: string,
        displayOrder: number,
        filterInputType: FilterInputType,
        filterName?: string
    ) {
        this.filterDialogFilterValueID = filterDialogFilterValueID || 0;
        this.filterDialogItemID = filterDialogItemID || 0;
        this.placeholderName = placeholderName;
        this.filterInputTypeID = filterInputTypeID;
        this.value = value.toString();
        this.displayOrder = displayOrder || 0;
        this.filterInputType = filterInputType;
        this.filterName = filterName || '';
    }

    public static parse(serialized: any, filter: FilterViewModel): FilterDialogItemValues {
        let filterDialogFilterValue = new FilterDialogItemValues(
            serialized.filterDialogItemFilterID,
            serialized.filterDialogItemID,
            serialized.placeholderName,
            serialized.filterInputTypeID,
            serialized.value.toString(),
            serialized.displayOrder,
            // FilterInputType.parse(serialized.filterInputType),
            filter.filterInputTypes.find(fit => fit.filterInputTypeID === serialized.filterInputTypeID),
            filter.filterName
        );
        return filterDialogFilterValue;
    }

    public parseFilters(filterID: number, filterInputTypes: FilterInputType[]): FilterInputType[] {
        return filterInputTypes.filter(filterInputType => filterInputType.filterInputTypeID === filterID);
    }

    public get id(): number {
        return this.filterDialogFilterValueID;
    }

    public set id(id: number) {
        this.filterDialogFilterValueID = id;
    }

    public get label(): string {
        return 'admin.filter.' + this.filterName + '.' + this.filterInputType.placeholderName;
    }

    public getLabel(): string {
        return 'admin.filter.' + this.filterName + '.' + this.filterInputType.placeholderName;
    }

    public get inputType(): string {
        switch (this.filterInputType.dataType) {
            case DataTypeEnum.Text: return 'text';
            case DataTypeEnum.Number:
            case DataTypeEnum.IntegerNumber:
            case DataTypeEnum.Money: return 'number';
        }
        return 'text';
    }

    public get categoryNames(): string {
        return this._categoryNames || '';
    }

    public onModelChanged(models) {
        let data = this.parseValues(models),
            namesOverflow = false,
            names = Object.values(data),
            viewLimit = 10;
        this.value = Object.keys(data).join(',');
        if (names.length > viewLimit) {
            namesOverflow = true;
            names = names.slice(0, viewLimit);
        }
        this._categoryNames = names.join(', ');
        this._categoryNames += namesOverflow ? ', ...' : '';
    }

    public get selectedModel(): any {
        return this._selectedModel;
    }

    public set selectedModel(model) {
        this._selectedModel = model;
    }

    private parseValues(models: CategoryMenuNodeModel[], data: any = {}): any {
        for (let i = 0; i < models.length; i++) {
            if (models[i].isSelected) {
                data[models[i].categoryId] = models[i].caption;
            }
            data = this.parseValues(models[i].children || [], data);
        }
        return data;
    }

    public setValues(values: string[], categories: CategoryMenuNodeModel[]) {
        for (let i = 0; i < categories.length; i++) {
            categories[i].isSelected = values.indexOf(categories[i].categoryId.toString()) > -1;
            this.setValues(values, categories[i].children || []);
        }
    }

    public set surveyQuestion(surveyQuestion: SurveyQuestion) {
        switch (surveyQuestion.answerType) {
            case QuestionAnswerType.MoreOfOptions:
                surveyQuestion.questionAnswers.forEach(a => a.isActive = false);
                break;
            case QuestionAnswerType.OneOfOptions:
                surveyQuestion.questionAnswers.forEach(a => a.isActive = false);
                break;
            case QuestionAnswerType.YesNo:
                let questionAnswer = new AnswerModel();
                questionAnswer.answer = 'admin.web.accepted';
                questionAnswer.answerNumber = 1;
                questionAnswer.isActive = false;
                questionAnswer.questionAnswerId = 1;
                surveyQuestion.questionAnswers.push(questionAnswer);
                break;
        }
        this._surveyQuestion = surveyQuestion;
    }

    public get surveyQuestion(): SurveyQuestion {
        return this._surveyQuestion;
    }

    public get surveyQuestionAnswers(): AnswerModel[] {
        return this._surveyQuestion.questionAnswers;
    }

    public set bottomRange(n: number) {
        this._range[0] = n;
    }

    public set topRange(n: number) {
        this._range[1] = n;
    }

    public set range(range: number[]) {
        this._range = range;
    }

    public get range(): number[] {
        let r = [];
        if (this._range.indexOf(null) > -1) {
            return null;
        }
        return this._range;
    }

    public defineRange(n: number, position: number) {
        let range = [null, null];
        range[position] = n;
        console.log('defined range', range);
    }

    public isTypeSurvey(): boolean {
        return this.filterInputType.dataType === DataTypeEnum.Survey;
    }

    public serializeSurveyValue(): string {
        let serialized = {},
            value: any;
        switch (this.surveyQuestion.answerType) {
            case QuestionAnswerType.YesNo:
                value = this.surveyQuestion.questionAnswers[0].isActive;
                break;
            case QuestionAnswerType.Stars5:
                value = this.range;
                break;
            case QuestionAnswerType.OneToTen:
                value = this.range;
                break;
            case QuestionAnswerType.OneOfOptions:
                value = this.surveyQuestionAnswers.filter(qa => qa.isActive).map(qa => qa.questionAnswerId);
                break;
            case QuestionAnswerType.MoreOfOptions:
                value = this.surveyQuestionAnswers.filter(qa => qa.isActive).map(qa => qa.questionAnswerId);
                break;
        }
        serialized[this.surveyQuestion.surveyQuestionId] = value;
        return JSON.stringify(serialized);
    }

    public get serverModel(): any {
        return {
            filterDialogFilterValueID: this.filterDialogFilterValueID,
            filterDialogItemID: this.filterDialogItemID,
            placeholderName: this.filterInputType.placeholderName,
            filterInputTypeID: this.filterInputType.filterInputTypeID,
            value: this.value,
            displayOrder: this.displayOrder
        };
    }
}
