import { ExportLevel }                  from '../shared/export-level.enum';
import { InterfaceModel, ExportContentSelector, PortalAgenda }               from '../interface/interface.model';
import { Observable } from 'rxjs';
// import { FileExportResult } from './file-export.model';

export class ExportRequestModel {

    public inProgress = false;

    public dialogTitle: string;

    public url: string;

    public level: ExportLevel = ExportLevel.All;

    public isSample = false;

    // If set to true, returns File directly
    public export = false;

    // If set to true, returns File directly
    public directDownload = false;

    public interfaceModel: InterfaceModel;

    public selectedExporFileID: number;

    public ExportSelector: ExportContentSelector;

    public agenda: PortalAgenda;

    public content = new ExportContentModel();

    public limit = 50000;

    public get supportUnlimitedDownload(): boolean {
        return this.agenda === PortalAgenda.Contact;
    };

    public exportCallback: (ExportRequestModel) => Observable<FileExportResult>;

    // public interfaces: Array<InterfaceFile> = [];

    public exportDownloadUrl: string;
}

export class ExportContentModel {
    uILanguageIsoCode: string;
    selector: ExportContentSelector;
    ids: number[];
    sinceDat: Date;
    tillDate: Date;

    // not necessary, redundant to selectedExporFileID
    // selectedFileFormat: InterfaceFile;

    // not necessary, server side variable
    // lastExportInfo: InterfaceLastExportInfo;

    /**
     * Dictionary<string, string> that contains implementation specific parameters
     */
    dialogData: any[];
}

export class FileExportResult {
    public errors: string[];
    public totalExported: number;
    public entityDataID: string;

    public isSample: boolean;
    public backgroudTask: boolean;

    public serviceUrl: string;
}
