import { Component,
         Input,
         EventEmitter,
         OnInit }                   from '@angular/core';
import { TableColumn }              from './table-column';
import { SortableFilter }           from './sortable-filter';
import { CommonService }            from '../shared/common.service';
import { HttpClient }               from '@angular/common/http';
import { Globals }                  from './globals';
import { from }                     from 'rxjs';
import { map }                      from 'rxjs/operators';
import { TranslationService }       from '../shared/translation.service';
import { ActivatedRoute }           from '@angular/router';
import { BaseComponent }            from './base.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'hc-universal-list',
  // tslint:disable:max-line-length
  template: `
  <div class="fixed-panel disable-scroll">
    <hc-toolbar [filter]="filter" [performSearch]="performSearch">
        <div class="hc-toolbar-menu">
            <button *ngIf="!detailDisabled && menuEnabled" mat-button id="action-button" [matMenuTriggerFor]="menu">
                <mat-icon>view_headline</mat-icon> {{ 'admin.web.action' | trans }}
            </button>
            <mat-menu #menu="matMenu" yPosition="below" [overlapTrigger]="false">
                <button mat-menu-item (click)="addNew()"><mat-icon>add</mat-icon> {{ 'admin.web.new' | trans }}</button>
            </mat-menu>
        </div>
    </hc-toolbar>

    <hc-grid-template #universalList [columns]="cols" [filter]="filter" [mapping]="mapping" [page]="page" [pageSize]="pageSize"
        [filterChanged]="performSearch" [selectedUpdated]="itemUpdated" [dataCallback]="getDataCallback" [dataPageCallback]="getDataPageCallback"
        [pagingDisabled]="pagingDisabled" [searchCallback]="boundSearchCallback" (editItem)="editItem($event)" flex>
    </hc-grid-template>
</div>
  `
})
export class UniversalListComponent extends BaseComponent<any> implements OnInit {


    @Input() public cols: Array<TableColumn>;

    @Input() public serviceName: string = null;

    /**
     * Parent path prefix for detail navigation, e.g. /-parentPath-/detail/1. If not set, serviceName is used
     */
    @Input() public parentPath: string = null;

    @Input() public pagingDisabled = true;

    @Input() public detailDisabled = false;

    @Input() public menuEnabled = true;

    @Input() public detailQuery = false;

    @Input() public primaryKeyName: string = null;

    @Input() public captionName: string = null;

    @Input() public dialogFactory: Function;

    @Input() public filter = new SortableFilter('');

    @Input() public mapping: Function;

    service: CommonService;

    performSearch = new EventEmitter();

    itemUpdated = new EventEmitter();

    public getDataCallback: Function;

    public getDataPageCallback: Function;

    public boundSearchCallback: Function;

  constructor(private http: HttpClient,
        private globals: Globals,

        private route: ActivatedRoute,
        public dialog: MatDialog,
        public trans: TranslationService) {
      super(globals);
      this.getDataCallback = () => from([]);
      this.getDataPageCallback = () => from([]);
  }

  ngOnInit(): void {
        this.service = new CommonService(this.http, this.globals, this.serviceName);
        this.getDataCallback = this.getData.bind(this);
        this.getDataPageCallback = this.getData.bind(this);
    }

  private getData(keyvalue: SortableFilter) {
      if (this.mapping !== undefined) {
        return this.service.getList(keyvalue).pipe(map((response) => {
          return response.map(e => this.mapping(e));
        }));
      } else {
        return this.service.getList(keyvalue);
      }
  }

  /**
   * addNew
   */
  public addNew() {
    if (this.detailDisabled) { return; }

    let routePrefix = this.parentPath || this.getPrevPathRoute();
    this.globals.navigateTo(routePrefix + '/new', { locKey: 'admin.web.new', relativeToParent: true });
  }

  public editItem(model) {
    if (this.detailDisabled) { return; }
    let detailModel: any = Object.assign({}, model); // use clone for the edit to prevent refresh of the grid on the background
    let caption = detailModel[this.primaryKeyName];
    if (this.captionName) {
        caption = detailModel[this.captionName];
    }
    let routePrefix = this.parentPath || this.getPrevPathRoute();
    this.globals.navigateTo(routePrefix + '/detail/' + detailModel[this.primaryKeyName], { locKey: 'admin.web.detail', relativeToParent: true, detailName: caption, canSave: true });
  }

  private getPrevPathRoute(): string {
    return this.route.routeConfig.path.substring(0, this.route.routeConfig.path.lastIndexOf('/'));
  }

  public search() {
    this.performSearch.emit(this.filter);
  }
}
