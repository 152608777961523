export class SavedReport {
    savedReportID: number;
    reportID: number;
    userName: string;
    name: string;
    content: string;

    created: Date | string;
    updateTime: Date | string;
}
