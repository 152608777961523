import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[hcAgGridResize]'
})
export class AGGridResizeDirective {
    private gridApi;
    private element: ElementRef;
    private renderer: Renderer2;

    constructor(el: ElementRef, renderer: Renderer2) {
        this.element = el;
        this.renderer = renderer;
    }

    @HostListener('show', ['$event'])
    @HostListener('window:resize')
    onResize() {
        if (!this.gridApi) {
            return;
        }

        let el = this.element;
        let ren = this.renderer;

        setTimeout(() => {
            if (!!this.gridApi) {
                let a = el;
                let r = ren;
                // this.gridApi.sizeColumnsToFit();
                // this.gridApi.setGridAutoHeight(document.body.offsetHeight - el.nativeElement.parentElement.getBoundingClientRect().bottom);
            }
            // if (this.gridApi.gridPanel.getWidthForSizeColsToFit()) {
            // }
        });
    }

    @HostListener('gridReady', ['$event'])
    onGridReady(params) {
        this.gridApi = params.api;
        let el = this.element;
        let ren = this.renderer;

        // this.gridApi.setGridAutoHeight(document.body.offsetHeight - el.nativeElement.parentElement.getBoundingClientRect().top);
        // this.gridApi.setGridAutoHeight(document.body.offsetHeight - el.nativeElement.parentElement.getBoundingClientRect().bottom);

        // this.gridApi.setGridAutoHeight(300);

        // if (params.api.gridPanel.getWidthForSizeColsToFit()) {
            params.api.sizeColumnsToFit();
        // }
    }
}
