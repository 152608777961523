import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslationService } from '../../../shared/translation.service';
import { SortableFilter, SortDirection } from '../../../common/sortable-filter';
import { ProductSetService } from '../../../shared/product-set.service';
import { ProductSetType } from '../product-set-type.enum';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'hc-create-product-set-dialog',
    templateUrl: './create-product-set.dialog.component.html',
    styleUrls: []
})
export class CreateProductSetDialogComponent implements OnInit {

    public nameFormControl = new FormControl('', [Validators.required]);
    public maxMembers = 50000;
    public productIDs: any[];
    public inProgress = false;
    public isError = false;
    public productSets = [];
    public productSetSelectControl = new FormControl(this.productSets[0], [Validators.required]);

    constructor(
        private dialogRef: MatDialogRef<CreateProductSetDialogComponent>,
        private trans: TranslationService,
        private service: ProductSetService,
        private snackbar: MatSnackBar,
    ) {
        this.productSets =  [
            { description: this.trans.instant('admin.web.create-new-product-set'), value: -2 }
        ];
    }

    ngOnInit() {
        this.inProgress = true;
        let filter = new SortableFilter('Name', SortDirection.asc);
        filter.pageSize = 1000;
        this.service.select(filter).subscribe(res => {
            res.items.forEach(productSet => {
                this.productSets.push({
                    description: productSet.type === ProductSetType.System ? this.trans.instant(productSet.name) : productSet.name,
                    value: productSet.productSetId
                });
            });
            this.inProgress = false;
        });
    }

    private confirm() {
        this.inProgress = true;
        if (this.isNameInputVisible()) {
            this.create();
        } else {
           this.addToExisting();
        }
    }

    private create() {
        this.service.create(this.nameFormControl.value, this.productIDs)
        .subscribe(res => {
                // ProductSet created
                this.inProgress = false;
                this.isError = false;
                this.snackbar.open(this.trans.instant('admin.web.list-created'), '', {duration: 2000});
            },
            err => {
                this.inProgress = false;
                this.isError = true;
                this.snackbar.open(this.trans.instant('admin.web.list-create-error'), '', {duration: 2000});
            },
        () => {
            this.dialogRef.close();
        });
    }

    private addToExisting() {
        this.service.addToWhiteList(this.productSetSelectControl.value.value, this.productIDs)
            .subscribe(res => {
                // Club created
                this.inProgress = false;
                this.isError = false;
                this.snackbar.open(this.trans.instant('admin.web.saved'), '', {duration: 2000});
            },
            err => {
                this.inProgress = false;
                this.isError = true;
                this.snackbar.open(this.trans.instant('admin.web.error-saving'), '', {duration: 2000});
            },
        () => {
            this.dialogRef.close();
        });
    }

    private close() {
        this.dialogRef.close();
    }

    private isValid(countError?: boolean): boolean {
        if (countError) {
            return this.productIDs.length <= this.maxMembers;
        }
        if (!this.isNameInputVisible()) {
            return this.productIDs.length <= this.maxMembers;
        }
        return this.productIDs.length <= this.maxMembers && !this.nameFormControl.hasError('required');
    }

    private isNameInputVisible(): boolean {
        return this.productSetSelectControl.value === this.productSets[0];
    }
}
