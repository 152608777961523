import { Injectable }           from '../../../node_modules/@angular/core';
import { IBaseService }         from './service.interface';
import { DocumentModel }        from '../common/document.model';
import { CommonService }        from './common.service';
import { Globals }              from '../common/globals';
import { ISortableFilter }      from '../common/sortable-filter';
import { Observable }           from '../../../node_modules/rxjs';
import { HttpClient } from '@angular/common/http';
import { AttachmentModel } from '../common/user.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class UserDocumentService {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals, snackbar: MatSnackBar) {
        this._commonService = new CommonService(http, globals, 'user/document');
    }

    public upsertItem(item: AttachmentModel): Observable<any> {
        return this._commonService.post('/' + item.userId.toString(), item);
    }

    public deleteItem(userId: number, attachmentId: number ): Observable<any> {
        return this._commonService.delete('/' + userId.toString() + '/' + attachmentId.toString());
    }
}
