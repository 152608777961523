import { ReportDetailType } from '../model/report-detail-type.enum';
import { UserAttributeType } from '../../user.model';

export class DetailRoute {
    url: string;
    idCol: string;
}

export class DetailRouting {
    public static getDetailRoute( detailType: ReportDetailType ): DetailRoute {
        let url = '';
        let idCol = '';

        switch (detailType) {
            case ReportDetailType.media:
                idCol = 'MediaID';
                url = '/communication/media/detail/{id}';
                break;
            case ReportDetailType.product:
                idCol = 'ProductID';
                url = '/product/detail/{id}';
                break;
            case ReportDetailType.report:
                idCol = 'ReportID';
                url = '/setting/report-setting/detail/{id}';
                break;
            case ReportDetailType.transaction:
                idCol = 'HeaderID';
                url = '/order/detail/{id}';
                break;
            case ReportDetailType.user:
                idCol = 'UserID';
                url = '/user/detail/{id}';
                break;
            case ReportDetailType.voucher:
                idCol = 'VoucherID';
                url = '/voucher/detail/{id}';
                break;
            case ReportDetailType.club:
                idCol = 'ClubID';
                url = '/user/club/{id}';
                break;
            case ReportDetailType.surveyQuestion:
                idCol = 'SurveyQuestionID';
                url = '/survey/questions/detail/{id}';
                break;
            case ReportDetailType.survey:
                idCol = 'SurveyID';
                url = '/survey/surveys/detail/{id}';
                break;
            case ReportDetailType.productSet:
                idCol = 'ProductSetID';
                url = '/product/product-set/{id}';
                break;
            case ReportDetailType.categoryDetail:
                idCol = 'CategoryID';
                url = '/product/category/detail/{id}';
                break;
            case ReportDetailType.categoryTreeDetail:
                idCol = 'CategoryID';
                url = '/product/category/list/{id}';
                break;

            case ReportDetailType.country:
                idCol = 'isoCode2';
                url = '/setting-core/country/detail/{id}';
                break;
            case ReportDetailType.currency:
                idCol = 'isoCode';
                url = '/setting-core/currency/detail/{id}';
                break;
            case ReportDetailType.language:
                idCol = 'languageISOCode';
                url = '/setting-core/language/detail/{id}';
                break;
            case ReportDetailType.measurementUnit:
                idCol = 'MeasurementUnitID';
                url = '/setting-core/measurement-unit/detail/{id}';
                break;
            case ReportDetailType.vat:
                idCol = 'VATID';
                url = '/setting-core/vat/detail/{id}';
                break;

            case ReportDetailType.aggregator:
                idCol = 'AggregatorId';
                url = '/setting/aggregators/detail/{id}';
                break;

            case ReportDetailType.codeList:
                idCol = 'CodeListId';
                url = '/setting/codelist/detail/{id}';
                break;

            case ReportDetailType.productAttributeType:
                idCol = 'ProductAttributeTypeId';
                url = '/setting/products-attributes/detail/{id}';
                break;

            case ReportDetailType.userAttribyteType:
                idCol = 'UserAttributeTypeId';
                url = '/setting/user-attributes/detail/{id}';
                break;

            case ReportDetailType.company:
                idCol = 'CompanyId';
                url = '/setting/company/detail/{id}';
                break;

            case ReportDetailType.walletTemplate:
                idCol = 'WalletTemplateID';
                url = '/setting/wallet-template/detail/{id}';
                break;

            case ReportDetailType.shippingType:
                idCol = 'ShippingID';
                url = '/setting/shipping/detail/{id}';
                break;

            case ReportDetailType.paymentType:
                idCol = 'PaymentID';
                url = '/setting/payment/detail/{id}';
                break;

            case ReportDetailType.paymentProvider:
                idCol = 'PaymentProviderID';
                url = '/setting/payment-provider/detail/{id}';
                break;

            case ReportDetailType.setting:
                idCol = 'SettingKey';
                url = '/setting/keyvalue/detail/{id}';
                break;

            case ReportDetailType.componentConfiguration:
                idCol = 'ConfigurationID';
                url = '/setting/configuration/detail/{id}';
                break;

            case ReportDetailType.skin:
                idCol = 'SkinID';
                url = '/setting/skin/detail/{id}';
                break;

            case ReportDetailType.variant:
                idCol = 'ComponentVariantID';
                url = '/setting/variant/detail/{id}';
                break;

            case ReportDetailType.widgetConfiguration:
                idCol = 'ConfigurationID';
                url = '/setting/widget/detail/{id}';
                break;

            case ReportDetailType.filter:
                idCol = 'FilterID';
                url = '/setting/filter/detail/{id}';
                break;

            case ReportDetailType.slotPlan:
                idCol = 'SlotPlanId';
                url = '/planner/slot/detail/{id}';
                break;

            case ReportDetailType.eventSource:
                idCol = 'EventSourceId';
                url = '/planner/detail/{id}';
                break;

            case ReportDetailType.location:
                idCol = 'LocationId';
                url = 'setting/location/detail/{id}';
                break;
            case ReportDetailType.portalUserDetail:
                idCol = 'userId';
                url = 'setting/portal-user/detail/{id}';
                break;
            case ReportDetailType.discountTypeDetail:
                idCol = 'ProductDiscountTypeID';
                url = 'product/discount-type/detail/{id}';
                break;
            case ReportDetailType.discountAssignmentDetail:
                idCol = 'ProductDiscountID';
                url = 'product/discount-assignment/detail/{id}';
                break;
            case ReportDetailType.segmentDetail:
                idCol = 'SegmentID';
                url = 'statistics/segments/detail/{id}';
                break;
            case ReportDetailType.backgroundImageDetail:
                idCol = 'BackgroundID';
                url = 'product/background-image/detail/{id}';
                break;
            case ReportDetailType.pbiTemplate:
                idCol = 'ReportTemplateID';
                url = 'statistics/pbi/pbi-templates/detail/{id}';
                break;
            case ReportDetailType.meaning:
                idCol = 'MeaningID';
                url = 'tasker/meaning/detail/{id}';
                break;
            case ReportDetailType.input:
                idCol = 'InputID';
                url = 'tasker/input/detail/{id}';
                break;
            case ReportDetailType.activity:
                idCol = 'ActivityID';
                url = 'tasker/activity/detail/{id}';
                break;
            case ReportDetailType.operation:
                idCol = 'OperationID';
                url = 'tasker/operation/detail/{id}';
                break;
            case ReportDetailType.task:
                idCol = 'TaskID';
                url = 'tasker/task/detail/{id}';
                break;
            case ReportDetailType.promotion:
                idCol = 'PromotionID';
                url = '/setting/promotion/detail/{id}';
                break;
            case ReportDetailType.htmlView:
                idCol = 'HtmlViewID';
                url = '/communication/html-views/detail/{id}';
                break;
            case ReportDetailType.categoryAttributeType:
                idCol = 'CategoryAttributeTypeID';
                url = '/setting/category-attributes/detail/{id}';
                break;
            case ReportDetailType.shippingOptionsProvider:
                idCol = 'ShippingOptionsProviderID';
                url = '/setting/shipping-options/detail/{id}';
                break;
            case ReportDetailType.shippingPriceProvider:
                idCol = 'ShippingPriceProviderID';
                url = '/setting/shipping-price/detail/{id}';
                break;
            case ReportDetailType.voucherTemplates:
                idCol = 'VoucherTemplateID';
                url = '/communication/voucher-template-detail/{id}';
                break;
        }
        return <DetailRoute>{ url: url, idCol: idCol};
    }
}
