import { CommonService }            from './common.service';
import { Globals }                  from '../common/globals';
import { CodeList }                 from '../common/code-list.model';
import { IBaseService }             from './service.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

@Injectable()
export class CodeListService implements IBaseService<any> {

    private _commonService: CommonService;

    constructor(
        private http: HttpClient,
        private globals: Globals,
        snackbar: MatSnackBar) {
        this._commonService = new CommonService(http, globals, 'codelist');
    }

    public deleteItem(id: string): Observable<any> {
        throw new Error('Method not implemented.');
    }

    public updateItem(id: string, item: any): Observable<any> {
        throw new Error('Method not implemented.');
    }

    public insertItem(item: any): Observable<any> {
        throw new Error('Method not implemented.');
    }

    public getDetail(id: string): Observable<any> {
        return this._commonService.get('detail/' + id);
    }

    public list(codeListIDs: number[]): Observable<CodeList[]> {
        return this._commonService.post('list', codeListIDs);
    }

    public listAll(): Observable<CodeList[]> {
        return this._commonService.get('');
    }
}
