import { Injectable }               from '@angular/core';
import { ISortableFilter,
         SortDirection }            from '../common/sortable-filter';
import { Globals }                  from '../common/globals';
import { HttpClient } from '@angular/common/http';
import { Observable }               from 'rxjs';
import { CommonService }            from './common.service';
import { GalleryImageModel }        from '../communication/gallery/gallery-image.model';
import { KeyValuePair }             from './keyValuePair.model';

@Injectable()
export class GalleryService {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'gallery');
    }

    public getItems(filter: FileRequest): Observable<any[]> {
        return this._commonService.post('listGallery', filter);
    }

    public getDetail(id: String): Observable<any> {
        return this._commonService.get('detail/' + id);
    }

    public getMetadata(id: String): Observable<GalleryImageModel> {
        return this._commonService.get('metadata/' + id);
    }

    public insertItem(item: any): Observable<any> {
        return this._commonService.insertItem(item);
    }

    public updateItem(id: string, item: any): Observable<any> {
       return this._commonService.updateItem(id, item);
    }

    public deleteItem(id: string): Observable<any> {
        return this._commonService.deleteItem(id);
    }

    public uploadFile(files: File[], transformProperties): Observable<any> {
        let keyValuePairs = [];
        if (transformProperties) {
            keyValuePairs = Object.keys(transformProperties).map(i => <KeyValuePair>{
                key: i,
                value: transformProperties[i]
            });
        }
        return this._commonService.uploadFile('uploadfile', files, keyValuePairs);
    }
}

export class FileRequest implements ISortableFilter {
    parent: string;
    sortColumn: string;
    sortDirection: SortDirection;
    page: number;
    pageSize: number;
    noCache: number;
    searchString: string;
}
