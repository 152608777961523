export enum DataTypeEnum {
    NotSet = 0,
    Boolean = 1,
    Number = 2,
    Text = 3,
    DateTime = 4,
    Image = 5,
    Money = 6,
    IntegerNumber = 7,
    ShortDate = 8,
    CodeList = 9,
    HTML = 10,
    Location = 11,
    Categories = 12,
    Media = 13,
    Survey = 14,
    FixedEnum = 15,
    DateRange = 16,
    NumberRange = 17,
    LocalizedTexts = 18,
    Dimensions = 19,
    ArrayOfStrings = 20,
    ArrayOfNumbers = 22,
    ArrayOfDates = 23,
    MulticurrencyPrice = 24,
    LocalizationKey = 25,
    StringDictionary = 26,
    NutsRegion = 27,
    Link  = 28,
    Receipt = 29,
    TenantHash = 30,
    VoucherTemplate = 31,
    ProductFilterAttributes = 32,
    JsonObject = 33,
    ProductNumber = 34,
    MultiLang = 35
}
