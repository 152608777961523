import { PlaceholderModel } from './media.model';
import { range } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';

 const key = {
   code: 123,
   char: '{'
 };

// create enumeration object for repeated class names
 const CLASSES = {
    marker: 'placeholder-input__marker',
    visible: 'placeholder-input__marker--visible'
  };

   const createMarker = (content, modifier, identifier) => {
    // create a marker for the input
    const marker = document.createElement('div');
    marker.classList.add(CLASSES.marker, `${CLASSES.marker}--${modifier}`);
    marker.setAttribute('data-identifier', identifier);
    marker.textContent = content;
    return marker;
  };

  /**
   * returns x, y coordinates for absolute positioning of a span within a given text input
   * at a given selection point
   * @param {object} input - the input element to obtain coordinates for
   * @param {number} selectionPoint - the selection point for the input
   */
  export const getCursorXY = (input, selectionPoint) => {
    // const {
    //   offsetLeft: inputX,
    //   offsetTop: inputY,
    // } = input;

    let inputX = input.getBoundingClientRect().x;
    let inputY = input.getBoundingClientRect().y;

    // console.log('inputX: ' + inputX);
    // console.log('inputY: ' + inputY);

    // create a dummy element that will be a clone of our input
    const div = document.createElement('div');
    // get the computed style of the input and clone it onto the dummy element
    const copyStyle = getComputedStyle(input) as any;
    for (const prop of copyStyle) {
      div.style[prop] = copyStyle[prop];
    }
    // we need a character that will replace whitespace when filling our dummy element if it's a single line <input/>
    const swap = '.';

    const isDiv = input.tagName === 'DIV';
    let inputValue = '';

    if (isDiv) {
      inputValue = input.innerHTML;
    } else {
      inputValue = input.tagName === 'INPUT' ? input.value.replace(/ /g, swap) : input.value;
    }

    // set the div content to that of the textarea up until selection
    const textContent = inputValue.substr(0, selectionPoint);
    // set the text content of the dummy element div
    div.textContent = textContent;
    if (input.tagName === 'TEXTAREA') { div.style.height = 'auto'; }
    // if a single line input then the div needs to be single line and not break out like a text area
    if (input.tagName === 'INPUT') { div.style.width = 'auto'; }
    // create a marker element to obtain caret position
    const span = document.createElement('span');
    // give the span the textContent of remaining content so that the recreated dummy element is as close as possible
    span.textContent = inputValue.substr(selectionPoint) || '.';
    // append the span marker to the div
    div.appendChild(span);
    // append the dummy element to the body
    document.body.appendChild(div);
    // get the marker position, this is the caret position top and left relative to the input
    const { offsetLeft: spanX, offsetTop: spanY } = span;
    // lastly, remove that dummy element
    // NOTE:: can comment this out for debugging purposes if you want to see where that span is rendered
    document.body.removeChild(div);
    // return an object with the x and y of the caret. account for input positioning so that you don't need to wrap the input


    if (isDiv) {
      let pos = getSelectionCoords(window);
      return pos;
      // return {
      //   x: inputX + spanX,
      //   y: inputY + spanY,
      // };
    }

    return {
      x: inputX + spanX,
      y: inputY + spanY,
    };
  };

  /**
   * shows a position marker that highlights where the caret is
   * @param {object} e - the input or click event that has been fired
   */
  const showPositionMarker = e => {
    // grab the input element
    const { currentTarget: input } = e;
    // create a function that will handle clicking off of the input and hide the marker
    const processClick = evt => {
      if (e !== evt && evt.target !== e.target) {
        toggleMarker();
      }
    };
    // create a function that will toggle the showing of the marker
    const toggleMarker = () => {
      input.__IS_SHOWING_MARKER = !input.__IS_SHOWING_MARKER;

      if (input.__IS_SHOWING_MARKER && !input.__MARKER) {
        // assign a created marker to input
        input.__MARKER = createMarker('Here I am! 😜', 'position', 'not-set');
        // append it to the body
        document.body.appendChild(input.__MARKER);
        document.addEventListener('click', processClick);
      } else {
        document.body.removeChild(input.__MARKER);
        document.removeEventListener('click', processClick);
        input.__MARKER = null;
      }
    };
    // if the marker isn't showing, show it
    if (!input.__IS_SHOWING_MARKER) { toggleMarker(); }
    // if the marker is showing, update its position
    if (input.__IS_SHOWING_MARKER) {
      // grab the properties from the input that we are interested in
      const {
        offsetLeft,
        offsetTop,
        offsetHeight,
        offsetWidth,
        scrollLeft,
        scrollTop,
        selectionEnd,
      } = input;
      // get style property values that we are interested in
      const { lineHeight, paddingRight } = getComputedStyle(input);
      // get the caret X and Y from our helper function
      const { x, y } = getCursorXY(input, selectionEnd);
      // set the marker positioning
      // for the left positioning we ensure that the maximum left position is the width of the input minus the right padding using Math.min
      // we also account for current scroll position of the input
      const newLeft = Math.min(
        x - scrollLeft,
        (offsetLeft + offsetWidth) - parseInt(paddingRight, 10)
      );
      // for the top positioning we ensure that the maximum top position is the height of the input minus line height
      // we also account for current scroll position of the input
      // const newTop = Math.min(
      //   y - scrollTop,
      //   (offsetTop + offsetHeight) - parseInt(lineHeight, 10)
      // );
      const newTop = y - scrollTop;
      input.__MARKER.setAttribute('style', `left: ${newLeft}px; top: ${newTop}px`);
    }
  };

  /**
   * shows a position marker for where a user has selected input content
   * @param {object} e - mouseup event for text selection
   */
  const getSelectionArea = e => {
    // grab the input element
    const { currentTarget: input } = e;
    // grab the properties of the input we are interested in
    const {
      offsetLeft,
      offsetWidth,
      scrollLeft,
      scrollTop,
      selectionStart,
      selectionEnd,
    } = input;
    // grab styling properties we are interested in
    const { paddingRight } = getComputedStyle(input);
    // create a function that will handle clicking off of the input and hide the marker
    const processClick = evt => {
      if (e !== evt && evt.target !== e.target) {
        toggleMarker();
      }
    };
    // create a function that will toggle the showing of the marker
    const toggleMarker = () => {
      input.__IS_SHOWING_MARKER = !input.__IS_SHOWING_MARKER;

      if (input.__IS_SHOWING_MARKER && !input.__MARKER) {
        // assign a created marker to input
        input.__MARKER = createMarker('Here\'s your selection! 🎉', 'selection', 'not-set');
        // append it to the body
        document.body.appendChild(input.__MARKER);
        document.addEventListener('click', processClick);
      } else {
        document.body.removeChild(input.__MARKER);
        document.removeEventListener('click', processClick);
        input.__MARKER = null;
      }
    };
    // if selectionStart === selectionEnd then there is no actual selection, hide the marker and return
    if (selectionStart === selectionEnd) {
      if (input.__IS_SHOWING_MARKER) { toggleMarker(); }
      return;
    }
    // we need to get the start and end positions so we can work out a midpoint to show our marker
    // first, get the starting top and left using selectionStart
    const { y: startTop, x: startLeft } = getCursorXY(input, selectionStart);
    // then get the ending top and left using selectionEnd
    const { y: endTop, x: endLeft } = getCursorXY(input, selectionEnd);
    // if the marker isn't showing and there's a selection, show the marker
    if (!input.__IS_SHOWING_MARKER && selectionStart !== selectionEnd) {
      toggleMarker();
    }
    // if the marker is showing then update its position
    if (input.__IS_SHOWING_MARKER) {
      // we don't care about the value of endTop as our marker will always show at the top point and this will always be startTop
      // account for scroll position by negating scrollTop
      // as for left positioning, we need to first work out if the end point is on the same line or we have multiline selection
      // in the latter case, the endpoint will be the furthest possible right selection point
      const endPoint =
        startTop !== endTop ? offsetLeft + (offsetWidth - parseInt(paddingRight, 10)) : endLeft;
      // we want the marker to show above the selection and in the middle of the selection
      // so start point plus halve the endpoint minus the start point
      const newLeft = startLeft + ((endPoint - startLeft) / 2);
      // set the marker positioning
      input.__MARKER.setAttribute('style', `left: ${newLeft - scrollLeft}px; top: ${startTop - scrollTop}px`);
    }
  };


  /**
   * shows a custom UI based on whether a user has typed a certain character, in this case
   * for this demo, just allow user to select from a predetermined list of animals
   * @param {object} e - event fired for keypress, keydown or keyup
   */
  export const showCustomUI = (e, placeholders: Array<PlaceholderModel>, identifier: string, afterUpdateCallback: () => void) => {
    // grab properties of event we are interested in
    const {
      which,
      type,
    } = e;

    let input = e.input || e.currentTarget;

    const isDiv = input.tagName === 'DIV';

    let value = isDiv ? input.innerHTML : input.value;

    let origRange = window.getSelection().getRangeAt(0);
    let preCaretRange = origRange.cloneRange();

    const selectionStart = isDiv ? getCaretPosition(input) : input.selectionStart; // getHTMLCaretPosition(input);
    // const selectionStart = e.input ? input.selectionStart : getCaretPosition(input)[0];
    const selectionEnd = isDiv ? getCaretPosition(input)[1] : input.selectionEnd;

    // grab properties of input that we are interested in
    const {
      offsetHeight,
      offsetLeft,
      offsetTop,
      offsetWidth,
      scrollLeft,
      scrollTop
    } = input;
    const {
      paddingRight,
      lineHeight,
    } = getComputedStyle(input);
    // create a function that will handle clicking off of the input and hide the marker
    const processClick = evt => {
      if (e !== evt && evt.target !== e.target) {
        toggleCustomUI();
      }
    };
    /**
     * toggles selected item in list via arrow keys
     * create a new selected item if one doesn't exist
     * else update the selected item based on the given selection direction
     * @param {string} dir - defines which element sibling to select next
     */
    const toggleItem = (dir = 'next') => {
      const list = input.__CUSTOM_UI.querySelector('ul');
      if (!input.__SELECTED_ITEM) {
        input.__SELECTED_ITEM = input.__CUSTOM_UI.querySelector('li');
        input.__SELECTED_ITEM.classList.add('custom-suggestions--active');
      } else {
        input.__SELECTED_ITEM.classList.remove('custom-suggestions--active');
        let nextActive = input.__SELECTED_ITEM[`${dir}ElementSibling`];
        if (!nextActive && dir === 'next') { nextActive = list.firstChild; } else if (!nextActive) { nextActive = list.lastChild; }
        input.__SELECTED_ITEM = nextActive;
        nextActive.classList.add('custom-suggestions--active');
      }
    };
    /**
     * filter a dummy list of data and append a <ul> to the marker element to show to the end user
     */
    const filterList = () => {
      const filter = value.slice(input.__EDIT_START + 1, selectionStart).toLowerCase();
      // const suggestions = ['Cat 😺', 'Dog 🐶', 'Rabbit 🐰'];
      const filteredSuggestions = placeholders.filter((entry) => entry.placeholderTranslation.toLowerCase().includes(filter));
      if (!filteredSuggestions.length) { filteredSuggestions.push(new PlaceholderModel( 0, '', '...' )); }
      const suggestedList = document.createElement('ul');
      suggestedList.classList.add('custom-suggestions');
      filteredSuggestions.forEach((entry) => {
        const entryItem = document.createElement('li');
        entryItem.textContent = entry.placeholderTranslation;
        entryItem.setAttribute('data-index', entry.placehoderIndex + '');
        entryItem.setAttribute('data-placeholder', entry.placeholder);
        suggestedList.appendChild(entryItem);
      });
      if (input.__CUSTOM_UI.firstChild) {
        input.__CUSTOM_UI.replaceChild(suggestedList, input.__CUSTOM_UI.firstChild);
      } else {
        input.__CUSTOM_UI.appendChild(suggestedList);
      }
    };
    /**
     * given a selected value, replace the special character and insert selected value
     * @param {string} selected - the selected value to be inserted into inputs text content
     * @param {bool} click - defines whether the event was a click or not
     */
    const selectItem = (selected: HTMLElement, click = false) => {
      let placeholder = '{{' +  selected.getAttribute('data-placeholder') + '}}';

      if (isDiv) {
        input = input as HTMLDivElement;
        let stringToCursor = input.innerHTML.slice(0, selectionStart + 1);
        let placeholderStart = stringToCursor.lastIndexOf('{');
        let start = input.innerHTML.slice(0, placeholderStart);

        let end = input.innerHTML.slice(placeholderStart + 1, input.innerHTML.length);
        input.innerHTML = `${start}${placeholder}${end}`;
        input.focus();
        let sel = window.getSelection();
        sel.removeAllRanges();
        origRange.setStartAfter(origRange.startContainer);
        sel.addRange(origRange);
      } else {
        const start = input.value.slice(0, input.__EDIT_START);
        const end = input.value.slice( click ? selectionStart + 1 : selectionStart, input.value.length);
        input.value = `${start}${placeholder}${end}`;
      }
      if (afterUpdateCallback) {
        afterUpdateCallback();
      }
    };
    /**
     * handle when the suggestions list is clicked so that user can select from list
     * @param {event} e - click event on marker element
     */
    // tslint:disable-next-line:no-shadowed-variable
    const clickItem = (e) => {
      e.preventDefault();
      if (e.target.tagName === 'LI') {
        input.focus();
        toggleCustomUI();
        selectItem(e.target, true);
      }
    };
    // toggle custom UI on and off
    const toggleCustomUI = () => {
      input.__EDIT_START = selectionStart;
      input.__IS_SHOWING_CUSTOM_UI = !input.__IS_SHOWING_CUSTOM_UI;

      if (input.__IS_SHOWING_CUSTOM_UI && !input.__CUSTOM_UI) {
        // assign a created marker to input
        input.__CUSTOM_UI = createMarker(null, 'custom', identifier);
        // append it to the body
        document.body.appendChild(input.__CUSTOM_UI);
        input.__CUSTOM_UI.addEventListener('click', clickItem);
        document.addEventListener('click', processClick);
      } else {
        input.__CUSTOM_UI.removeEventListener('click', clickItem);
        document.body.removeChild(input.__CUSTOM_UI);
        document.removeEventListener('click', processClick);
        input.__CUSTOM_UI = null;
      }

      if (input.__IS_SHOWING_CUSTOM_UI) {
        // update list to show
        filterList();
        // update position
        const { x, y } = getCursorXY(input, selectionStart);
        const newLeft = Math.min(
          x - scrollLeft,
          (offsetLeft + offsetWidth) - parseInt(paddingRight, 10)
        );
        // const newTop = Math.min(
        //   y - scrollTop,
        //   (offsetTop + offsetHeight) - parseInt(lineHeight, 10)
        // );
        const newTop = y - scrollTop;
        input.__CUSTOM_UI.setAttribute('style', `left: ${newLeft}px; top: ${newTop}px`);
      }
    };

    const previousChar = value.charAt(selectionStart - 1).trim();

    let prevIsNbsp = value.length > 5 && value.substr(value.length - 6) === '&nbsp;';
    // console.log('before: ' +  value.substr(0, selectionStart));
    // console.log('after: ' +  value.substr(selectionStart));

    // // determine whether we can show custom UI, format must be special character preceded by a space
    // console.log('value:' + value);
    // console.log('which: ' + which + ',[' + e.char + '] , previousChar: "' + previousChar + '"');

    if (isDiv &&
        ((which === key.code || e.char === key.char) &&
        (previousChar === '' || previousChar === '>' || previousChar === ' ' || prevIsNbsp))) {
      toggleCustomUI();
    } else if (which === key.code && previousChar === '') {
      toggleCustomUI();
    } else if (input.__IS_SHOWING_CUSTOM_UI) {
      switch (which) {
        case key.code:
        case 32:
          toggleCustomUI();
          break;
        case 8:
          if (selectionStart === input.__EDIT_START) {
            toggleCustomUI();
          } else {
            filterList();
          }
          break;
        case 13:
          if (input.__SELECTED_ITEM) {
            e.preventDefault();
            selectItem(input.__CUSTOM_UI.querySelector('.custom-suggestions--active'));
            toggleCustomUI();
          } else {
            toggleCustomUI();
          }
          break;
        case 38:
        case 40:
          if (type === 'keydown') {
            e.preventDefault();
            // up is 38
            // TCh - disabled, causes troubles with multiple pickers on single page
            // toggleItem(which === 38 ? 'previous' : 'next');
            // down is 40
          }
          break;
        case 37:
        case 39:
          if (selectionStart < input.__EDIT_START + 1) {
            toggleCustomUI();
          }
          break;
        default:
          filterList();
          break;
      }
    }
  };

  // node_walk: walk the element tree, stop when func(node) returns false
function node_walk(node, func) {
  let result = func(node);
  for (node = node.firstChild; result !== false && node; node = node.nextSibling) {
    result = node_walk(node, func);
  }
  return result;
}

// getCaretPosition: return [start, end] as offsets to elem.textContent that
//   correspond to the selected portion of text
//   (if start == end, caret is at given position and no text is selected)
// function getCaretPosition(elem) {
//   let sel = window.getSelection();
//   let cum_length = [0, 0];

//   if (sel.anchorNode === elem) {
//     cum_length = [sel.anchorOffset, sel.extentOffset];
//   } else {
//     let nodes_to_find = [sel.anchorNode, sel.extentNode];
//     if (!elem.contains(sel.anchorNode) || !elem.contains(sel.extentNode)) {
//       return undefined;
//     } else {
//       let found = [0, 0];
//       let i;
//       node_walk(elem, function (node) {
//         for (i = 0; i < 2; i++) {
//           if (node === nodes_to_find[i]) {
//             found[i] = 1;
//             if (found[i === 0 ? 1 : 0]) {
//               return false;
//             } // all done
//           }
//         }

//         if (node.innerHTML && !node.firstChild) {
//           for (i = 0; i < 2; i++) {
//             if (!found[i]) {
//               cum_length[i] += node.textContent.length;
//             }
//           }
//         }
//       });
//       cum_length[0] += sel.anchorOffset;
//       cum_length[1] += sel.extentOffset;
//     }
//   }
//   if (cum_length[0] <= cum_length[1]) {
//     return cum_length;
//   }
//   return [cum_length[1], cum_length[0]];
// }

function getCaretCharacterOffsetWithin(element) {
  let caretOffset = 0;
  if (typeof window.getSelection !== 'undefined') {
      let carretRange = window.getSelection().getRangeAt(0);
      let preCaretRange = carretRange.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(carretRange.endContainer, carretRange.endOffset);
      caretOffset = preCaretRange.toString().length;
  } /* else if (typeof window.document.selection !== 'undefined' && document.selection.type !== 'Control') {
      let textRange = document.selection.createRange();
      let preCaretTextRange = document.body.createTextRange();
      preCaretTextRange.moveToElementText(element);
      preCaretTextRange.setEndPoint('EndToEnd', textRange);
      caretOffset = preCaretTextRange.text.length;
  }*/
  return caretOffset;
}

function getCaretPosition (node) {
  let cRange = window.getSelection().getRangeAt(0),
      preCaretRange = cRange.cloneRange(),
      caretPosition,
      tmp = document.createElement('div');

  preCaretRange.selectNodeContents(node);
  preCaretRange.setEnd(cRange.endContainer, cRange.endOffset);
  tmp.appendChild(preCaretRange.cloneContents());
  caretPosition = tmp.innerHTML.length;
  return caretPosition;
}

function getHTMLCaretPosition(element) {
  let textPosition = getCaretPosition(element),
      htmlContent = element.innerHTML,
      textIndex = 0,
      htmlIndex = 0,
      insideHtml = false,
      htmlBeginChars = ['&', '<'],
      htmlEndChars = [';', '>'];


  if (textPosition === 0) {
    return 0;
  }

  while (textIndex < textPosition) {

    htmlIndex++;

    // check if next character is html and if it is, iterate with htmlIndex to the next non-html character
    while (htmlBeginChars.indexOf(htmlContent.charAt(htmlIndex)) > -1) {
      // console.log('encountered HTML');
      // now iterate to the ending char
      insideHtml = true;

      while (insideHtml) {
        if (htmlEndChars.indexOf(htmlContent.charAt(htmlIndex)) > -1) {
          if (htmlContent.charAt(htmlIndex) === ';') {
            htmlIndex--; // entity is char itself
          }
          // console.log('encountered end of HTML');
          insideHtml = false;
        }
        htmlIndex++;
      }
    }
    textIndex++;
  }

  // console.log(htmlIndex);
  // console.log(textPosition);
  // in htmlIndex is caret position inside html
  return htmlIndex;
  }

  function getSelectionCoords(win) {
    win = win || window;
    let doc = win.document;
    let sel = doc.selection, selRange, rects, rect;
    let x = 0, y = 0;
    if (sel) {
        if (sel.type !== 'Control') {
          selRange = sel.createRange();
          selRange.collapse(true);
            x = selRange.boundingLeft;
            y = selRange.boundingTop;
        }
    } else if (win.getSelection) {
        sel = win.getSelection();
        if (sel.rangeCount) {
          selRange = sel.getRangeAt(0).cloneRange();
            if (selRange.getClientRects) {
              selRange.collapse(true);
                rect = selRange.getBoundingClientRect();
                // if (rects.length > 0) {
                //     rect = rects[0];
                // }

                // if (!rect) {
                //   // tslint:disable-next-line:no-debugger
                //   debugger;
                // }

                x = rect.left;
                y = rect.top;
            }
            // Fall back to inserting a temporary element
            if (x === 0 && y === 0) {
                let span = doc.createElement('span');
                if (span.getClientRects) {
                    // Ensure span has dimensions and position by
                    // adding a zero-width space character
                    span.appendChild( doc.createTextNode('\u200b') );
                    selRange.insertNode(span);
                    rect = span.getClientRects()[0];
                    x = rect.left;
                    y = rect.top;
                    let spanParent = span.parentNode;
                    spanParent.removeChild(span);

                    // Glue any broken text nodes back together
                    spanParent.normalize();
                }
            }
        }
    }
    return { x: x, y: y };
}

  /*
  // grab instance of different inputs
  const getPositionInput = document.querySelector('.get-position-input');
  const getPositionTextArea = document.querySelector('.get-position-textarea');
  const getSelectionTextArea = document.querySelector('.get-selection-textarea');
  const getSelectionInput = document.querySelector('.get-selection-input');
  const showCustomUIInput = document.querySelector('.show-custom-ui-input');
  const showCustomUITextArea = document.querySelector('.show-custom-ui-textarea');
  // bind event listeners to the different text inputs
  getPositionInput.addEventListener('input', showPositionMarker);
  getPositionInput.addEventListener('click', showPositionMarker);
  getPositionTextArea.addEventListener('input', showPositionMarker);
  getPositionTextArea.addEventListener('click', showPositionMarker);
  getSelectionTextArea.addEventListener('mouseup', getSelectionArea);
  getSelectionInput.addEventListener('mouseup', getSelectionArea);
  getSelectionTextArea.addEventListener('input', getSelectionArea);
  getSelectionInput.addEventListener('input', getSelectionArea);
  showCustomUIInput.addEventListener('keypress', showCustomUI);
  showCustomUIInput.addEventListener('keydown', showCustomUI);
  showCustomUIInput.addEventListener('keyup', showCustomUI);
  showCustomUITextArea.addEventListener('keypress', showCustomUI);
  showCustomUITextArea.addEventListener('keydown', showCustomUI);
  showCustomUITextArea.addEventListener('keyup', showCustomUI);
  */
