import { Injectable }           from '@angular/core';
import { CommonService }        from './common.service';
import { HttpClient } from '@angular/common/http';
import { Globals }              from '../common/globals';
import { ISortableFilter }      from '../common/sortable-filter';
import { Observable }           from 'rxjs';
import { FilterDialog }         from '../common/filter-dialog/models/filter-dialog.model';
import { FilterInputType }      from '../common/filter-dialog/models/filter-input-type.model';
import { DataTypeEnum } from '../common/data-type.enum';
import { FilterPurpose } from '../common/filter-purpose.enum';

@Injectable()
export class FilterDialogService {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'filterDialog');
    }

    public getDetail(id: number): Observable<any> {
        return this._commonService.get(id.toString());
    }

    public getItems(filter: ISortableFilter): Observable<any> {
        return this._commonService.get('list', filter);
    }

    public getItemsForPurpose(filterPurpose: FilterPurpose): Observable<any> {
        return this._commonService.get('listForPurpose/' + filterPurpose);
    }

    public insertItem(filterDialog: any): Observable<FilterDialog> {
        return this._commonService.insertItem(filterDialog);
    }

    public updateItem(id: number, filterDialog: any): Observable<FilterDialog> {
        return this._commonService.updateItem(id.toString(), filterDialog);
    }

    public getFilterInputs(): Observable<FilterInputType[]> {
        return this._commonService.get('filterInputs');
    }

    public deleteItem(id: number): Observable<any> {
        return this._commonService.deleteItem(id.toString());
    }
}
