export enum ColumnFilterComparision {
    undefined,
    contains,
    notContains,
    equals,
    notEqual,
    startsWith,
    endsWith,
    lessThan,
    lessThanOrEquals,
    greaterThan,
    greaterThanOrEquals,
    inRange
}
