import { AuthService } from '../shared/auth.service';
import {
    Component,
    ChangeDetectorRef
} from '@angular/core';
import { Router } from '@angular/router';
import {
    FormControl,
    FormBuilder,
    FormGroup,
    Validators
} from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginModel } from './login.model';
import { Globals } from '../common/globals';
import { NavigationExtras } from '@angular/router';
import { isNullOrUndefined } from 'src/app/common/functions';
import { TranslationPipe } from '../shared/translation.pipe';
import { CompanyService } from '../shared/company.service';
import { HcLocalStorage } from '../common/local-storage';
import { AGGridLicenser } from '../common/report-grid/tools/ag-grid-license';
import { InterfaceService } from '../shared/interfaces.service';
import { TranslationService } from '../shared/translation.service';

const jwtHelper = new JwtHelperService();

@Component({
    selector: 'hc-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    public loginForm: FormGroup;
    public errorMessage: string = null;
    public inProgress = false;

    public multipleTenants = false;

    public selectedTenantID: number = null;

    private tenants = [];

    private jwtTokens: true | { id: any; name: any; jwt: string; }[];

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private companyService: CompanyService,
        public globals: Globals,
        private cd: ChangeDetectorRef,
        private hcLocalStorage: HcLocalStorage,
        private trans: TranslationPipe,
        private gridLic: AGGridLicenser,
        private interfaceService: InterfaceService,
        private translationService: TranslationService
    ) {
        if (this.loggedIn()) {
            this.router.navigate(['/']);
        }

        this.loginForm = this.formBuilder.group({
            Username: new FormControl('', Validators.required),
            Password: new FormControl('', Validators.required),
            RememberMe: new FormControl(),
            TenantId: new FormControl('1', Validators.required)
        });

        this.loginForm.controls['TenantId'].valueChanges.subscribe(v => {
            this.selectedTenantID = v;
        },
            (err) => console.error(err));
    }

    loggedIn(): boolean {
        let token = this.globals.getToken();
        if (!token) { return false; }
        return !jwtHelper.isTokenExpired(token);
    }

    private async loadPrerequities() {
        await this.interfaceService.load();
        this.gridLic.setAGGridLicense();
    }

    async onSubmit(event: Event) {
        event.preventDefault();

        this.inProgress = true;
        this.errorMessage = null;

        if (!this.loginForm.valid) {
            return;
        }

        let formModel = this.loginForm.value as LoginModel;

        // Already have JWT tokens, just need setup
        if (this.multipleTenants) {
            let jwtToken = (this.jwtTokens as Array<any>).filter(f => f.id === formModel.TenantId)[0].jwt;

            // TODO: Detect if token has user, admin or superadmin claim
            // let tokenDecoded = jwtHelper.decodeToken(jwtToken);

            await this.authService.setToken(jwtToken);
            sessionStorage.setItem('loggedUserName', formModel.Username);
            let storedBreadcrumb = sessionStorage.getItem('hc_route');
            localStorage['tenantId'] = formModel.TenantId;

            // Load language set for selected tenant
            this.translationService.get(this.globals.getLanguage());

            this.loadPrerequities();
            if (!!storedBreadcrumb) {
                this.router.navigate([this.globals.currentNavItem().path], <NavigationExtras>this.globals.currentNavItem().data);
            } else {
                this.globals.navigateClearTo('');
            }
            return;
        } else {
            this.globals.setMultiloginTenants([]);
        }

        let jwtTokens = await this.authService.multilogin(formModel.Username, formModel.Password);
        this.jwtTokens = jwtTokens;

        if (isNullOrUndefined(jwtTokens) || jwtTokens.valueOf() === false) {
            this.inProgress = false;
            this.errorMessage = this.trans.transform('admin.web.invalid-login');
            this.cd.detectChanges();
            return;
        }

        if (jwtTokens.valueOf() === true) {
            let storedBreadcrumb = sessionStorage.getItem('hc_route');
            localStorage['tenantId'] = formModel.TenantId;
            this.loadPrerequities();
            if (!!storedBreadcrumb) {
                this.router.navigate([this.globals.currentNavItem().path], <NavigationExtras>this.globals.currentNavItem().data);
            } else {
                this.globals.navigateClearTo('');
            }
        } else {

            this.tenants = (this.jwtTokens as Array<any>);

            // Filter eshop only accounts
            this.tenants = this.tenants.filter(t => {
                let decodedToken = jwtHelper.decodeToken(t.jwt);

                if (decodedToken.user_type === 'Contact') {
                    return false;
                }

                if (Array.isArray(decodedToken.role)) {
                    return decodedToken.role.indexOf('admin') > -1 ||
                           decodedToken.role.indexOf('sysadmin') > -1 ||
                           decodedToken.role.indexOf('user') > -1;
                } else {
                    return decodedToken.role === 'admin' ||
                           decodedToken.role === 'sysadmin' ||
                           decodedToken.role === 'user';
                }
            });

            if (this.tenants.length === 0) {
                this.inProgress = false;
                this.errorMessage = this.trans.transform('admin.web.invalid-login');
                this.cd.detectChanges();
                return;
            }

            this.multipleTenants = true;

            this.tenants = this.tenants.sort((a, b) => {
                if ( a.name < b.name ) { return -1; }
                if ( a.name > b.name ) { return 1; }
                return 0;
            });

            this.globals.setMultiloginTenants(this.tenants);

            let selectedTenant = this.tenants.find(t => t.id === localStorage['tenantId']);
            if (selectedTenant) {
                formModel.TenantId = selectedTenant.id;
                this.loginForm.controls['TenantId'].setValue(selectedTenant.id);
            } else {
                this.loginForm.controls['TenantId'].setValue(null);
                formModel.TenantId = null;
            }
            this.cd.detectChanges();
        }
    }
}

