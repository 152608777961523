

import { Component,
         OnInit,
         AfterViewInit,
         Output,
         EventEmitter,
         Input,
         ChangeDetectionStrategy,
         ChangeDetectorRef,
         OnDestroy,
         ElementRef,
         ViewChild,
         HostListener,
         AfterViewChecked,
         AfterContentChecked }      from '@angular/core';
import { DomSanitizer,
         SafeHtml }                 from '@angular/platform-browser';
import { SortDirection,
         ISortableFilter }          from './sortable-filter';
import { Observable,
         Subscription,
         BehaviorSubject }          from 'rxjs';
import { tap, map }                 from 'rxjs/operators';
import { TableColumn }              from './table-column';
import { createGuid,
         isNullOrWhitespace }       from './functions';
import { TableColumnSetting }       from './table-column-setting';
import { TranslationPipe }          from '../shared/translation.pipe';
import { format }                   from 'date-fns';
import { isNullOrUndefined }        from './functions';
import { Globals }                  from './globals';
import { TableColumnType }          from './table-column.type';
import { PaginationInstance }       from 'ngx-pagination';

enum SelectorPageState {
  NotSelected = 0,
  AllSelected = 1,
  Indetermined = 2
}

@Component({
  selector: 'hc-grid-template',
  templateUrl: './grid.template.html',
  styleUrls: ['./grid.template.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GridTemplateComponent<T> implements OnInit, AfterViewInit, AfterViewChecked, AfterContentChecked, OnDestroy {

  private isResizing = false;

  ngAfterContentChecked(): void {
    // this.onResizeWrapper();
  }
  @ViewChild('scrollableBody', {static: true}) scrollableBody: ElementRef;

  @HostListener('window:resize', ['$event'])

  onResizeWrapper() {
    console.log('resize wrapper');
    let resFn = this.onResize;
    setTimeout(() => this.onResize(null), 100);
  }

  onResize(event) {
    if (this.isResizing) { return; }
    this.isResizing = true;
    this.scrollbarVisible =
      !document.querySelector('table.scroll tbody') || ( // It's safer to make it vissible
      document.querySelector('table.scroll tbody').scrollHeight >
      document.querySelector('table.scroll tbody').clientHeight);

      let cols = document.querySelectorAll('.column-head > tr > th > div');
      this.calculatedOffsetWidths = [];
      if (!!cols) {
        for (let index = 0; index < cols.length; index++) {
          this.calculatedOffsetWidths.push((<any>cols[index]).offsetWidth + 'px');
        }
      }
      // console.log('resized');
      if (!this.changeRef['destroyed']) { this.changeRef.detectChanges(); }
      this.isResizing = false;
  }

  @Output() editItem = new EventEmitter();
  @Output() selectedIDs = new EventEmitter();
  @Output() totalFound = new EventEmitter();
  @Input() public columns: Array<TableColumn>;
  @Input() public filterChanged: EventEmitter<any>;
  @Input() public selectedUpdated: EventEmitter<T>;
  @Input() public dataCallback: Function;
  @Input() public dataPageCallback: Function;
  @Input() public searchCallback: Function;
  @Input() public pageSize = '10';
  @Input() public page = 1;
  @Input() pagingDisabled = true;
  @Input() public hideLastPage = false;

  pagingApplicable = true;

  @Input() defferLoading = false;

  @Input() sortingDisabled = false;
  @Input() filter: ISortableFilter;
  @Input() public mapping: Function;
  @Input() title: string;

  items: Observable<T[]>;
  selectedId: string;
  selectorSelectIDs: any[] = [];

  totalCount = 0;

  loading = true;

  exception = false;

  exceptionMessage: SafeHtml = null;

  paginationServerID = createGuid();

  sortOrder: Object;

  colTypes = TableColumnType;

  sortDirection = SortDirection;

  scrollbarVisible = false;

  scrollbarPadding = '';

  primaryKeyColumn: TableColumn;

  rows: any[];

  index = () => { return (this.page - 1) * Number(this.pageSize); };

  calculatedOffsetWidths: Array<string> = [];

  filterChangedSubscription: Subscription;

  checkerCheck: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  checker: Observable<void> = this.checkerCheck.asObservable();

  public config: PaginationInstance = {
    id: this.paginationServerID,
    itemsPerPage: Number(this.pageSize),
    currentPage: this.page,
    totalItems: this.totalCount,
  };

  constructor(
    private trans: TranslationPipe,
    private globals: Globals,
    private sanitizer: DomSanitizer,
    private changeRef: ChangeDetectorRef
  ) {
    let scrollPadding = 5; // Some additional padding already in grid
    if (navigator.appVersion.indexOf('Mac') !== -1)  {
      scrollPadding += 15; // MacOS scrollbar width
    } else if (window.navigator.userAgent.indexOf('Edge') > -1) {
      scrollPadding += 12; // Win10 + Edge scrollbar width
    } else {
      scrollPadding += 17; // Others
    }
    this.scrollbarPadding = scrollPadding + 'px';
  }

  isSelected(selectedItem: T, column: TableColumn = null) {
    if (!!column && !!column.callbackFunction) {
      column.callbackFunction(selectedItem);
      return;
    }
    this.editItem.emit(selectedItem);
  }

  updatePagination() {
      this.config.itemsPerPage = Number(this.pageSize);
      this.config.currentPage = this.page;
      this.config.totalItems = this.totalCount;
  }

  selectorIsSelected(row: any): boolean {
    return (this.selectorSelectIDs.indexOf(this.getPrimaryKeyValue(row)) > -1);
  }

  // used to signal state of the header checkbox
  selectorGetPageState(): SelectorPageState {
    let selectedCount = 0;
    if (isNullOrUndefined(this.rows)) {
      return SelectorPageState.Indetermined;
    }
    this.rows.forEach( r => {
      if (this.selectorIsSelected(r)) {
        selectedCount++;
      }
    });
    if (selectedCount === this.rows.length) {
      return SelectorPageState.AllSelected;
    } else if (selectedCount === 0) {
      return SelectorPageState.NotSelected;
    } else {
      return SelectorPageState.Indetermined;
    }
  }

  selectorSwitchSelected(row: any, $event: Event) {
    $event.stopPropagation();
    this.selectorChangeSelected(row, !this.selectorIsSelected(row));
    this.selectedIDs.emit(this.selectorSelectIDs);
  }

  selectorSwitchAllSelected() {
    let selectorHederSelect = !(this.selectorGetPageState() === SelectorPageState.AllSelected);
    for (let row of this.rows) {
      this.selectorChangeSelected(row, selectorHederSelect);
    }
    this.selectedIDs.emit(this.selectorSelectIDs);
  }

  selectorChangeSelected(row: any, select: boolean) {
    let isSelected = this.selectorIsSelected(row);
    if (select === true) {
      if (isSelected) { return; }
      this.selectorSelectIDs.push(this.getPrimaryKeyValue(row));
    } else {
      if (!isSelected) { return; }
      this.selectorSelectIDs.splice(this.selectorSelectIDs.indexOf(this.getPrimaryKeyValue(row)), 1);
    }
  }

  getPrimaryKeyValue(row: any): string {
    if (isNullOrUndefined(row)) { return undefined; }
    if (isNullOrUndefined(this.primaryKeyColumn)) { return undefined; }
    return row[this.primaryKeyColumn.name];
  }

  getPrimaryKeyColumn(): TableColumn {
    for (let c of this.columns) {
      if (c.primaryKey === true) {
        return c;
      }
    }
    return undefined;
  }

  ngOnInit() {
    let autoCols = 0;
    this.columns.forEach(c => {
      if (c.width === 'auto') {
        autoCols++;
      }
    });

    this.columns.forEach(c => {
      if (c.width === 'auto') {
        c.width = (Math.round((100 / autoCols) * 100) / 100) + '%';
      }
    });
    this.calculatedOffsetWidths = this.columns.map(c => c.width);

    this.filterChangedSubscription = this.filterChanged.subscribe((filter: ISortableFilter) => {
        this.filter = filter;
        this.page = filter.page;
        this.getPage();
      },
      (err) => console.error(err));

    this.primaryKeyColumn = this.getPrimaryKeyColumn();
  }

  ngOnDestroy(): void {
    this.filterChangedSubscription.unsubscribe();
    // this.changeRef.detach();
  }

  // Custom template for pagination:  http://michaelbromley.github.io/ng2-pagination/#/custom-template

  goToPage(page: number) {
    this.page = page;
    this.globals.gridPageChanged.emit(this.page);
    this.getPage();
  }

  getPage() {
      this.loading = true;
      this.exception = false;
      let c = this.checkerCheck;
      if (this.pagingDisabled) {
        this.items = this.dataCallback(this.filter)
          .pipe(
          tap((res) => {
              this.pageSize = (<any>res).length;
              this.totalCount = (<any>res).length;
              this.rows = (<any>res).items;
              this.loading = false;
              this.pagingApplicable = this.totalCount > 10;
              this.updatePagination();
              // this.onResize(null);
              this.totalFound.emit(this.totalCount);
              c.next(null);
          }),
          tap(_ => {
            console.log('getPage');
            this.onResizeWrapper();
          })
        )
          // .catch(res => {
          //   this.showError(res);
          // })
          ;
      } else {
        c.next(null);
        this.filter.page = this.page;
        this.filter.pageSize = Number(this.pageSize);
        let pageRequest = <Observable<any>>this.dataPageCallback(this.filter);
        this.items = pageRequest
            .pipe(
              tap(res => {
              this.totalCount = (<any>res).totalCount;
              this.rows = (<any>res).items;
              this.loading = false;
              this.pagingApplicable = this.totalCount > 10;
              // this.onResize(null);
              this.totalFound.emit(this.totalCount);
              this.updatePagination();
              c.next(null);
            }),
            map(res => (<any>res).items),
            tap(_ => {
              console.log('getPage');
              this.onResizeWrapper();
            })
          );
            // .catch(res => {
            //   this.showError(res);
            // })
      }

      if (!this.changeRef['destroyed']) { this.changeRef.detectChanges(); }
  }

  headerClick(column: TableColumn) {
    if (column.type === TableColumnType.Selector) {
      // switch the select/unselect state for all the rows
      this.selectorSwitchAllSelected();
    } else {
      // sorting by default for all the other types
      this.sortBy(column);
    }
  }

  sortBy(column: TableColumn) {
    if (this.sortingDisabled || !column.sortable || this.totalCount === 0) {
      return;
    }

    if (!!this.filter && (column.name === this.filter.sortColumn || column.sortKey === this.filter.sortColumn)) {
        if (this.filter.sortDirection === SortDirection.asc) {
          this.filter.sortDirection = SortDirection.desc;
        } else if (this.filter.sortDirection === SortDirection.desc) {
          this.filter.sortDirection = SortDirection.asc;
        }
    } else {
      this.filter.sortDirection = SortDirection.asc;
    }

    if (isNullOrUndefined(column.sortKey) || isNullOrWhitespace(column.sortKey)) {
      this.filter.sortColumn = column.name;
    } else {
      this.filter.sortColumn = column.sortKey;
    }

    this.getPage();
  }

  pageSizeChanged(e) {
    this.globals.gridPageSizeChanged.emit(this.pageSize);
    this.page = 1; // reset on page size change
    this.getPage();
  }

  format(val: any, col: TableColumn, row: any = null): SafeHtml {

      // take value from the function
      if (!!col.getValueFunction) {
        val = col.getValueFunction(val, row);
      }

      // take value from the selector
      if (col.type === TableColumnType.Selector) {
        val = this.selectorIsSelected(row);
      }

      if (val === null || val === 'undefined') {
        return '';
      }

      if (!!col.formatFunction) {
          if (col.type === TableColumnType.HTML) {
            return col.formatFunction(val, row);
          }
          if (col.type === TableColumnType.Image) {
            return this.sanitizer.bypassSecurityTrustHtml('<img class="hc-grid-image" src="' + col.formatFunction(val, row) + '" />');
          }
          return this.sanitizer.bypassSecurityTrustHtml(col.formatFunction(val, row));
      }

      if (col.type === TableColumnType.HTML) {
          return val;
      }

      if (col.type === TableColumnType.Image) {
        return '';
      }

      if (col.type === TableColumnType.Money && typeof val === 'number') {
        return val.toFixed(2);
      }
      if (col.type === TableColumnType.Bool) {
          if (!!val) {
            return this.sanitizer.bypassSecurityTrustHtml('<i class="material-icons checkbox_true">check_box</i>');
          } else {
            return this.sanitizer.bypassSecurityTrustHtml('<i class="material-icons checkbox_false">check_box_outline_blank</i>');
          }
      }
      if (col.type === TableColumnType.DayOfMonth) {
          return new Date(val).getDate() + '';
      }
      if (col.type === TableColumnType.Date) {
         return format(val, 'dd/MM/yyyy');
       }
      if (col.type === TableColumnType.DateTime) {
          return format(val, 'HH:mm dd/MM/yyyy');
      }
      if (col.type === TableColumnType.DateTime2) {
          return format(val, 'dd.MM.yyyy HH:mm');
      }
      if (col.type === TableColumnType.LocalizableString) {
        return this.trans.transform(val);
      }
      // if (col.type === TableColumnType.Array) {
      //     let tmpArray = val;
      // }
      return this.sanitizer.bypassSecurityTrustHtml(val);
  }
  getValue(item: any, valuePath: string): string {

    if (typeof valuePath === 'undefined') { return item; }

    let paths = valuePath.split('.');
    if (paths.length === 1) {
      return item.getProp(paths[0]);
    } else {
      return this.getValue(item.getProp(paths[0]), paths.splice(1).join('.'));
    }
  }

  ngAfterViewInit() {
    if (!this.defferLoading) {
        this.getPage();
    } else {
        this.loading = false;
        // this.onResize(null);
        // FIX: Expression has changed after it was checked.
        // See https://github.com/angular/angular/issues/6005

    }
  }

  ngAfterViewChecked(): void {
    // if (this.loading) {
    //   this.onResize(null); // data not loaded yet
    //   if (!this.changeRef['destroyed']) { this.changeRef.detectChanges(); }
    // }
  }

  gridRenderFinished() {
    // this.onResizeWrapper(); // data not loaded yet
    // if (!this.changeRef['destroyed']) { this.changeRef.detectChanges(); }
  }

  captionTrans(col: TableColumnSetting) {
    if (!!col.captionTrans && col.captionTrans !== null) {
      return this.trans.transform(col.captionTrans);
    }
    return col.caption;
  }

  showError(res) {
    this.exception = true;
    this.loading = false;
    if (!!res.message && res.message.length > 0) {
      this.exceptionMessage = this.sanitizer.bypassSecurityTrustHtml(res.message);
    }
  }
}
