import { Injectable }               from '@angular/core';
import { Globals }                  from '../common/globals';
import { HttpClient } from '@angular/common/http';
import { Observable }               from 'rxjs';
import { CommonService }            from './common.service';
import { FilterPurpose }            from '../common/filter-purpose.enum';

@Injectable()
export class FilterService {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'filter');
    }

    public getItems(): Observable<any> {
        return this._commonService.get('list');
    }

    public getItemsForPurpose( purpose: FilterPurpose ): Observable<any> {
        return this._commonService.get('listForPurpose/' + purpose);
    }

    public getDetail(id: number): Observable<any> {
        return this._commonService.get('detail/' + id);
    }

    public insertItem(item: any): Observable<any> {
        return this._commonService.insertItem(item);
    }

    public updateItem(id: string, item: any): Observable<any> {
       return this._commonService.updateItem(id, item);
    }

    public deleteItem(id: string): Observable<any> {
        return this._commonService.deleteItem(id);
    }
}
