import { FilterDialogItemType }             from '../enums/filter-dialog-item-type.enum';
import { FilterDialogItemValues }           from './filter-dialog-filter-value.model';
import { FilterViewModel }                  from '../../../setting/filter/filter-view.model';
import { CategoryMenuNodeModel }            from '../../../product/category-node-model';

export class FilterDialogItem {

    public filterDialogItemID: number;
    public filterDialogID: number;
    public name: string;
    public filterDialogItemType: FilterDialogItemType;
    public displayOrder: number;
    public filterID?: number;
    public filter?: FilterViewModel;
    public filterDialogItemValues: FilterDialogItemValues[];
    public categories?: CategoryMenuNodeModel[];

    constructor(
        filterDialogItemID: number,
        filterDialogID: number,
        name: string,
        filterDialogItemType: FilterDialogItemType,
        displayOrder: number,
        filterDialogItemValues?: FilterDialogItemValues[],
        filterID?: number,
        filter?: FilterViewModel,
        categories?: CategoryMenuNodeModel[]
    ) {
        this.id = filterDialogItemID || 0;
        this.filterDialogID = filterDialogID || 0;
        this.name = name;
        this.filterDialogItemType = filterDialogItemType;
        this.displayOrder = displayOrder || 0;
        filterDialogItemValues.forEach(filterDialogFilterValue => {
            filterDialogFilterValue.filterName = filter.filterName;
        });
        this.filterDialogItemValues = filterDialogItemValues || [];
        this.filterID = filterID || null;
        this.filter = filter || null;
        this.categories = categories || null;
    }

    public static parse(serialized: any, filter: FilterViewModel): FilterDialogItem {
        let filterValues: FilterDialogItemValues[] = [];
        if (serialized.filterDialogItemValues) {
            serialized.filterDialogItemValues.forEach(filterDialogFilterValue => {
                filterValues.push(FilterDialogItemValues.parse(filterDialogFilterValue, filter));
            });
        }
        return new FilterDialogItem(
                    serialized.filterDialogItemID,
                    serialized.filterDialogID,
                    serialized.name,
                    serialized.filterDialogItemType,
                    serialized.displayOrder,
                    filterValues,
                    serialized.filterID,
                    filter
                );
    }

    public static parseFromFilter(filter: FilterViewModel) {
        let filterDialogItem = new FilterDialogItem(0, 0, filter.filterName, FilterDialogItemType.Filter, 0, [], filter.filterID, filter);
        filter.filterInputTypes.forEach(fit => {
            let filterDialogFilterValue = new FilterDialogItemValues(0, 0, fit.placeholderName, fit.filterInputTypeID, '', 0, fit);
            filterDialogItem.add(filterDialogFilterValue);
        });
        return filterDialogItem;
    }

    public add(...itemFilters: FilterDialogItemValues[]): void {
        itemFilters.forEach(itemFilter => {
            itemFilter.filterName = this.filter.filterName;
        });
        this.filterDialogItemValues.push(...itemFilters);
    }

    public remove(...itemFiltersToRemove: FilterDialogItemValues[]): void {
        let indexes: number[] = [];
        itemFiltersToRemove.forEach((filterDialogItemFilterToRemove: FilterDialogItemValues) => {
            let foundIndex: number = this.filterDialogItemValues.findIndex((itemFilterToRemove: FilterDialogItemValues) => {
                return filterDialogItemFilterToRemove.displayOrder === itemFilterToRemove.displayOrder;
            });
            if (foundIndex > -1) {
                indexes.push(foundIndex);
            }
        });
        indexes.forEach(i => this.filterDialogItemValues.splice(i, 1));
    }

    public removeFilter() {
        this.filter = null;
    }

    public removeCategories() {
        this.categories = null;
    }

    public clear() {
        this.filterDialogItemValues = [];
    }

    public get id(): number {
        return this.filterDialogItemID;
    }

    public set id(id: number) {
        this.filterDialogItemID = id;
    }

    public get localizedName(): string {
        return 'admin.filter.' + this.name;
    }

    public determineDataType(query: string): string[] {
        let regexp = /(\{\{)([\sa-zA-Z_\.0-9]+)(\}\})/gm;
        let match: string[],
            matches: string[] = [];
        while (match = regexp.exec(query)) {
            matches.push(match[2]);
        }
        return matches;
    }

    public get serverModel(): any {
        let model = {
            filterDialogItemID: this.id,
            filterDialogID: this.filterDialogID,
            name: this.name,
            filterDialogItemType: this.filterDialogItemType,
            displayOrder: this.displayOrder,
            filterID: this.filterID,
            filterDialogItemValues: []
        };
        model.filterDialogItemValues = this.filterDialogItemValues.map(fv => {
            return fv.serverModel;
        });
        return model;
    }
}

