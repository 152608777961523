import { Component, OnInit, Renderer2, forwardRef, ViewChild, Input, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RichEditorDialogComponent } from './rich-editor-dialog.component';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/auth.service';
import { Globals } from '../globals';
import { isNullOrUndefined } from 'src/app/common/functions';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'hc-angular-rich-editor',
  templateUrl: './angular-rich-editor.component.html',
  styleUrls: ['./angular-rich-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line:no-forward-ref
      useExisting: forwardRef(() => AngularRichEditorComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AngularRichEditorComponent implements OnInit, ControlValueAccessor, OnDestroy {

  private onChange: (value: string) => void;
  private onTouched: () => void;
  private subscriptions: Array<Subscription> = [];

  @Input() id = '';
  @Input() header = '';
  @Input() hideControls = false;
  @Input() placeholders = [];

  @ViewChild('editor', {static: true}) editor: any;
  @ViewChild('editorWrapper', {static: true}) editorWrapper: any;

  constructor(
    private _renderer: Renderer2,
    private dialog: MatDialog,
    private authService: AuthService,
    private globals: Globals
  ) {

  }

  /**
   * Set the function to be called
   * when the control receives a change event.
   *
   * @param fn a function
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Set the function to be called
   * when the control receives a touch event.
   *
   * @param fn a function
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Write a new value to the element.
   *
   * @param value value to be executed when there is a change in contenteditable
   */
  writeValue(value: any): void {
    this.refreshView(value);
  }

  /**
   * refresh view/HTML of the editor
   *
   * @param value html string from the editor
   */
  refreshView(value: string): void {
    const normalizedValue = isNullOrUndefined(value) ? '' : value;
    this._renderer.setProperty(this.editor.nativeElement, 'innerHTML', normalizedValue);
    return;
  }

  public openEditor() {

    const editableElement = this.editor.nativeElement;
    let dialogData = {
      token: this.authService.getToken(),
      content: editableElement.innerHTML,
      uploadUrl: this.globals.textEditorUploadUrl,
      galleryListUrl: 'api/DocumentStorage/gallery/getLast/10',
      apiPrefix: this.globals.getApiURL(),
      tenantHash: this.globals.getTenantHash(),
      header: this.header
    };
    let dialogRef = this.dialog.open(RichEditorDialogComponent, { data: dialogData });
    let richEditorClosed = dialogRef.afterClosed().subscribe(d => {
      if (!!d && d.action === 'save') {
        editableElement.innerHTML = d.content;
        this.onContentChange(d.content);
      }
    });
    this.subscriptions.push(richEditorClosed);
  }

  /**
   * Executed from the contenteditable section while the input property changes
   * @param html html string from contenteditable
   */
  onContentChange(html: string): void {

    if (typeof this.onChange === 'function') {
      this.onChange(html);
      // if ((!html || html === '<br>' || html === '') !== this.showPlaceholder) {
      //   this.togglePlaceholder(this.showPlaceholder);
      // }
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe(), (err) => console.error(err));
  }

}
