import {throwError as observableThrowError,
        Observable,
        from}                       from 'rxjs';
import { ISortableFilter,
         SortableFilter }           from '../common/sortable-filter';
import { Globals }                  from '../common/globals';
import { HttpClient, HttpHeaders, HttpBackend }  from '@angular/common/http';
import { objectToParams }           from '../common/functions';
import { GridInterfaceService }     from './grid.interface.service';
import { KeyValuePair }             from './keyValuePair.model';
import { map,
         catchError, 
         timeout}               from 'rxjs/operators';
import { JwtHelperService }         from '@auth0/angular-jwt';

const defaulTimeout = 30000;
const jwtHelper = new JwtHelperService();

export class CommonService implements GridInterfaceService {

    private _api: string;

    private globals: Globals;

    private http: HttpClient;

    public GetApi() {
        return this._api;
    }

    public getUrlPrefix() {
        return this.globals.GetUrlPrefix(this.localPort, this.useApiURL) + this._api;
    }

    private getHeaders = () => {
        let headers = { 'Content-Type': 'application/json', 'Accept-Language': this.globals.getLanguage() };
        if (this.getCustomHeaders) {
            Object.assign( headers, this.getCustomHeaders() );
        }
        return new HttpHeaders( headers );
    }
    public getCustomHeaders: Function;

    constructor(
        http: HttpClient,
        globals: Globals,
        api: string,
        private localPort = 8371,
        serviceName = undefined,
        private useApiURL = false,
        private httpBackend: HttpBackend = null) {

        this._api = '/api/';
        if (serviceName) {
            // only use the service name when exists and when not running on local host
            if ((<any>window).location.href.indexOf('localhost:') === -1) {
                this._api = this._api + serviceName + '/';
            }
        }
        this._api += api + '/';
        this.globals = globals;
        this.http = http;
    }

    public get(urlSuffix: string, filter: ISortableFilter | any = null, timeoutVal: number = defaulTimeout): Observable<any> {

        let isFuckingIE = (navigator.appName === 'Microsoft Internet Explorer' ||
                            !!(navigator.userAgent.match(/Trident/) ||
                            navigator.userAgent.match(/rv:11/)));

        if (isFuckingIE) {
            if (!!filter) {
                filter.noCache = new Date().getTime();
            } else {
                filter = new SortableFilter(null);
                filter.noCache = new Date().getTime();
            }
        }

        let stringParams = (filter === null) ? '' : objectToParams(filter);
        if (filter !== null) {
            urlSuffix += ('?' + stringParams);
        }

        let options = { headers: this.getHeaders() };

        let request = this.http.get(this.globals.GetUrlPrefix(this.localPort, this.useApiURL) + this._api + urlSuffix, options);
        let response = request.pipe(
            timeout(timeoutVal),
            map((res: any) => {
                return res;
            }), catchError( (error: any) => {
                return this.handleBadResponse(error);
            }));
        return response;
    }

    public getItems(filter: ISortableFilter, timeout: number = defaulTimeout): Observable<any> {
        return this.get('filter', filter, timeout);
    }

    public getList(filter: ISortableFilter, timeout: number = defaulTimeout): Observable<any> {
        return this.get('list', filter, timeout);
    }

    public insertItem(item: any, timeout: number = defaulTimeout): Observable<any> {
        return this.post('', item, timeout);
    }

    public post(urlSuffix: string, item: any, timeoutVal: number = defaulTimeout): Observable<any> {
        let options = { headers: this.getHeaders() };
        let request = this.http.post(this.globals.GetUrlPrefix(this.localPort, this.useApiURL) + this._api + urlSuffix, item, options);
        let response = request.pipe(
            timeout(timeoutVal),
            (res: any) => {
                if (res.statusCode === 200) { return {}; }
                return res;
            }, catchError( (error: any) => {
                return this.handleBadResponse(error);
            }));
        return response;
    }

    public updateItem(id: string, item: any, timeoutVal: number = defaulTimeout): Observable<any> {
        let options = { headers: this.getHeaders() };

        let request = this.http.put(this.globals.GetUrlPrefix(this.localPort, this.useApiURL) + this._api + id, item, options);
        let response = request.pipe(
            timeout(timeoutVal),
            (res: any) => {
                if (res.statusCode === 204) { return {}; }
                return res;
            }, catchError( (error: any) => {
                    return this.handleBadResponse(error);
            }));
        return response;
    }

    public updateItemTenant(id: string, item: any, tenantHash: string = null, timeoutVal: number = defaulTimeout): Observable<any> {

        if (!this.httpBackend) {
            return this.updateItem(id, item, timeoutVal);
        }

        let bearerToken = '';
        this.globals.getMultiloginTenants().forEach(t => {
            let tokenDecoded = jwtHelper.decodeToken(t.jwt);
            if (tenantHash === tokenDecoded.TenantHash) {
                bearerToken = t.jwt;
            }
        });

        let headers = {
            'Content-Type': 'application/json',
            'Accept-Language': this.globals.getLanguage(),
            'Authorization': 'Bearer ' + bearerToken
         };

        let options = { headers:  headers };

        let httpClient = new HttpClient(this.httpBackend);

        let request = httpClient.put(this.globals.GetUrlPrefix(this.localPort, this.useApiURL) + this._api + id, item, options);
        // request.subscribe(r => {
        //     console.log(r);
        // });

        let response = request.pipe(
            timeout(timeoutVal),
            (res: any) => {
                if (res.statusCode === 200) { return {}; }
                return res;
            }, catchError( (error: any) => {
                    return this.handleBadResponse(error);
            }));
        return response;
    }

    private handleBadResponse(res): Observable<any> {

        if (res.name === 'TimeoutError') {
            return observableThrowError(res.name);
        }

        // res.error is object or string
        if (!!res.error) {
            return observableThrowError(res.error);
        }

        if (this.globals.environment === 'Development') {
            console.error(res.text());
        }
        try {
            return observableThrowError(res.json());
        } catch {
            if (res.statusText) {
                return observableThrowError(res.statusText);
            }
            return observableThrowError(res);
        }
    }

    public deleteItem(id: string, timeout: number = defaulTimeout): Observable<any> {
        return this.delete(id, timeout);
    }

    public delete(urlSuffix: string, timeoutVal: number = defaulTimeout): Observable<any> {
        let options = { headers: this.getHeaders() };
        let request = this.http.delete(this.globals.GetUrlPrefix(this.localPort, this.useApiURL) + this._api + urlSuffix, options);
        let response = request.pipe(
            timeout(timeoutVal),
            (res: any) => {
                if (res.statusCode === 200) { return {}; }
                return res;
            }, catchError( (error: any) => {
                    return this.handleBadResponse(error);
            }));
        return response;
    }

    public uploadFile(urlSuffix: string, files: File[],
            keyValueData: KeyValuePair[] = [],
            progressCallback: (progress) => {} = null,
            timeoutVal: number = defaulTimeout): Observable<any> {
        let url = this.globals.GetUrlPrefix(this.localPort, this.useApiURL) + this._api + urlSuffix;
        let observable =
          from(new Promise((resolve, reject) => {
            let formData = new FormData();
            let xhr = new XMLHttpRequest();
            for (let i = 0; i < files.length; i++) {
              formData.append('uploads[]', files[i], files[i].name);
            }
            if (!!keyValueData) {
                keyValueData.forEach((val) => formData.append(val.key, val.value) );
            }

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  return resolve(xhr.response);
                } else {
                  return reject(xhr.response);
                }
              }
            };
            if (!!progressCallback) {
                xhr.upload.onprogress = (event) => {
                    let progress = Math.round(event.loaded / event.total * 100);
                    progressCallback(progress);
                };
            }
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', this.globals.getAuthorizationHeaderValue());
            xhr.setRequestHeader('Accept-Language', this.globals.getLanguage());
            xhr.send(formData);
        }));
        return observable.pipe(
            timeout(timeoutVal),
            (res: any) => {
                if (res.statusCode === 200) { return {}; }
                return res;
            }, catchError( (error: any) => {
                    return this.handleBadResponse(error);
            }));
      }
}
