import { ISortableFilter }          from '../common/sortable-filter';
import { IBaseService }             from './service.interface';
import { Observable }               from 'rxjs';
import { CommonService }            from './common.service';
import { HttpClient }               from '@angular/common/http';
import { Globals }                  from '../common/globals';
import { SlotPlanViewModel }        from '../planner/slotplan/slotplan-view.model';
import { Injectable }               from '@angular/core';

@Injectable()
export class SlotPlanService implements IBaseService<SlotPlanViewModel> {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'slotplan');
    }

    public getItems(filter: ISortableFilter): Observable<SlotPlanViewModel[]> {
        return this._commonService.get('list', filter);
    }

    deleteItem(id: any): Observable<any> {
        return this._commonService.deleteItem(id + '');
    }
    insertItem(item: any): Observable<number> {
        return this._commonService.post('', item);
    }
    updateItem(id: any, item: any): Observable<any> {
        return this._commonService.updateItem(id + '', item);
    }
    getDetail(id: string): Observable<SlotPlanViewModel> {
        return this._commonService.get('detail/' + id);
    }
}
