import { Component,
         OnInit,
         ChangeDetectorRef,
         OnDestroy }                                from '@angular/core';
import { Router,
         NavigationEnd }                            from '@angular/router';
import { Globals }                                  from '../common/globals';
import { ActivatedRoute }                           from '@angular/router';
import { NavItemData, MenuItem }                              from '../common/nav-item.model';
import { DeleteDialogComponent }                    from '../common/dialog.delete.component';
import { Subscription }                             from 'rxjs';
import { filter }                                   from 'rxjs/operators';

@Component({
  selector: 'hc-action-row',
  templateUrl: './action-row.component.html',
  styleUrls: ['./action-row.component.scss']
})
export class ActionRowComponent implements OnInit, OnDestroy {

    public canSave = false;
    public canDelete = false;
    public canMove = false;
    public isFirst = false;
    public isLast = false;
    public isAlive = true;
    public globals: Globals;
    public currentNavSettings = new NavItemData();

    public hasCustomActions = false;

    public mouseOverSaveBtn = false;
    public mouseOverSaveOptions = false;

    public get customActionsVisible() {
        return this.mouseOverSaveBtn || this.mouseOverSaveOptions;
    }

    private subscriptions: Array<Subscription> = [];
    public customSaveActions: Array<MenuItem> = [];
    public defaultSaveAction: MenuItem = null;
    public selectedIDs: Array<any> = [];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private cd: ChangeDetectorRef,
        globals: Globals,
        ) {
            this.globals = globals;
            let sb =  this.globals.updatedBreadcrumb.subscribe(() => {
                this.currentNavSettings = this.globals.currentNavData();
                cd.markForCheck();
            },
            (err) => console.error(err));

            this.subscriptions.push(sb);

            let sa =  this.globals.updatedActionRow.subscribe(() => {
                this.currentNavSettings = this.globals.currentNavData();
                cd.markForCheck();
            },
            (err) => console.error(err));
            this.subscriptions.push(sa);

            let saveAction = new MenuItem();
            saveAction.callback = () => this.save();
            saveAction.localizationKey = 'admin.web.save';
            saveAction.matIcon = 'done';

            let sbtn =  this.globals.updatedSaveButton.subscribe(() => {
                this.currentNavSettings = this.globals.currentNavData();
                this.customSaveActions = this.globals.currentNavData().customSaveActions;
                if (this.globals.currentNavData().defaultCustomSaveAction) {
                    this.defaultSaveAction = this.globals.currentNavData().defaultCustomSaveAction;
                    this.customSaveActions.push(saveAction); // Add "Save" button
                }
                this.hasCustomActions = this.customSaveActions && this.customSaveActions.length > 0;
                // console.log('AR', this.currentNavSettings);
                cd.markForCheck();
            },
            (err) => console.error(err));

            this.subscriptions.push(sbtn);
            this.defaultSaveAction = saveAction;
            this.hasCustomActions = this.customSaveActions && this.customSaveActions.length > 0;
            // this.hasCustomActions = true
    }

    ngOnInit() {
        this.currentNavSettings = this.globals.currentNavData();
    }

    ngOnDestroy(): void {
        this.isAlive = false;
        this.subscriptions.forEach(s => s.unsubscribe(),
        (err) => console.error(err));
        this.customSaveActions = [];
    }

    defaultSave() {
        let currentNavData = this.globals.currentNavData();
        currentNavData.saving = true;
        this.defaultSaveAction.callback();
        currentNavData.saving = false;
        this.globals.dataSaved.emit(null);
    }

    save() {
        if (!!this.globals.currentNavItem().saveCallback) {
            let currentNavData = this.globals.currentNavData();
            currentNavData.saving = true;
            this.cd.markForCheck();
            this.globals.currentNavItem().saveCallback();
            currentNavData.saving = false;
            this.globals.dataSaved.emit(null);
            this.cd.markForCheck();
        }
    }

    clone() {
        if (!!this.globals.currentNavItem().cloneCallback) {
            this.globals.currentNavItem().cloneCallback();
        }
    }

    // delete() {
    //     if (!!this.globals.currentNavItem().deleteCallback) {
    //         setTimeout(() => {
    //             let deleteConfirmation = this.confirmDialog.open(DeleteDialogComponent);
    //             deleteConfirmation.afterClosed().subscribe(res => {
    //                 if (res === true) {
    //                     this.globals.currentNavItem().deleteCallback();
    //                 }
    //             },
    //             (err) => console.error(err));
    //         });
    //     }
    // }

    backward() {
        if (!!this.globals.currentNavItem().backward) {
            this.globals.currentNavItem().backward();
        }
    }

    forward() {
        if (!!this.globals.currentNavItem().forward) {
            this.globals.currentNavItem().forward();
        }
    }

    public setMouseOverSaveBtn($event, isOver: boolean) {
        $event.stopPropagation();
        this.mouseOverSaveBtn = isOver;
    }

    public setMouseOverOptions($event, isOver: boolean) {
        $event.stopPropagation();
        this.mouseOverSaveOptions = isOver;
    }
}
