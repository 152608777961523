import { Component,
         OnInit,
         OnDestroy,
         ViewEncapsulation,
         Input,
         ChangeDetectionStrategy,
         ChangeDetectorRef}                                from '@angular/core';
import { Router }                                   from '@angular/router';
import { ComponentService }                         from '../shared/component.service';
import { Globals }                                  from '../common/globals';
import { MenuGroupDynamicEnum }                            from './menugroup-dynamic.enum';
import { Honeycomb } from '../shared/honeycomb-api/honeycomb-api';
import { enumToValues, enumToStrings, routeToUIRole, isNullOrUndefined } from '../common/functions';
import { TranslationService } from '../shared/translation.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { of, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'hc-menu-dynamic-item',
  templateUrl: './menu-dynamic-item.component.html',
  styleUrls: ['./menu-dynamic-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})
export class MenuDynamicItemComponent implements OnInit, OnDestroy {

  @Input() menuItem: any;
  @Input() level: number;

  isOpen = false;

  menuGroup = MenuGroupDynamicEnum;

  constructor(
      private router: Router,
      private componentService: ComponentService,
      private trans: TranslationService,
      private permissionService: NgxPermissionsService,
      private authService: AuthService,
      public globals: Globals,
      private cd: ChangeDetectorRef
    ) {
  }

  async ngOnInit() {
    if (this.menuItem.special === 'attributes') {
      let attributeTypesValues = [];
      let attributeTypesNames = [];
      let attributeTypesEnum = Honeycomb.Tenant.Admin.IService.Enums.AttributeTypeEnum;

      attributeTypesValues = enumToValues(Honeycomb.Tenant.Admin.IService.Enums.AttributeTypeEnum);
      attributeTypesNames = enumToStrings(Honeycomb.Tenant.Admin.IService.Enums.AttributeTypeEnum);

      this.menuItem.items = attributeTypesValues.map((at, ix) => {
        let locKey = ('admin.web.attribute.' +  attributeTypesNames[ix]);
        let route = ('/setting/attributes/list/' + at);
        let result = {
          list_loc_key: locKey,
          menu_loc_key: locKey,
          route: route
        };
        return result;
      });
    }

    if (this.menuItem.special === 'components') {
      const components = await this.componentService.getMenu().pipe(tap(p => this.cd.detectChanges())).toPromise();
      this.menuItem.items = components.map(c => {
        let listLocKey = 'admin.web.list-' + c.componentName;
        let menuLocKey = 'admin.web.' + c.componentName;
        let route = '/setting/configuration/list/' + c.componentID;

        let res = {
          list_loc_key: listLocKey,
          menu_loc_key: menuLocKey,
          route: route
        };
        return res;
      });
    }
  }

  canShow(permission: string): Observable<boolean> {
    if (!permission) {
      return of(false);
    }
    let hasUiRole = this.authService.uiRoles.indexOf(permission) > -1;
    return of(hasUiRole);
  }

  getUIRole(item: any): string {
    if (!item || (!item.route && (!item.items || item.items.length === 0))) {
      return null;
    }

    // if (!!item.items && item.items.length > 0 && !item.route) { // virtual UI that has visible items should be visible
    //   routeToUIRole(item.route) + '_virtual'
    // }

    return routeToUIRole(item.route) + '_read';
  }

  getMenuText(menu_loc_key: string): string {
    return this.trans.instant(menu_loc_key);
  }

  hasVisibleSubitems(menuItem: any): Observable<boolean> {
    if (isNullOrUndefined(menuItem.items) || menuItem.items.length === 0)  {
      return of(false);
    }
    if (this.authService.uiRoles.indexOf(this.getUIRole(menuItem)) > -1) {
      return of(true);
    }
    let subTree = this.canShowSubTree(menuItem);
    return of(subTree);
  }

  canShowSubTree(menuItem: any): boolean {
    for (const mi of menuItem.items) {
      if (this.authService.uiRoles.indexOf(this.getUIRole(mi)) > -1) {
        return true;
      }
      if (isNullOrUndefined(mi.items) || mi.items.length === 0) {
        continue;
      }
      if (this.canShowSubTree(mi)) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy(): void {
  }
}
