export enum MenuGroupDynamicEnum {
    Unset               = 0,
    // tslint:disable:no-bitwise
    Customer            = 1,    // 0000 0000 0010
    CustomerSurveys     = 3,    // 0000 0000 0011
    Products            = 4,    // 0000 0000 0100
    Communication       = 8,    // 0000 0000 1000
    Documents           = 16,   // 0000 0001 0000
    Statistics          = 32,   // 0000 0010 0000
    SettingsCore        = 64,   // 0000 0100 0000

    Settings            = 512,  // 0010 0000 0000
    SettingsVariants    = 640,  // 0010 1000 0000
    SettingsOrder       = 768,  // 0011 0000 0000
}
