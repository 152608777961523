import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * @title Dialog Overview
 * @note DUPLICIT: InputDialogComponent
 */
@Component({
  selector: 'hc-prompt-dialog',
  template:
  `
  <div fxLayout="column">
        <p fxFlex>
            {{ data.message ? data.message : ('admin.web.common-prompt' | trans) }}
        </p>
        <div fxFlex="none"  fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
          <button mat-raised-button (click)="clickNo()">{{ 'common.no' | trans }}</button>
          <button mat-raised-button color="primary" (click)="clickYes()" >{{ 'common.yes' | trans }}</button>
        </div>
  </div>
   `
})

export class PromptDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<PromptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

    clickYes(): void {
        this.data = true;
        this.dialogRef.close(true);
    }

    clickNo(): void {
        this.data = false;
        this.dialogRef.close(false);
    }

}
