import { EventEmitter } from '@angular/core';
import { ISortableFilter } from './sortable-filter';
import { PortalAgenda } from '../interface/interface.model';
import { isNullOrUndefined } from './functions';

/**
 * Navigation class for breadcrumbs navigation and action row
 */
export class NavItem {
    public path = '';

    public menu: MenuItemTop;

    public saveCallback: () => void;

    public cloneCallback: () => void;

    public forward: () => void;

    public backward: () => void;

    public data: any = null;

    constructor(path?: string, data?: NavItemData) {
        if (path) {
            this.path = path;
        }
        if (data) {
            this.data = data;
        }
    }
}

export class NavItemData {
    public canSave = true;
    public saving = false;
    public newItem = false;
    public canDelete = false;
    public canCreate = false;

    public canClone = false;
    public relativeToParent = false;
    public locKey: string = null;
    public detailName: string = null;
    public actionRowVisible = false;
    public containerScrollDisabled = false;
    public isComponentList = false;
    public disableClose = false;
    public passedData: any;
    public selectedParentsList: any[];
    public parent: string = null;
    public parentId: string = null;
    public selectedId: any;

    public customData: any;

    // Page context
    public activeTabIndex: number = null;
    public gridPage: number = null;
    public gridPageSize: string = null;
    public filter: ISortableFilter = null;

    public actionButtons: Array<ActionRowButton> = [];
    public customSaveActions: Array<MenuItem> = [];
    public defaultCustomSaveAction: MenuItem = null;
    public uiBase: string = null;

    performSearch: EventEmitter<any> = new EventEmitter();

    constructor(canSave?: boolean, newItem?: boolean, canDelete?: boolean,
        canClone?: boolean, relativeToParent?: boolean, locKey?: string, detailName?: string,
        actionRowVisible?: boolean, isComponentList?: boolean, passedData?: any,
        selectedParentsList?: any, selectedId?: any, containerScrollDisabled?: boolean, customData?: any, disableClose?: boolean
    ) {
        if (!isNullOrUndefined(canSave))                  { this.canSave = canSave; }
        if (!isNullOrUndefined(newItem))                  { this.newItem = newItem; }
        if (!isNullOrUndefined(canDelete))                { this.canDelete = canDelete; }
        if (!isNullOrUndefined(canClone))                 { this.canClone = canClone; }
        if (!isNullOrUndefined(relativeToParent))         { this.relativeToParent = relativeToParent; }
        if (!isNullOrUndefined(locKey))                   { this.locKey = locKey; }
        if (!isNullOrUndefined(detailName))               { this.detailName = detailName; }
        if (!isNullOrUndefined(actionRowVisible))         { this.actionRowVisible = actionRowVisible; }
        if (!isNullOrUndefined(containerScrollDisabled))  { this.containerScrollDisabled = containerScrollDisabled; }
        if (!isNullOrUndefined(isComponentList))          { this.isComponentList = isComponentList; }
        if (!isNullOrUndefined(passedData))               { this.passedData = passedData; }
        if (!isNullOrUndefined(selectedParentsList))      { this.selectedParentsList = selectedParentsList; }
        if (!isNullOrUndefined(selectedId))               { this.selectedId = selectedId; }
        if (!isNullOrUndefined(customData))               { this.customData = customData; }
        if (!isNullOrUndefined(disableClose))             { this.disableClose = disableClose; }
    }
}

export class ActionRowButton {
    constructor(
        public matIcon = '',
        public locKey = '',
        public callback = () => {}
    ) {
    }
}


export class MenuItem {

    public callback?: () => void | Promise<any> = null;
    public confirmText?: string = null;

    public localizationKey: string;
    public matIcon?: string;
    public handleSelected?: boolean;
    public subItems?: Array<MenuItem> = [];

    constructor(
        localizationKey?: string,
        matIcon?: string,
        handleSelected?: boolean,
        subItems?: Array<MenuItem>
    ) {
        this.localizationKey = localizationKey || 'admin.web.options';
        this.matIcon = matIcon || 'setting';
        this.handleSelected = !!handleSelected;
        this.subItems = subItems || [];
    }
}

export class MenuItemTop extends MenuItem {

    public selectedIDs: any[] = [];
    public agenda: PortalAgenda;
    public hideExports = false;

    public static InitDetailMenu( deleteCallback: () => void = null,
                                  cloneCallback: () => void = null) {
        return this.InitDefaultMenu( null, null, deleteCallback, cloneCallback );
    }

    public static InitDefaultMenu(newCallback: () => void = null,
                                  agenda?: PortalAgenda,
                                  deleteCallback: () => void = null,
                                  cloneCallback: () => void = null) {

        let top = new MenuItemTop();
        top.agenda = agenda || PortalAgenda.Undefined;
        top.subItems = [];
        if (newCallback) {
            top.addSubMenu('admin.web.new', 'add', false, [], newCallback);
        }
        if (deleteCallback) {
            let confirmText = 'admin.web.delete-question';
            top.addSubMenu('admin.web.delete', 'delete', false, [], deleteCallback, confirmText);
        }
        if (cloneCallback) {
            top.addSubMenu('admin.web.copy', 'call_split', false, [], cloneCallback);
        }
        return top;
    }

    constructor(
        agenda?: PortalAgenda,
        localizationKey?: string,
        matIcon?: string,
        handleSelected?: boolean,
        subItems?: Array<MenuItem>,
        hideExports = false,
    ) {
        super(
            localizationKey,
            matIcon,
            handleSelected,
            subItems
        );
        this.agenda = agenda || PortalAgenda.Undefined;
        this.hideExports = hideExports;
    }

    public addSubMenu( text: string,
                       command: string,
                       handleSelected = false,
                       subItems: [],
                       callback: () => void,
                       confirmText: string = null
                    ): MenuItem {
        let newItem = new MenuItem(text, command, handleSelected, subItems);
        newItem.callback = callback;
        newItem.confirmText = confirmText;
        this.subItems.push(newItem);
        return newItem;
    }
}
