import { ElasticFilterElement }         from './elastic-filter-element.model';
import { ElasticPagination }            from './elastic-pagination.model';
import { ElasticSorting }               from './elastic-sorting.model';
import { SortableFilter }               from './sortable-filter';
import { FilterPurpose } from './filter-purpose.enum';

export class ElasticFilterRequest extends SortableFilter {

    public filters: ElasticFilterElement[] = [];
    public pagination: ElasticPagination = new ElasticPagination();
    public sorting: ElasticSorting[] = [];
    public filterPurpose: FilterPurpose;
    // public filterPanel?: any;
    // public usePersonalization?: boolean;
}
