import { Output,
         EventEmitter,
         OnDestroy,
         Injectable,
         }                              from '@angular/core';
import { Observable,
         Observer,
         Subscription, 
         of}                 from 'rxjs';
import { JwtHelperService }             from '@auth0/angular-jwt';

import { Headers }                      from '@angular/http';
import { MenuGroupEnum }                from '../menu/menugroup.enum';
import { NavigationEnd,
         Router,
         Route,
         ActivatedRoute,
         NavigationStart}               from '@angular/router';
import { NavItem,
         NavItemData,
         ActionRowButton,
         MenuItem,
         MenuItemTop}                   from './nav-item.model';
import { NavigationExtras }             from '@angular/router';
import { isNullOrWhitespace,
         deepCopy,
         isPromise,
         routeToUIRole,
         isNullOrUndefined}                      from './functions';
import { NgxPermissionsService }        from 'ngx-permissions';
import { HcLocalStorage }               from './local-storage';
import { PlatformLocation }             from '@angular/common';
import { filter }                       from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { HttpHeaders } from '@angular/common/http';


export const ContentHeaders = new HttpHeaders();
ContentHeaders.append('Accept', 'application/json');
ContentHeaders.append('Content-Type', 'application/json');

export const AuthHeaders = new HttpHeaders();
AuthHeaders.append('Accept', 'application/json');
AuthHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

const jwtHelper = new JwtHelperService();

@Injectable()
export class Globals implements OnDestroy {

    @Output()
    updateMenu = new EventEmitter();

    @Output()
    toggleMenu = new EventEmitter();

    @Output()
    langChanged = new EventEmitter();

    @Output()
    updatedBreadcrumb = new EventEmitter();

    @Output()
    updatedActionRow = new EventEmitter();

    @Output()
    updatedSaveButton = new EventEmitter();

    @Output()
    forceUpdateContent = new EventEmitter();

    @Output()
    gridPageSizeChanged = new EventEmitter();

    @Output()
    gridPageChanged = new EventEmitter();

    @Output()
    tabIndexChanged = new EventEmitter();

    @Output()
    optionsMenuUpdated = new EventEmitter<MenuItemTop>();

    @Output()
    optionsMenuAction = new EventEmitter<MenuItem>();

    public dataSaved = new EventEmitter();

    showProgress = false;
    showProgressUpdate: Observable<boolean>;
    showProgressObserver: Observer<boolean>;

    public tenantHash: string;

    public apiUrl: string;

    public breadcrumb = new Array<NavItem>();

    public isDownloadingPdf = false;

    public isDownloadingCsv = false;

    public isTranslationsDownloadRunning = false;

    public isFirstLoad = true;

    public isNavigatingBack = false;

    public environment: string = null;

    public settings = [];

    public selectedMenuItem: MenuGroupEnum = MenuGroupEnum.Unset;

    public dictionary: any = {};

    private _currentLang: string;

    private antiCache = '?anticache=' + new Date().getTime();

    public menuOpen = true;

    public navigationContextData = {};

    public updateBreadcrumb = false;

    private prevNavigation: NavItem;
    private prevBreadcrumb: NavItem[];

    private navigationEndSubscription: Subscription;

    private navigationBeginSubscription: Subscription;

    private multiloginTenants: any[];

    public saveDialog: MatDialog;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public permissionService: NgxPermissionsService,
        private hcLocalStorage: HcLocalStorage,
        private location: PlatformLocation,
        private dialog: MatDialog,
    ) {
        this.saveDialog = dialog;

        this.showProgressUpdate = Observable.create((observer: Observer<boolean>) => {
            this.showProgressObserver = observer;
        });
        let storedBreadcrumb = sessionStorage.getItem('hc_route');
        if (!!storedBreadcrumb) { // this.currentNavItem() can be null for some rare scenarios
            this.breadcrumb = <Array<NavItem>>JSON.parse(storedBreadcrumb);
            this.router.navigateByUrl(this.currentNavItem().path, <NavigationExtras>this.currentNavItem().data).catch(() => {
                // handle bad route within cache
                this.breadcrumb.splice(1); // Keep home
                sessionStorage.removeItem('hc_route');
                this.router.navigateByUrl('');
            });
        } else {
            let homeConf = router.config.find(c => c.path === ''); // Home by default
            this.breadcrumb.push(<NavItem>homeConf);
        }

        this.location.onPopState(() => {
            if ((this.breadcrumb.length > 1) &&
                ((this.location.pathname === this.breadcrumb[this.breadcrumb.length - 2].path) ||
                  this.location.pathname === '/' && this.breadcrumb[this.breadcrumb.length - 2].path === '')) {
                this.isNavigatingBack = true;
                this.navigationContextData = this.prevNavigation = this.breadcrumb.pop();
                this.updateBreadcrumb = false;
                this.breadcrumbSave();
            } else {
                this.navigationContextData = this.prevNavigation = this.breadcrumb.pop();
                // this.isNavigatingBack = false;
                // this.breadcrumb.push(this.prevNavigation);
            }
            this.updatedBreadcrumb.emit();
        });

        this.navigationBeginSubscription = this.router.events.pipe(
                filter(e => e instanceof NavigationStart)
            ).subscribe(nav => {
                // Reset options menu
                this.setMenu(null);
                // console.log(nav);
        });

        this.navigationEndSubscription = this.router.events.pipe(
                filter(e => e instanceof NavigationEnd)
                // ,takeWhile(() => this.isFirstLoad)
            )
            .subscribe((e: NavigationEnd) => {

                let routeConfigs = [];
                let nextRoute = this.route;
                while (!!nextRoute) {
                    // Must be deep-copy otherwise it will ruin Angular routing and you'll end up in hell
                    if (nextRoute.routeConfig) {
                        let routeConfigClone = deepCopy(nextRoute.routeConfig);
                        routeConfigs.push(routeConfigClone);
                    }
                    nextRoute = nextRoute.firstChild;
                }

                if (routeConfigs.length === 0) { return; }

                let lastRouteConfig = routeConfigs.reverse()[0];

                let navItem = <NavItem>lastRouteConfig;
                if (!navItem.data) {
                    navItem.data = new NavItemData();
                }
                Object.assign(navItem.data, this.navigationContextData);
                navItem.path = e.url;

                if (this.updateBreadcrumb) {
                    this.breadcrumb.push(navItem);
                }

                this.updatedBreadcrumb.emit();
                this.breadcrumbSave();
                this.isFirstLoad = false;
            },
            (err) => console.error(err)
            );
        let token = this.getToken();
        if (token) {
            let decodedToken = jwtHelper.decodeToken(token);
            this.setJWTToken(decodedToken);
        }
    }

    public setJWTToken( decodedToken ) {
        if (!!decodedToken.tenantHash) {
            this.tenantHash = decodedToken.TenantHash;
        }
        if (!!decodedToken.apiUrl) {
            this.apiUrl = decodedToken.apiUrl;
            // the later logic expects the url not to end with slash, so check & remove
            let lastChar = this.apiUrl.substr(-1);
            if (lastChar === '/') {
                this.apiUrl = this.apiUrl.slice(0, -1);
            }
        }
    }

    setProgress(running: boolean) {
        this.showProgress = running;
        if (!!this.showProgressObserver) {
            this.showProgressObserver.next(this.showProgress);
        }
    }

    // public async setRole(roleName: string) {
    //     // let hasPermission = await this.permissionService.hasPermission(roleName);
    //     if (await this.permissionService.hasPermission(roleName)) {
    //         this.permissionService.removePermission(roleName);
    //     } else {
    //         this.permissionService.addPermission(roleName);
    //     }
    // }

    GetHostnamePrefix(): string {
        return  '//' + window.location.hostname + '';
    }

    GetUrlPrefix(localPort = 8371, useApiUrl = false): string {
        if ((<any>window).location.href.indexOf('localhost:') > -1) {
            return '//' + window.location.hostname + ':' + localPort;
        } else {
            if (useApiUrl && this.apiUrl) {
                return this.apiUrl;
            }
            return '';
        }
    }

    public getLocalStorage(): HcLocalStorage {
        return this.hcLocalStorage;
    }

    GetAnticacheSuffix(): string {
        return (<any>window).anticache || this.antiCache;
    }

    ShowProgress(): boolean {
        return this.showProgress;
    }

    OverrideToDev(): boolean {
        return false;
    }

    // --------------- Navigation START ------------------------//

    public navigateTo(path: string, data?: any) {
        this.prevNavigation = this.currentNavItem();
        this.prevBreadcrumb = [...this.breadcrumb];
        this.isNavigatingBack = false;
        this.navigationContextData = data || {};
        this.updateBreadcrumb = true;
        this.router.navigateByUrl(path);
    }

    public navigateReplace(path: string, data?: any) {
        this.prevBreadcrumb = [...this.breadcrumb];
        this.prevNavigation = this.breadcrumb.pop();
        this.updateBreadcrumb = true;
        this.navigationContextData = data || {};
        this.router.navigateByUrl(path).then(() => {
            this.router.onSameUrlNavigation = 'reload';
        });
    }

    public navigateToRoot() {
        this.prevBreadcrumb = [...this.breadcrumb];
        this.breadcrumb.splice(1);
        this.isNavigatingBack = true;
        this.updateBreadcrumb = false;
        this.router.navigateByUrl(this.breadcrumb[0].path);
    }

    public navigateClearTo(path: string, data?: any) {
        this.prevNavigation = this.currentNavItem();
        this.prevBreadcrumb = [...this.breadcrumb];
        this.isNavigatingBack = this.breadcrumb.length > 1;
        this.breadcrumb.splice(1);
        this.navigationContextData = data || {};
        this.updateBreadcrumb = true;
        this.router.navigateByUrl(path);
    }

    public revertNavigation() {
        this.currentNavData().saving = false;

        if (this.prevNavigation.path === this.currentNavItem().path) { // return to the same page
            return;
        }
        this.breadcrumb = this.prevBreadcrumb;
        this.breadcrumbSave();
        if (this.updatedBreadcrumb) {
            this.updatedBreadcrumb.emit();
        }
        this.updateBreadcrumb = false;
    }

    public navigateBack() {
        this.isNavigatingBack = true;
        this.prevBreadcrumb = [...this.breadcrumb];
        if (this.breadcrumb.length > 1) {
            this.prevNavigation = this.breadcrumb.pop();
        }
        this.updateBreadcrumb = false;
        let targetPath = this.breadcrumb[this.breadcrumb.length - 1];
        this.navigationContextData = targetPath.data || {};
        this.router.navigateByUrl(targetPath.path);
    }

    public breadcrumbClick(e: Route) {
        this.isNavigatingBack = true;
        this.prevBreadcrumb = [...this.breadcrumb];
        let ix = this.breadcrumb.findIndex((b) => b === e);

        if (this.breadcrumb.length > 1) {
            this.prevNavigation = this.breadcrumb[ix + 1];
        }

        this.breadcrumb.splice(ix + 1);
        this.breadcrumbSave();
        this.updateBreadcrumb = false;
        this.router.navigateByUrl(e.path);
    }

    public currentNavItem(): NavItem {
        let navItem = this.breadcrumb[this.breadcrumb.length - 1];
        if (!!navItem && !navItem.data) {
            navItem.data = new NavItemData();
        }
        if (!navItem.data.customSaveActions) {
            navItem.data.customSaveActions = [];
        }
        return navItem;
    }

    public currentNavData(): NavItemData {
        if (!this.currentNavItem()) {
            return new NavItemData();
        }
        let resultData = Object.assign({}, this.currentNavItem().data);
        Object.keys(resultData).forEach((key) => (isNullOrUndefined(resultData[key])) && delete resultData[key]);
        return resultData;
    }

    public setCurrentNavActionButtons(actionRowButtons: Array<ActionRowButton>) {
        this.currentNavItem().data.actionButtons = actionRowButtons;
        this.updatedActionRow.emit(actionRowButtons);
    }

    public setMultiSaveButtons(actionRowButtons: Array<MenuItem>) {
        this.currentNavItem().data.customSaveActions = actionRowButtons;
        this.updatedSaveButton.emit(actionRowButtons);
    }

    public setCustomUiBase(uiBase: string) {
        this.currentNavItem().data.uiBase = uiBase;
    }

    public async setMultiSaveButtonsSaveAndClose(
        saveCallback: () => boolean | Promise<boolean>,
        isDefault = true,
        disableUiRoleCheck = false
    ) {
        let saveAndCloseAction = new MenuItem();
        saveAndCloseAction.callback = async () => {
            let callbackRes: boolean| Promise<boolean> = saveCallback();
            if (isPromise(callbackRes)) {
                callbackRes = await callbackRes;
            } else {
                callbackRes = <boolean>callbackRes;
            }
            if (callbackRes) {
                this.navigateBack();
            }
        };
        saveAndCloseAction.localizationKey = 'admin.web.save-and-close';
        saveAndCloseAction.matIcon = 'check_circle_outline';
        if (isDefault) {
            this.currentNavItem().data.defaultCustomSaveAction = saveAndCloseAction;
        } else {
            this.currentNavItem().data.customSaveActions.push(saveAndCloseAction);
        }
        if (!this.currentNavItem().saveCallback) {
            this.currentNavItem().saveCallback = saveCallback;
        }

        let [canSave, canCreate] = await this.getSaveCreateRights();
        this.currentNavItem().data.canSave = disableUiRoleCheck || canSave;
        this.currentNavItem().data.canCreate = disableUiRoleCheck || canCreate;
        this.updatedSaveButton.emit(saveCallback);
    }

    public setCurrentBreadcrumbData(data: NavItemData) {
        this.breadcrumb[this.breadcrumb.length - 1].data = data;
        this.breadcrumbSave();
    }

    public getCurrentBreadcrumbData(): NavItemData {
        return this.breadcrumb[this.breadcrumb.length - 1].data;
    }

    public breadcrumbSave() {
        let breadcrumbCopy = new Array<NavItem>();
        this.breadcrumb.forEach(b => {
            let d = b.data;
            let navData: NavItemData;
            if (!!d) {
                navData = new NavItemData(d.canSave, d.newItem, d.canDelete, d.canClone,
                                          d.relativeToParent, d.locKey, d.detailName, d.actionRowVisible, d.isComponentList,
                                          d.passedData, d.selectedParentsList, d.selectedId, d.containerScrollDisabled);
            }
            let clonedNavItem = new NavItem(b.path, navData);
            breadcrumbCopy.push(clonedNavItem);
        });

        sessionStorage.setItem('hc_route', JSON.stringify(breadcrumbCopy));
    }

    public detectCircularNavigation(path: string): boolean {
        return this.breadcrumb.some((navItem: NavItem) => {
            return navItem.path === path || navItem.path === ('/' + path);
        });
    }

    public navigateAndResetHistory( navigateUrl = '') {
        let homeConf = this.router.config.find(c => c.path === ''); // Home by default
        this.breadcrumb.splice(0, this.breadcrumb.length);
        this.breadcrumb.push(<NavItem>homeConf);
        sessionStorage.removeItem('hc_route');
        if (this.updatedBreadcrumb) {
            this.updatedBreadcrumb.emit();
        }
        return this.router.navigateByUrl(navigateUrl);
    }

    public setMenu(options: MenuItemTop): MenuItemTop {
        let navItem = this.breadcrumb[this.breadcrumb.length - 1];
        if (!!navItem && !navItem.menu) {
            navItem.menu = options;
        }
        this.optionsMenuUpdated.emit(options);
        return options;
    }

    public async setDetailMenu(deleteCallback: () => void, cloneCallback: () => void = null) {
        let uiBase = this.currentNavItem().data.uiBase || routeToUIRole(this.currentNavItem().path);
        if (!await this.permissionService.hasPermission(uiBase + '_delete')) {
            deleteCallback = null;
        }
        if (!await this.permissionService.hasPermission(uiBase + '_create')) {
            cloneCallback = null;
        }
        return this.setMenu(MenuItemTop.InitDetailMenu(deleteCallback, cloneCallback));
    }

    private async getSaveCreateRights(): Promise<Array<boolean>> {
        let uiBase = this.currentNavItem().data.uiBase || routeToUIRole(this.currentNavItem().path);
        return [
            await this.permissionService.hasPermission(uiBase + '_write'),
            await this.permissionService.hasPermission(uiBase + '_create'),
        ];
    }
    // --------------- Navigation END ------------------------//

    public getLanguage(): string {
        return (!!this._currentLang) ? this._currentLang : 'cs';
    }

    public setLanguage(lang: string): void {
        let fireLangChangeEvent = this._currentLang !== lang;
        this._currentLang = lang;
        if (fireLangChangeEvent) {
            this.langChanged.emit({ lang });
        }
    }

    // public getRoles() {
    //     if (!!this.roles && this.roles.length > 0) {
    //         return this.roles;
    //     }

    //     let token = this.getToken();

    //     if (!token) {
    //         return [];
    //     }

    //     let decodedToken = jwtHelper.decodeToken(token);
    //     if (!!decodedToken.role) {
    //         this.roles = Array.isArray(decodedToken.role) ? decodedToken.role : [decodedToken.role];
    //         if (!!decodedToken.environment) {
    //             this.environment = decodedToken.environment;
    //         } else {
    //             this.environment = null;
    //         }
    //         return this.roles;
    //     }
    // }


    public getAppRoleIDs() {
        let token = this.getToken();
        if (!!token) {
           let deToken = jwtHelper.decodeToken(token);
            if (!!deToken.app_role_id) {
                let appRoles = Array.isArray(deToken.app_role_id) ? deToken.app_role_id : [deToken.app_role_id];
                return appRoles.map(ar => Number(ar));
            }
        }
        return [];
    }

    public getToken(): string {
        return sessionStorage.getItem('id_token');
    }

    public getAuthorizationHeaderValue() {
        return 'Bearer ' + this.getToken();
    }

    public getTenantID(): number {
        let token = this.getToken();
        let decodedToken = jwtHelper.decodeToken(token);
        if (!decodedToken) {
            return 0;
        }
        return decodedToken.TenantID;
    }

    public getTenantName(): string {
        let token = this.getToken();
        let decodedToken = jwtHelper.decodeToken(token);
        if (!decodedToken) {
            return '';
        }
        return decodedToken.TenantName;
    }

    private getSettings() {
        let settings = sessionStorage.getItem('settings');
        if (isNullOrWhitespace(settings)) {
            return null;
        }

        return JSON.parse(settings);
    }

    public getSettingValue( key: string ): string {
        const settings = this.getSettings();
        if (!settings) { return null; }
        const sv = settings.find(s => s.settingKey === key);
        if (!sv) {
            return null;
        }
        return sv.settingValue;
    }

    public getEcommerceLocationID(): number {
        let settings = this.getSettings();
        if (!settings) { return null; }
        return Number(settings.find(s => s.settingKey === 'eCommerceLocationId').settingValue);
    }

    public getApiURL(): string {
        let settings = this.getSettings();
        if (!settings) { return ''; }
        return settings.find(s => s.settingKey === 'ApiURL').settingValue;
    }

    public getEmailSenderName(): string {
        let settings = this.getSettings();
        if (!settings) { return ''; }
        return settings.find(s => s.settingKey === 'EmailSenderName').settingValue;
    }

    public getEmailSenderAddress(): string {
        let settings = this.getSettings();
        if (!settings) { return ''; }
        return settings.find(s => s.settingKey === 'EmailSenderAddress').settingValue;
    }

    public getCustomerMustBeApproved(): boolean {
        let settings = this.getSettings();
        if (!settings) { return false; }
        return settings.find(s => s.settingKey === 'CustomerMustBeApproved' && s.settingValue === 'true') ? true : false;
    }

    public get textEditorUploadUrl() {
        return this.getApiURL() +
            'api/TenantAdmin/editorImage/upload?TenantHash=' + this.getTenantHash();
    }

    public get getNoImageUID(): string {
        let settings = this.getSettings();
        return settings.find(s => s.settingKey === 'NoImageRecordUID').settingValue;
    }

    public get getSupportedLanguages(): string[] {
        let settings = this.getSettings();
        let languages = settings.find(s => s.settingKey === 'supportedLanguages').settingValue as string;
        if (!languages) { return [this.getLanguage()]; }
        return languages.split(',');
    }

    public getWebURL(): string {
        let settings = this.getSettings();
        if (!settings) { return ''; }
        return settings.find(s => s.settingKey === 'WebURL').settingValue;
    }

    public getHomePBIReportName(): string {
        let settings = this.getSettings();
        if (!settings) { return null; }
        let pbiSettings = settings.find(s => s.settingKey === 'homePBIReport');
        if (isNullOrWhitespace(pbiSettings)) {
            return null;
        }
        return pbiSettings.settingValue;
    }

    public getHelpUrl(): string {
        let settings = this.getSettings();
        if (!settings) { return ''; }

        let prefixSettings = settings.find(s => s.settingKey === 'helpPrefixURL');
        let prefixUrl = prefixSettings ? prefixSettings.settingValue : '//';
        let navSuffix = this.currentNavItem().path;

        if (navSuffix[0] === '/') {
            navSuffix = navSuffix.substr(1);
        }
        navSuffix = navSuffix.replace( /\/\d+/g, '').replace(/\//g, '-').replace(/--/g, '-');
        return prefixUrl + this.getLanguage() + '/' + navSuffix;
    }

    public getTemplateContact(): any {
        let settings = this.getSettings();
        if (!settings) { return {}; }
        let templateContactString = settings.find(s => s.settingKey === 'templateContact').settingValue;
        return JSON.parse(templateContactString);
    }

    public getTenantHash(): string {
        let settings = this.getSettings();
        return settings.find(s => s.settingKey === 'tenantHash').settingValue;
    }
    public numRequired(event) {
        console.log(event);
    }

    public setMultiloginTenants(tenants: any[]) {
        this.multiloginTenants = tenants;
        sessionStorage.setItem('multitenants', JSON.stringify(tenants));
    }

    public getMultiloginTenants(): any[] {
        if (this.multiloginTenants) {
            return this.multiloginTenants;
        }

        let s = sessionStorage.getItem('multitenants');
        if (s) {
            try {
                this.multiloginTenants = JSON.parse(s);
            } catch {
                this.multiloginTenants = [];
            }
        } else {
            this.multiloginTenants = [];
        }
        return this.multiloginTenants;
    }

    ngOnDestroy(): void {
        this.navigationEndSubscription.unsubscribe();
    }
}
