import { HttpMethod } from './http-method.enum';
import { RequestParam } from './request-param.model'

export class RequestParams {
    endpointRoute: string;
    method: HttpMethod;
    params: RequestParam[];
    constructor(endpointRoute: string, method: HttpMethod, params: RequestParam[]) {
        this.endpointRoute = endpointRoute;
        this.method = method;
        this.params = params;
    }
}
