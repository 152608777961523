import { Injectable }               from '@angular/core';
import { Globals }                  from '../common/globals';
import { HttpClient }               from '@angular/common/http';
import { Observable }               from 'rxjs';
import { CommonService }            from './common.service';
import { Queryable }                from '../common/report-grid/model/queryable.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class QueryableService {
    public readonly localPort = 8377;
    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals, snackbar: MatSnackBar) {
        this._commonService = new CommonService(http, globals, 'queryable', this.localPort, 'TenantReports', true);
        // the authorozatopm is removed by the interceptor when accessing different domain
        // this issue has no other solution then this one, the whitelisted domain property can't be used

        this._commonService.getCustomHeaders = () => {
            return { 'Authorization': globals.getAuthorizationHeaderValue() };
        };
    }

    public getQueryable(reportID: number): Observable<Queryable> {
        return this._commonService.get(reportID.toString());
    }

    public getQueryableByName(reportName: string): Observable<Queryable> {
        return this._commonService.get('byName/' + reportName);
    }

    public getQuerayblesList(): Observable<Queryable[]> {
        return this._commonService.get('');
    }

    public getQueryablesforCodeLists(): Observable<Queryable[]> {
        return this._commonService.get('forCodeLists');
    }
}
