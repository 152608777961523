import { Component, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid';

@Component({
  selector: 'hc-ag-grid-checkbox',
  template: `<input type="checkbox" [(ngModel)]="params.value" (change)="this.refresh(this.params)">`,
})
export class AgGridCheckboxComponent implements AgRendererComponent {

  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    params.data[params.column.colId] = params.value;
    params.api.refreshCells(params);
    return false;
  }
}