import { Pipe,
         PipeTransform }        from '@angular/core';
import { DomSanitizer,
         SafeHtml,
         SafeUrl,
         SafeResourceUrl,
         SafeScript }           from '@angular/platform-browser';
import { SecurityContext }      from '@angular/compiler/src/core';

@Pipe({
  // tslint:disable-next-line:pipe-naming
  name: 'safeUrl',
  pure: true // better UI performance
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}

@Pipe({
  // tslint:disable-next-line:pipe-naming
  name: 'safeHtml',
  pure: true // better UI performance
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Pipe({
  // tslint:disable-next-line:pipe-naming
  name: 'safeScript',
  pure: true // better UI performance
})
export class SafeScriptPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(value: string): SafeScript {
    return this.sanitizer.bypassSecurityTrustScript(value);
  }
}

@Pipe({
  // tslint:disable-next-line:pipe-naming
  name: 'safeResourceUrl',
  pure: true // better UI performance
})
export class SafeResourceUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
