import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MenuItem } from '../common/nav-item.model';
import { DeleteDialogComponent } from '../common/dialog.delete.component';
import { ErrorDialogComponent } from '../common/dialog.error.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'hc-action-row-save-options',
  templateUrl: './action-row-save-options.component.html',
  styleUrls: ['./action-row-save-options.component.scss']
})
export class ActionRowSaveOptionsComponent implements OnInit {

  @Input() items: MenuItem[];

  @Input() selectedIDs: any[];

  @ViewChild('childMenu', {static: true}) public childMenu;

  constructor(
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
  }

  public async menuItemClick(options: MenuItem) {
    if (options.confirmText == null) {
        options.callback();
        return;
    }

    let deleteDialogRef = this.dialog.open(DeleteDialogComponent, { data: { confirmText: options.confirmText } });
      let res = await deleteDialogRef.afterClosed().toPromise();
      if (!!res) {
        try {
          options.callback();
        } catch {
          this.dialog.open(ErrorDialogComponent, { data: { message: 'admin.web.delete-failed' } });
        }
      }
  }
}
