import { Injectable }               from '@angular/core';
import { Observable }               from 'rxjs';
import { map, share }                      from 'rxjs/operators';
import { Globals }                  from '../common/globals';
import { CommonService }            from './common.service';
import { HttpClient } from '@angular/common/http';


export class DocumentStorageFileMetadata {
  public fileName: string;
  public mimeType: string;
  public updateTime: Date;
  public recordUID: string;

  public isImage(): boolean {
    if (!this.mimeType) { return false; }
    return this.mimeType.indexOf('image') > -1;
  }

  public isPdf(): boolean {
    if (!this.mimeType) { return false; }
    return this.mimeType.indexOf('pdf') > -1;
  }

  public documentTypeClass(size = 32): string {

    let mimeImgFileName = '';
    if (!this.mimeType) { return 'thumb-' + size + '-unknown'; }

    if (this.mimeType.indexOf('bmp') > -1) {
      mimeImgFileName = 'thumb-' + size + '-bmp';
    } else if (this.mimeType.indexOf('jpg') > -1 || this.mimeType.indexOf('jpeg') > -1) {
      mimeImgFileName = 'thumb-' + size + '-jpg';
    } else if (this.mimeType.indexOf('png') > -1) {
      mimeImgFileName = 'thumb-' + size + '-png';
    } else if (this.mimeType.indexOf('text') > -1) {
      mimeImgFileName = 'thumb-' + size + '-text';
    } else if (this.mimeType.indexOf('pdf') > -1) {
      mimeImgFileName = 'thumb-' + size + '-pdf';
    } else if (this.mimeType.indexOf('msword') > -1 || this.mimeType.indexOf('ms-word') > -1 || this.mimeType.indexOf('wordprocessingml')) {
      mimeImgFileName = 'thumb-' + size + '-word';
    } else if (this.mimeType.indexOf('ms-excel') > -1 || this.mimeType.indexOf('spreadsheetml') > -1) {
      mimeImgFileName = 'thumb-' + size + '-excel';
    } else if (this.mimeType.indexOf('ms-powerpoint') > -1 || this.mimeType.indexOf('presentationml') > -1) {
      mimeImgFileName = 'thumb-' + size + '-powerpoint';
    } else {
      mimeImgFileName = 'thumb-' + size + '-unknown';
    }

    return mimeImgFileName;
  }

  public documentTypeThumb(): string {
    return this.documentTypeClass() + '.png';
  }

}

@Injectable()
export class DocumentStorageService {

  public progress: any;
  public progressObserver: any;

  private metadata: any;

  private _commonService: CommonService;

  constructor (
    public http: HttpClient,
    private globals: Globals) {

    this.progress = Observable.create(observer => {
      this.progressObserver = observer;
    }).pipe(share());

    this._commonService = new CommonService(http, globals, 'DocumentStorage');
  }

  public getMetadata(recordUid: string): Observable<DocumentStorageFileMetadata> {
    return this._commonService.get('metadata/' + recordUid).pipe(map(m => {
      if (!m) { return null; }
      let meta = new DocumentStorageFileMetadata();
      Object.assign(meta, m);
      return meta;
    }));
  }

  public metadataForRecords(recordUids: Array<string>): Observable<Array<DocumentStorageFileMetadata>> {
    return this._commonService.post('metadataForRecords', recordUids).pipe(map(m => {
      let result = new Array<DocumentStorageFileMetadata>();
      for (let i = 0, len = m.length; i < len; i++) {
        let meta = new DocumentStorageFileMetadata();
        Object.assign(meta, m[i]);
        result.push(meta);
      }
      return result;
    }));
  }

  public saveFile(cacheGuid: string): Observable<any> {
     return this._commonService.updateItem(cacheGuid, null);
  }

  public uploadFile(files: File[]): Observable<any> {
    return this._commonService.uploadFile('uploadfile', files);
  }
}

export class DbFile {
    recordUID: string;
    fileName: string;
    fileType: string;
}
