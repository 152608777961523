import { Pipe,
         PipeTransform }                  from '@angular/core';
import { TranslationService }             from './translation.service';

// tslint:disable-next-line:pipe-naming
@Pipe({ name: 'trans',
        // impure pipe - the pipe is called every time change detection runs (which is usually quite often).
        // pure: false // impure pipe, update value when we change language
        pure: true // better UI performance
})

export class TranslationPipe implements PipeTransform {
     constructor(
        private _translate: TranslationService
     ) {
     }

     transform(value: string, format?: string, fallback?: string): string {
        // tslint:disable:curly
        if (!value && !!fallback) return fallback;
        if (!value) return;
        let res = '';
        if (!!format) {
                res = this.formatString(this._translate.instant(value), format);
        } else {
                res = this._translate.instant(value);
                if (!res && !fallback) return value;
        }
        if (!!fallback && res === value) return fallback;
        return res;
     }

     private formatString(value: string, format: string): string {
        let rgx = /^(U|L)?((f|F)(\d*)(U|L))?$/;
        // format can be U or L (uppercase or lowercase)
        // with first letter modifier (f = first letter, F = first letters of all words)
        // followed by their number (default = 1) and their format.
        // U = all uppercase, UfL = first letter lowercase, all other uppercase,
        // FU = all first letters uppercase, rest unchanged, LF2U = all first two letters uppercase, rest lowercase
        // tslint:disable-next-line:curly
        if (!value || !format || !rgx.test(format)) return value;
        let match = rgx.exec(format);
        let caser = f => (f === 'U') ? s => s.toUpperCase() : (f === 'L') ? s => s.toLowerCase() : s => s;
        let count = (match[3]) ? parseInt(match[4] || '1', 10) : 0;
        let slicer = s => caser(match[5])(s.slice(0, count)) + caser(match[1])(s.slice(count));
        return ((match[3] === 'F') ? value.split(' ') : [value]).map(p => slicer(p)).join(' ');
     }
}
