import { Pipe, PipeTransform } from '@angular/core';

// tslint:disable-next-line:pipe-naming
@Pipe({ name: 'lastItem', pure: true })
export class LastItemPipe implements PipeTransform {
  transform(items: any[], enabled = true): any {
    if (enabled) { return items.slice(0, items.length - 1); }
    return items;
  }
}
