import { Component, Input, Optional, Host, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { SatPopover } from '@ncstate/sat-popover';
import { filter } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { AgEditorComponent } from 'ag-grid-angular';

@Component({
    selector: 'hc-inline-autocomplete',
    styleUrls: ['inline-autocomplete.component.scss'],
    templateUrl: 'inline-autocomplete.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineAutocompleteComponent implements OnInit, AgEditorComponent {
    @ViewChild('autoCompleteInput', {static: true}) inputElement: ElementRef<HTMLInputElement>;

    autoControl: FormControl = new FormControl();

    @Input()
    get value(): string { return this._value; }
    set value(x: string) {
        this.editVal = this._value = x;
    }
    private _value = '';

    @Input()
    public valueProperty: string;

    @Input()
    public displayFunction: Function = null;

    @Input()
    public dataSource: any;

    /** Form model for the input. */
    editVal = '';

    constructor(
        @Optional()
        @Host()
        private popover: SatPopover,
        private changeRef: ChangeDetectorRef) { }

    valueSelected($event) {
        this.value = $event.option.value;
        this.editVal = null; // an option has been selected cleanup the manually entered when exists
    }

    ngOnInit() {
        // subscribe to cancellations and reset form value
        if (this.popover) {
            this.popover.closed.pipe(filter(val => val == null))
                .subscribe(() => {
                    this.editVal = this.value || '';
                    this.changeRef.markForCheck();
                });
        }
        setTimeout( () => { this.inputElement.nativeElement.focus();
                            this.inputElement.nativeElement.select();
                          }, 10 );
    }

    public agInit(params) {
        this.value = params.getValueFunction(params.node.data);
        this.displayFunction = params.displayFunction;
        this.dataSource = params.dataSource;
    }

    public getValue(): any {
        if (this.editVal) {
            return this.editVal; // manually entered value
        }
        return this.value;
    }

    public isPopup(): boolean {
        return false;
    }
}
