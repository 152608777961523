import { Component,
         OnInit,
         OnDestroy,
         ViewChild,
         ViewEncapsulation,
         ChangeDetectorRef,
         Inject}                         from '@angular/core';
import { Router }                                   from '@angular/router';
import { ComponentType }                            from '../common/component-type';
import { ComponentFeature }                         from '../common/component-features';
import { Globals }                                  from '../common/globals';
import { MenuGroupDynamicEnum }                     from './menugroup-dynamic.enum';
import { Honeycomb }                                from '../shared/honeycomb-api/honeycomb-api';
import { enumToValues, enumToStrings }              from '../common/functions';
import { AuthService } from '../shared/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { HcLocalStorage } from '../common/local-storage';
import { BehaviorSubject, lastValueFrom, of, Subscription } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'hc-menu-dynamic',
  templateUrl: './menu-dynamic.component.html',
  styleUrls: ['./menu-dynamic.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuDynamicComponent implements OnInit, OnDestroy {


  public attributesOpened = false;
  public userOpen = false;
  public surveysOpen = false;
  public communicationOpen = false;
  public taskerOpen = false;
  public productsOpen = false;
  public plannerOpen = false;
  public ecommerceOpen = false;
  public statisticsOpen = false;
  public globalSettingsOpen = false;
  public adminOpen = false;
  public documentsOpen = false;
  public productsAndServicesOpened = false;
  public reportsAndOverviews = false;
  public componentsOpen = false;
  public discountsOpen = false;
  public analytics = false;
  isFuckingIE = false;
  settingsOpen = false;
  settingsCoreOpen = false;
  menuComponentsReady = false;
  menuComponents = [];
  loading = false;

  data: any = null;
  roles = null;
  uiRolesUpdateSubs: Subscription = null;

  attributeTypesValues = [];
  attributeTypesNames = [];
  attributeTypesEnum = Honeycomb.Tenant.Admin.IService.Enums.AttributeTypeEnum;

  public componentType = ComponentType;
  public componentFeature = ComponentFeature;
  public menuGroup = MenuGroupDynamicEnum;

  version$: BehaviorSubject<string> = null;

  @ViewChild('sidenav', {static: true}) sidenav: MatSidenav;

  constructor(
      private router: Router,
      private authService: AuthService,
      private hcLocalStorage: HcLocalStorage,
      @Inject('NamedConfigurationCaller') private namedConfigurationCaller: Honeycomb.Tenant.Admin.IService.NamedConfigurationsController,
      @Inject('ApiController') private apiController: Honeycomb.Tenant.Admin.IService.ApiController,
      private cd: ChangeDetectorRef,
      private permissionService: NgxPermissionsService,
      public globals: Globals
    ) {
    this.isFuckingIE = (navigator.appName === 'Microsoft Internet Explorer' ||
                            !!(navigator.userAgent.match(/Trident/) ||
                            navigator.userAgent.match(/rv:11/)));

    if (globals) {
      globals.toggleMenu.subscribe(() => {
        if (!!this.sidenav) {
          this.sidenav.opened = !this.globals.menuOpen;
        }
      },
      (err) => console.error(err));
    }

    if (!this.uiRolesUpdateSubs) {
      this.uiRolesUpdateSubs = this.authService.reloadMenu.subscribe(_ => {
        if (this.data === null) {
          this.loadMenu();
          this.loading = false;
        }
      });
    }
    this.loadMenu();
  }

  async loadMenu() {
    if (!!this.loading || !this.authService.loggedIn() || this.data !== null) {
      return;
    }
    this.loading = true;
    if (this.data === null) {
      this.data = await lastValueFrom(this.namedConfigurationCaller.GetAsObject('portal.menu'));
      this.cd.detectChanges();
    }
    this.loading = false;
  }

  async ngOnInit() {
    this.attributeTypesValues = enumToValues(Honeycomb.Tenant.Admin.IService.Enums.AttributeTypeEnum);
    this.attributeTypesNames = enumToStrings(Honeycomb.Tenant.Admin.IService.Enums.AttributeTypeEnum);

    if (this.version$ === null) {
      const assemblyInfo = await this.apiController.Version()
                                    // if something happen, don't crash whole app
                                    .pipe(catchError((err) => of({ assemblyVersion: '0.0.0.0' })))
                                  .toPromise(); 
      const printVersion = assemblyInfo.assemblyVersion;
      this.version$ = new BehaviorSubject(printVersion);
    }
  }

  ngOnDestroy(): void {
    this.uiRolesUpdateSubs.unsubscribe();
    this.uiRolesUpdateSubs = null;
  }

  public compareBitwise(feature: ComponentFeature, flag: ComponentFeature  ): boolean {
    // tslint:disable-next-line:no-bitwise
    return (feature & flag) !== 0;
  }
}
