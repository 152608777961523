import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { switchMap, debounceTime, tap, finalize } from 'rxjs/operators';
import { UserService } from 'src/app/shared/user.service';
import { UserListItem } from '../user.model';

@Component({
    selector: 'hc-user-suggester',
    templateUrl: './user-suggester-component.html',
    styleUrls: ['./user-suggester-component.scss']
})
export class UserSuggesterComponent implements OnInit {

    @Output() public onUserSelected: EventEmitter<UserListItem> = new EventEmitter();
    @Input() public label = 'admin.web.search';
    @Input() public clearAfterSelection = true;
    @Input() public display: string;

    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    foundUsers: UserListItem[] = [];
    searchForm: FormGroup;
    isLoading = false;

    constructor(private fb: FormBuilder,
                private service: UserService,
                private changeRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.searchForm = this.fb.group({
            searchInput: null
        });

        this.searchForm
            .get('searchInput')
            .valueChanges
            .pipe(
                debounceTime(100),
                tap(() => this.isLoading = true),
                switchMap(async value =>
                    this.foundUsers = await this.service.getSuggestions(value, 20)
                    .pipe(
                        finalize(() => {
                            this.isLoading = false;
                        }),
                    ).toPromise()
                    .catch(_ => { return []; })
                )
            )
            .subscribe(res => {
                this.foundUsers = res;
                this.changeRef.detectChanges();
            }, err => {
            }
            );
    }

    public valueSelected($event) {
        if (!$event.option) {
            return;
        }
        let user = <UserListItem>$event.option.value;
        if (this.onUserSelected) {
            this.onUserSelected.emit(user);
        }
        if (this.clearAfterSelection) {
            this.searchInput.nativeElement.value = '';
            this.searchInput.nativeElement.focus();
        } else {
            this.searchInput.nativeElement.value = $event.option.viewValue;
        }
    }

    public displayFn(user: UserListItem) {
        if (user) { return user.userNumber + ' - ' + user.name; }
    }
}
