import { GridDataSource } from 'src/app/common/report-grid/tools/report-grid.datasource';

export class ClubCreationDataModel {
    public rowIDs: any[];

    public createTempTable: boolean;
    public createTempTableDatasource: GridDataSource;

    public existingIDsTableName: string;
    public existingIDsColumnName: string;
    public existingIDSCount: number;

    public get membersCount(): number {
        if (this.rowIDs) {
            return this.rowIDs.length;
        } else if (this.existingIDSCount) {
            return this.existingIDSCount;
        }
        return null;
    }
}
