import { Injectable, Output, EventEmitter }               from '../../../node_modules/@angular/core';
import { CommonService }            from './common.service';
import { CodeList }                 from '../common/code-list.model';
import { CodeListService } from './code-list.service';
import { MediaService } from './media.service';
import { MediaModel } from '../common/media.model';
import { Code } from '../common/code.model';

@Injectable()
export class CodeLookupCacheService {

    private _commonService: CommonService;

    @Output() public cacheReady: EventEmitter<any> = new EventEmitter();

    private codeLists: CodeList[];
    private medias: MediaModel[];
    constructor(
        private codeListService: CodeListService,
        private mediaService: MediaService
    ) {
            this.codeListService.list(null).subscribe( r => {
                this.codeLists = r;
                this.readyEvent();
            });
            this.mediaService.getItems(null).subscribe( r => {
                this.medias = r;
                this.readyEvent();
            });
        }

        private readyEvent() {
            if (this.codeLists && this.medias) {
                this.cacheReady.emit();
            }
        }

        public getCodeListItems( codeListID: number ): Code[] {
            if (!this.codeLists) {
                return null;
            }
            let cd = this.codeLists.find( c => c.codeListId === codeListID);
            if (cd == null) {
                return null;
            }
            return cd.codes;
        }

        public getCodeListItemsHashmap( codeListID: number ): object {
            let items = this.getCodeListItems( codeListID );
            let res = {};
            items.forEach( c => res[c.codeKey] = c.codeValue);
            return res;
        }

        public getCodeListValue( codeListID: number, key: string ): any {
            let vals = this.getCodeListItems(codeListID);
            if (!vals) { return null; }
            const res = vals.find(v => v.codeKey === key);
            return (!!res) ? res.codeValue : null;
        }

        public getMediaList(): MediaModel[] {
            return this.medias;
        }

        public getMedia( mediaID: number ): MediaModel {
            if (!this.medias) {
                return null;
            }
            return this.medias.find( m => m.mediaID === mediaID );
        }

        public getMediaDescription( mediaID: number ): string {
            let m = this.getMedia( mediaID );
            if (m) {
                return m.description;
            }
            return null;
        }

        public getMediaForVoucherTemplate( voucherTemplateID: number ): MediaModel {
            if (!this.medias) {
                return null;
            }
            return this.medias.find( m => m.voucherTemplateID === voucherTemplateID );
        }

        public getMediaDescriptionForVoucherTemplate( voucherTemplateID: number ): string {
            let m = this.getMediaForVoucherTemplate( voucherTemplateID );
            if (m) {
                return m.description;
            }
            return null;
        }
}




