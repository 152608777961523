import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData, DatePipe } from '@angular/common';
import { TranslationPipe } from './shared/translation.pipe';
import localeCs from '@angular/common/locales/cs';
import localeEn from '@angular/common/locales/en';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToolbarComponent } from './common/toolbar/toolbar-components';
import { GridTemplateComponent } from './common/grid.template.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SavingDialogComponent } from './common/saving-dialog.component';
import { CanDeactivateGuard } from './common/can-deactivate.guard';
import { MatPaginatorCustom } from './shared/mat-paginator.custom';
import { ImportDialogComponent,
         ImportPromptDialogComponent } from './common/file-import.component';
import { ExportDialogComponent } from './common/file-export.component';
import { InfoDialogComponent } from './common/dialog.info.component';
import { PromptDialogComponent } from './common/dialog.prompt.component';
import { InlineCodeListComponent } from './common/inline-codelist.component';
import { InlineBoolComponent } from './common/inline-bool.component';
import { InlineUniversalComponent } from './common/inline-universal.component';
import { InlineNumberComponent } from './common/inline-number.component';
import { InlineEditComponent } from './common/inline-edit.component';
import { InlineSelectComponent } from './common/inline-select.component';
import { InlineTextareaComponent } from './common/inline-textarea.component';
import { HCurrencyPipe } from './shared/hcurrency.pipe';
import { KeepHtmlPipe } from './shared/keep-html.pipe';
import { LastItemPipe } from './shared/last-item.pipe';
import { HighlightPipe } from './shared/highlight.pipe';
import { SafeUrlPipe, SafeHtmlPipe, SafeScriptPipe, SafeResourceUrlPipe } from './shared/safe.pipe';
import { TemplatePickerComponent } from './setting/template/template-picker/template-picker.component';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FileUploadComponent } from './common/file-upload.component';
import { ExpandableTreeComponent } from './common/expandable-tree/expandable-tree.component';
import { TreeModule } from 'angular-tree-component';
import { UniversalListComponent } from './common/universal-list.component';
import { GalleryService } from './shared/gallery.service';
import { CategoryService } from './shared/category.service';
import { ConfigurationService } from './shared/configuration.service';
import { LocationService } from './shared/location.service';
import { AttachmentService } from './shared/attachment.service';
import { DocumentStorageService, DocumentStorageFileMetadata } from './shared/document-storage.service';
import { TemplateService } from './shared/template.service';
import { ClubService } from './shared/club.service';
import { HcValidatedNgModelDirective } from './shared/validated-ng-model.directive';
import { ProductsService } from './shared/products.service';
import { CodeListService } from './shared/code-list.service';
import { SurveyService } from './shared/survey.service';
import { UserDocumentService } from './shared/user-document.service';
import { OrderDocumentService } from './shared/order-document.service';
import { ContactService } from './shared/contact.service';
import { DeleteDialogComponent } from './common/dialog.delete.component';
import { ReportGridComponent } from './common/report-grid/report-grid.component';
import { FilterSelectorComponent } from './common/filter-selector/filter-selector.component';
import { AgGridModule } from 'ag-grid-angular';
import { ReportService } from './shared/report-service';
import { QueryableService } from './shared/queryable-service';
import { InputDialogComponent } from './common/dialog-input.component';
import { InterfacesMenuComponent } from './common/interfaces-menu/interfaces-menu.component';
import { AGGridResizeDirective } from './shared/ag-grid-resize-directive';
import { InlineAutocompleteComponent } from './common/inline-autocomplete.component';
import { NavigationOptionsComponent } from './navigation-options/navigation-options.component';
import { HcAngularEditorComponent } from './common/angular-editor/angular-editor.component';
import { HcAngularEditorToolbarComponent } from './common/angular-editor/angular-editor-toolbar.component';
import { AngularRichEditorComponent } from './common/angular-editor/angular-rich-editor.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ProductSuggesterComponent } from './common/product-suggester/product-suggester-component';
import { CreateClubDialogComponent } from './user/club/create-club-dialog.component';
import { CreateProductSetDialogComponent } from './product/product-set/creation/create-product-set-dialog.component';
import { ProductSetService } from './shared/product-set.service';
import { EmailDialogComponent } from './common/dialog.email.component';
import { PlaceholderSuggesterDirective } from './shared/placeholder-suggester.directive';
import { UserSuggesterComponent } from './common/user-suggester/user-suggester-component';
import { UserService } from './shared/user.service';
import { UniversalSuggesterComponent } from './common/universal-suggester/universal-suggester-component';
import { RichEditorDialogComponent } from './common/angular-editor/rich-editor-dialog.component';
import { SegmentService } from './shared/segment-service';
import { PrintDialogComponent } from './common/dialog.print.component';
import { InlineDatepickerComponent } from './common/inline-datepicker.component';
import { ErrorDialogComponent } from './common/dialog.error.component';
import { DateRangeSelectorComponent } from './common/date-range-selector.component';
import { GridTypedEditorComponent } from './common/grid-typed-editor/grid-typed-editor.component';
import { GridTypedRendererComponent } from './common/grid-typed-editor/grid-typed-renderer.component';
import { CalendarComponent } from './common/calendar/calendar.component';
import { CodeLookupCacheService } from './shared/code-lookup-cache-service';
import { HttpClient } from '@angular/common/http';
import { Globals } from './common/globals';
import { ApiCaller } from './shared/honeycomb-api/api-caller';
import { EnumToArrayPipe } from './shared/enum-to-array.pipe';
import { Honeycomb } from './shared/honeycomb-api/honeycomb-api';
import { AgGridCheckboxComponent } from './shared/ag-grid-checkbox.component';
import { MultilangInputComponent } from './common/multilang-input/multilang-input.component';
import { MultilangInputDialogComponent } from './common/multilang-input/multilang-input-dialog.component';
import { MultilangInputEditorComponent } from './common/multilang-input/multilang-input-editor.component';
import { MultilangInputRendererComponent } from './common/multilang-input/multilang-input-renderer.component';
import { FilteredTreeComponent } from './common/filtered-tree/filtered-tree.component';
import { ConfirmDialogComponent } from './common/dialog.confirm.component';
import { AppDateAdapter, APP_DATE_FORMATS } from './common/date-picker-adatapter';

import { MultiLangComponent } from './common/multi-lang/multi-lang.component';
import { MultiLangDialogComponent } from './common/multi-lang/multi-lang-dialog.component';
import { MultiLangEditorComponent } from './common/multi-lang/multi-lang-editor.component';
import { UserJobSuggesterComponent } from './common/user-job-suggester/user-job-suggester-component';
import { GridMultiselectEditorComponent } from './common/grid-multiselect/grid-multiselect-editor.component';
import { FromUtcPipe } from './shared/from-utc.pipe';
import { Platform } from '@angular/cdk/platform';

registerLocaleData(localeCs, localeEn);

@NgModule({
    declarations: [
        // Global Pipes
        TranslationPipe,
        HCurrencyPipe,
        KeepHtmlPipe,
        LastItemPipe,
        HighlightPipe,
        FromUtcPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        SafeScriptPipe,
        SafeResourceUrlPipe,
        EnumToArrayPipe,
        // Global UI components
        ToolbarComponent,
        GridTemplateComponent,
        InlineCodeListComponent,
        InlineBoolComponent,
        InlineUniversalComponent,
        InlineNumberComponent,
        InlineEditComponent,
        InlineSelectComponent,
        InlineTextareaComponent,
        FileUploadComponent,
        ExpandableTreeComponent,
        FilteredTreeComponent,
        UniversalListComponent,
        InterfacesMenuComponent,
        InlineAutocompleteComponent,
        InlineDatepickerComponent,
        NavigationOptionsComponent,
        HcAngularEditorComponent,
        HcAngularEditorToolbarComponent,
        AngularRichEditorComponent,
        ProductSuggesterComponent,
        CreateClubDialogComponent,
        CreateProductSetDialogComponent,
        UserSuggesterComponent,
        UserJobSuggesterComponent,
        UniversalSuggesterComponent,
        DateRangeSelectorComponent,
        CalendarComponent,
        MultilangInputComponent,
        MultiLangComponent,
        // Dialogs
        SavingDialogComponent,
        ImportDialogComponent,
        ImportPromptDialogComponent,
        ExportDialogComponent,
        InfoDialogComponent,
        ConfirmDialogComponent,
        EmailDialogComponent,
        InputDialogComponent,
        PrintDialogComponent,
        PromptDialogComponent,
        TemplatePickerComponent,
        DeleteDialogComponent,
        RichEditorDialogComponent,
        ErrorDialogComponent,
        MultilangInputDialogComponent,
        MultiLangDialogComponent,
        // Directives
        HcValidatedNgModelDirective,
        AGGridResizeDirective,
        PlaceholderSuggesterDirective,
        MultilangInputEditorComponent,
        MultilangInputRendererComponent,
        MultiLangEditorComponent,
        // Report grid
        ReportGridComponent,
        GridTypedEditorComponent,
        GridTypedRendererComponent,
        AgGridCheckboxComponent,
        GridMultiselectEditorComponent,
        // Filter selector
        FilterSelectorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // Material
        MatInputModule,
        MatSlideToggleModule,
        MatIconModule,
        MatBadgeModule,
        MatToolbarModule,
        MatSelectModule,
        MatMenuModule,
        FlexLayoutModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatCardModule,
        MatSidenavModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatGridListModule,
        MatListModule,
        MatCheckboxModule,
        MatTableModule,
        MatDatepickerModule,
        MatTabsModule,
        MatRadioModule,
        MatExpansionModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatSortModule,
        MatChipsModule,
        NgxPaginationModule,
        SatPopoverModule,
        NgxPermissionsModule,
        TreeModule,
        ColorPickerModule,
        AgGridModule.withComponents([])
    ],
    exports: [
        CommonModule,
        ToolbarComponent,
        GridTemplateComponent,
        GridMultiselectEditorComponent,
        ReportGridComponent,
        GridTypedEditorComponent,
        GridTypedRendererComponent,
        FilterSelectorComponent,
        FormsModule,
        ReactiveFormsModule,
        MultilangInputEditorComponent,
        MultilangInputRendererComponent,
        MultiLangEditorComponent,
        // Global UI components
        InlineCodeListComponent,
        InlineBoolComponent,
        InlineUniversalComponent,
        InlineNumberComponent,
        InlineEditComponent,
        InlineSelectComponent,
        InlineTextareaComponent,
        FileUploadComponent,
        ExpandableTreeComponent,
        FilteredTreeComponent,
        UniversalListComponent,
        InterfacesMenuComponent,
        InlineAutocompleteComponent,
        InlineDatepickerComponent,
        NavigationOptionsComponent,
        HcAngularEditorComponent,
        HcAngularEditorToolbarComponent,
        AngularRichEditorComponent,
        ProductSuggesterComponent,
        UserSuggesterComponent,
        UserJobSuggesterComponent,
        UniversalSuggesterComponent,
        DateRangeSelectorComponent,
        CalendarComponent,
        MultilangInputComponent,
        MultiLangComponent,
        // Dialogs
        SavingDialogComponent,
        ImportDialogComponent,
        ImportPromptDialogComponent,
        ExportDialogComponent,
        InfoDialogComponent,
        ConfirmDialogComponent,
        EmailDialogComponent,
        InputDialogComponent,
        PrintDialogComponent,
        PromptDialogComponent,
        TemplatePickerComponent,
        DeleteDialogComponent,
        RichEditorDialogComponent,
        ErrorDialogComponent,
        MultilangInputDialogComponent,
        MultiLangDialogComponent,
        // Pipes
        TranslationPipe,
        HCurrencyPipe,
        KeepHtmlPipe,
        LastItemPipe,
        HighlightPipe,
        FromUtcPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        SafeScriptPipe,
        SafeResourceUrlPipe,
        EnumToArrayPipe,
        // Material
        MatInputModule,
        MatSlideToggleModule,
        MatIconModule,
        MatBadgeModule,
        MatToolbarModule,
        MatSelectModule,
        MatMenuModule,
        FlexLayoutModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatCardModule,
        MatSidenavModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatSnackBarModule,
        MatGridListModule,
        MatListModule,
        MatCheckboxModule,
        MatTableModule,
        MatDatepickerModule,
        MatTabsModule,
        MatRadioModule,
        MatExpansionModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatSortModule,
        MatChipsModule,
        // Directives
        HcValidatedNgModelDirective,
        AGGridResizeDirective,
        PlaceholderSuggesterDirective,
        ColorPickerModule,
        NgxPaginationModule,
        SatPopoverModule,
        NgxPermissionsModule,
        TreeModule
    ],
    providers: [
        GalleryService,
        CategoryService,
        ConfigurationService,
        LocationService,
        DocumentStorageService,
        TemplateService,
        ClubService,
        DocumentStorageFileMetadata,
        UserDocumentService,
        OrderDocumentService,
        ContactService,
        AttachmentService,
        CodeLookupCacheService,
        // Filter services
        ProductsService,
        CodeListService,
        SurveyService,
        UserService,
        // Reports
        DatePipe,
        ReportService,
        QueryableService,
        SegmentService,
        TranslationPipe,
        ProductSetService,
        {
            provide: ApiCaller,
            useFactory: (httpClient: HttpClient, globals: Globals) => (new ApiCaller(httpClient, globals)),
            deps: [HttpClient, Globals]
        },
        {
            provide: 'PBIReportController',
            useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Reports.IService.PBIController(apiCaller)),
            deps: [ApiCaller]
        },
        {
            provide: 'NamedConfigurationCaller',
            useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Admin.IService.NamedConfigurationsController(apiCaller)),
            deps: [ApiCaller]
        },
        {
            provide: 'UserController',
            useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Admin.IService.UserController(apiCaller)),
            deps: [ApiCaller]
        },
        {
            provide: 'TenantLocalizationController',
            useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.LookupTables.IService.TenantLocalizationController(apiCaller)),
            deps: [ApiCaller]
        },
        {
            provide: 'SettingController',
            useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Admin.IService.SettingController(apiCaller)),
            deps: [ApiCaller]
        },
        {
            provide: 'NotificationController',
            useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Tasker.IService.Controller.NotificationController(apiCaller)),
            deps: [ApiCaller]
        },
        {
            provide: 'ApiController',
            useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Admin.IService.ApiController(apiCaller)),
            deps: [ApiCaller]
        },
        { provide: MAT_DATE_LOCALE, useValue: 'cs-CZ' },
        { provide: DateAdapter, useClass: AppDateAdapter, deps: [MAT_DATE_LOCALE, Platform] },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [ // services that you want to share across modules
        CanDeactivateGuard,
        TranslationPipe,
        HCurrencyPipe,
        KeepHtmlPipe,
        LastItemPipe,
        HighlightPipe,
        FromUtcPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        SafeScriptPipe,
        SafeResourceUrlPipe,
        { provide: LOCALE_ID, useValue: 'cs-CZ' },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'} },
        { provide: MatPaginatorIntl, useClass: MatPaginatorCustom},
        ApiCaller,
        EnumToArrayPipe,

        { provide: MAT_DATE_LOCALE, useValue: 'cs-CZ' },
        { provide: DateAdapter, useClass: AppDateAdapter, deps: [MAT_DATE_LOCALE, Platform] },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
      ]
    };
  }
}
