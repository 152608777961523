import { Component,
         ChangeDetectorRef,
         OnDestroy,
         ViewEncapsulation,
         AfterViewInit}                             from '@angular/core';
import { Globals }                                  from '../common/globals';
import { Subscription }                             from 'rxjs';
import { HelpDialogComponent }                      from '../common/help-dialog/help-dialog.component';
import { MenuItem, MenuItemTop }                                 from '../common/nav-item.model';
import { PortalAgenda } from '../interface/interface.model';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'hc-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavigationComponent implements OnDestroy, AfterViewInit {

  updateBreadcrumbSubscriber: Subscription;
  navEndSubscriber: Subscription;

  public portalAgenda = PortalAgenda;

  public topMenuItem: MenuItemTop = new MenuItemTop();

  constructor(
      private dialog: MatDialog,
      private cd: ChangeDetectorRef,
      public globals: Globals
    ) {
      this.updateBreadcrumbSubscriber = this.globals.updatedBreadcrumb.subscribe(() => {
        if (!this.cd['destroyed']) {
          this.cd.detectChanges();
        }
      },
      (err) => console.error(err));

      this.navEndSubscriber = this.globals.optionsMenuUpdated.subscribe((topMenuItem: MenuItemTop) => {
        this.topMenuItem = topMenuItem;
        if (!this.cd['destroyed']) {
          this.cd.detectChanges();
        }
      });
  }

  public menuItemClick(options: MenuItem) {
    options.callback();
  }

  openHelpDialog() {
    let helpUri = this.globals.getHelpUrl();
    this.dialog.open(HelpDialogComponent, { data: helpUri });
  }

  ngAfterViewInit(): void {
    if (!this.cd['destroyed']) { this.cd.detectChanges(); }
  }

  ngOnDestroy(): void {
    this.updateBreadcrumbSubscriber.unsubscribe();
    this.navEndSubscriber.unsubscribe();
  }
}
