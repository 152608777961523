import { Component,
         OnInit,
         OnDestroy,
         ViewChild,
         ViewEncapsulation}                                from '@angular/core';
import { Router }                                   from '@angular/router';
import { ComponentService }                         from '../shared/component.service';
import { ComponentType }                            from '../common/component-type';
import { ComponentFeature }                         from '../common/component-features';
import { Globals }                                  from '../common/globals';
import { MenuGroupEnum }                            from './menugroup.enum';
import { Honeycomb } from '../shared/honeycomb-api/honeycomb-api';
import { enumToValues, enumToStrings } from '../common/functions';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'hc-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit, OnDestroy {

  public attributesOpened = false;
  public userOpen = false;
  public surveysOpen = false;
  public communicationOpen = false;
  public taskerOpen = false;
  public productsOpen = false;
  public plannerOpen = false;
  public ecommerceOpen = false;
  public statisticsOpen = false;
  public globalSettingsOpen = false;
  public adminOpen = false;
  public documentsOpen = false;
  public productsAndServicesOpened = false;
  public reportsAndOverviews = false;
  public componentsOpen = false;
  public discountsOpen = false;
  public analytics = false;
  isFuckingIE = false;
  settingsOpen = false;
  settingsCoreOpen = false;
  menuComponentsReady = false;
  menuComponents = [];

  attributeTypesValues = [];
  attributeTypesNames = [];
  attributeTypesEnum = Honeycomb.Tenant.Admin.IService.Enums.AttributeTypeEnum;

  public componentType = ComponentType;
  public componentFeature = ComponentFeature;
  public menuGroup = MenuGroupEnum;

  @ViewChild('sidenav', {static: true}) sidenav: MatSidenav;

  constructor(
      private router: Router,
      private componentService: ComponentService,
      public globals: Globals
    ) {
    this.isFuckingIE = (navigator.appName === 'Microsoft Internet Explorer' ||
                            !!(navigator.userAgent.match(/Trident/) ||
                            navigator.userAgent.match(/rv:11/)));
    // this.loadMenu();

    globals.updateMenu.subscribe(() => {
      this.loadMenu();
    },
    (err) => console.error(err));

    globals.toggleMenu.subscribe(() => {
      this.sidenav.opened = !this.globals.menuOpen;
    },
    (err) => console.error(err));
  }

  loadMenu() {
    this.componentService.getMenu().subscribe(data => {
      this.menuComponents = data;
      let currentUrl = this.router.url;
      if ((currentUrl.indexOf('setting') > -1) && (currentUrl.indexOf('setting-core') === -1)) {
        this.settingsOpen = true;
      }
      if (this.settingsCoreOpen = currentUrl.indexOf('setting-core') > -1) {
        this.settingsCoreOpen = true;
      }
    },
    (err) => console.error(err));
  }

  ngOnInit() {
    this.attributeTypesValues = enumToValues(Honeycomb.Tenant.Admin.IService.Enums.AttributeTypeEnum);
    this.attributeTypesNames = enumToStrings(Honeycomb.Tenant.Admin.IService.Enums.AttributeTypeEnum);
  }


  ngOnDestroy(): void {
  }

  public compareBitwise(feature: ComponentFeature, flag: ComponentFeature  ): boolean {
    // tslint:disable-next-line:no-bitwise
    return (feature & flag) !== 0;
  }

  setSubMenu() {
  }
}
