import { Injectable }               from '@angular/core';
import { CommonService }            from './common.service';
import { HttpClient } from '@angular/common/http';
import { Globals }                  from '../common/globals';
import { ISortableFilter }          from '../common/sortable-filter';
import { Observable }               from 'rxjs';
import { ImportFileMetaData }       from '../common/import-file-metadata.model';
import { ProductModel, ProductListItem, ProductAttributeDistinctValues, SetRelationParentProductsRequest } from '../common/product.model';
import { ExportRequestModel } from '../common/export-request-model.model';
import { share } from 'rxjs/operators';

@Injectable()
export class ProductsService {

    private _commonService: CommonService;
    public progress: any;
    public progressObserver: any;
    private fileOptTimeout = 120000;

    constructor(
        http: HttpClient,
        globals: Globals) {
        this._commonService = new CommonService(http, globals, 'product');
        this.progress = Observable.create(observer => {
            this.progressObserver = observer;
          }).pipe(share());
    }

    public getItems(filter: ISortableFilter): Observable<any> {
        return this._commonService.post('select', filter);
    }

    public updateItem(id: string, item: ProductModel): Observable<any> {
      return this._commonService.updateItem(id, item);
    }

    public insertItem(item: ProductModel): Observable<any> {
      return this._commonService.insertItem(item);
    }

    public getDetail(id: number): Observable<ProductModel> {
        return this._commonService.get('detail/' + id);
    }

    public getCategories(rootCode: string) {
        return this._commonService.get('categories/' + rootCode);
    }

    public getAllCategories() {
        return this._commonService.get('AllCategories');
    }

    public getAttributeTypes() {
        return this._commonService.get('attributeTypes');
    }

    public getProductVariables() {
        return this._commonService.get('variables');
    }

    public updateCategories(model) {
        return this._commonService.post('updateCategories', model);
    }

    public getVats() {
        return this._commonService.get('vats');
    }

    public getCompanies() {
        return this._commonService.get('companies');
    }

    public getRootRelevantSettings(id: number, keys: string[]) {
        return this._commonService.post('rootrelevant/' + id, keys);
    }

    public getNumber(serie) {
        return this._commonService.post('getNumber', serie);
    }

    public productsFullByIDs(productIDs: Array<number>): Observable<Array<ProductModel>> {
        return this._commonService.post('productsFullByIDs', productIDs);
    }

    public getPdf(id: number, type: string, desiredName: string): Observable<any> {
        return this._commonService.get('pdf/' + id + '?type=' + type + '&desiredName=' + desiredName);
    }

    public select(filter: ISortableFilter): Observable<any> {
        return this._commonService.post('select', filter);
    }

    public search(filter: ISortableFilter): Observable<any> {
        return this._commonService.post('select', filter);
    }

    public uploadFile(url: string, files: File[], metaData: ImportFileMetaData) {
        return this._commonService.uploadFile(url, files, null, null, this.fileOptTimeout);
    }

    public export(url: string, metaData: ExportRequestModel) {
        return this._commonService.post(url, metaData, this.fileOptTimeout);
    }

    public getSuggestions(search: string, maxCount: number): Observable<ProductListItem[]> {
        return this._commonService.get('suggest/' + search +  '?maxCount=' + maxCount.toString());
    }

    public getFilterAttributeDistinctValues(): Observable<ProductAttributeDistinctValues[]> {
        return this._commonService.get('selectProductFillterAttributesDistinctValues');
    }

    public getRelationParentProducts(productId: number): Observable<ProductModel[]> {
        return this._commonService.get('getRelationParentProducts/' + productId);
    }

    public setRelationParentProducts(request: SetRelationParentProductsRequest): Observable<any> {
        return this._commonService.post('setRelationParentProducts', request);
    }
}
