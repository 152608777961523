import { ReportPrintFormat } from "./report-print-format.enum";
import { ReportPrintOrientation } from "./report-print-orientation.enum";

export class ReportPrintSettings {
    pageFormat : ReportPrintFormat;
    pageOrientation : ReportPrintOrientation;
    isPreview : boolean;

    public constructor() {
        this.pageFormat = ReportPrintFormat.A4;
        this.pageOrientation = ReportPrintOrientation.Portrait;
        this.isPreview = false;
    }
}
