import { Injectable }               from '@angular/core';
import { ISortableFilter }          from '../common/sortable-filter';
import { Globals }                  from '../common/globals';
import { HttpClient }               from '@angular/common/http';
import { Observable }               from 'rxjs';
import { CommonService }            from './common.service';
import { IBaseService }             from './service.interface';
import { CategoryModel }            from '../product/category/category.model';
import { ExportRequestModel }       from '../common/export-request-model.model';
import { ImportFileMetaData }       from '../common/import-file-metadata.model';
import { toKeyValuePairs }          from '../common/functions';

@Injectable()
export class CategoryService implements IBaseService<CategoryModel> {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'category');
    }

    public select(filter: ISortableFilter): Observable<any> {
        return this._commonService.post('select', filter);
    }

    public getDetail(id: string): Observable<CategoryModel> {
        return this._commonService.get('detail/' + id);
    }

    public getCategories(rootId): Observable<any> {
        return this._commonService.get('list/' + rootId);
    }

    public getByLevel(level): Observable<any> {
        return this._commonService.get('byLevel/' + level);
    }

    public getForeignCategories(): Observable<any> {
        return this._commonService.get('foreignCategories');
    }

    public updateCategories(model, id) {
        return this._commonService.post('updateCategories/' + id, model);
    }

    public insertCategories(model) {
        return this._commonService.post('insertCategories', model);
    }

    public insertItem(item: CategoryModel): Observable<any> {
        return this._commonService.insertItem(item);
    }

    public updateItem(id: string, item: CategoryModel): Observable<any> {
       return this._commonService.updateItem(id, item);
    }

    public deleteItem(id: string): Observable<any> {
        return this._commonService.deleteItem(id);
    }

    public uploadFile(url: string, files: File[], metaData: ImportFileMetaData): Observable<any> {
        let postParams = toKeyValuePairs(metaData);
        return this._commonService.uploadFile(url, files, postParams);
    }

    public export(url: string, metaData: ExportRequestModel) {
        return this._commonService.post(url, metaData);
      }
}
