import { Injectable }               from '@angular/core';
import { ISortableFilter }          from '../common/sortable-filter';
import { Globals }                  from '../common/globals';
import { HttpClient }               from '@angular/common/http';
import { Observable }               from 'rxjs';
import { CommonService }            from './common.service';
import { UserModel,
         UserListItem,
         OptionType}             from '../common/user.model';
import { IBaseService }             from './service.interface';
import { ImportFileMetaData }       from '../common/import-file-metadata.model';
import { toKeyValuePairs }          from '../common/functions';
import { ExportRequestModel }       from '../common/export-request-model.model';
import { ContactModel }             from '../common/contact.model';
import { ElasticFilterRequest }     from '../common/elastic-filter-request.model';

@Injectable()
export class UserService implements IBaseService<UserModel> {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'user');
    }

    public getItems(filter: ISortableFilter): Observable<any> {
        return this._commonService.post('select', filter);
    }

    public select(filter: ISortableFilter): Observable<any> {
        return this._commonService.post('select', filter);
    }

    public getDetail(id: string): Observable<UserModel> {
        return this._commonService.get('detail/' + id);
    }

    public getCollection(id: number, collecitonName: string): Observable<any> {
      return this._commonService.get(id + '/collection/' + collecitonName);
    }

    public getHistory(id: number): Observable<any> {
      return this._commonService.get('userHistory/' + id); //  + '?userNumber=' + userNumber );
    }

    public getAttributeTypes() {
      return this._commonService.get('attributeTypes');
    }

    public insertItem(item: UserModel): Observable<any> {
        return this._commonService.insertItem(item);
    }

    public updateItem(id: string, item: UserModel): Observable<any> {
       return this._commonService.updateItem(id, item);
    }

    public deleteItem(id: string): Observable<any> {
        return this._commonService.deleteItem(id);
    }

    public uploadFile(url: string, files: File[], metaData: ImportFileMetaData) {
      let postParams = toKeyValuePairs(metaData);
      return this._commonService.uploadFile(url, files, postParams);
    }

    public export(url: string, metaData: ExportRequestModel) {
      return this._commonService.post(url, metaData);
    }

    public newUser(item: ContactModel): Observable<any> {
        return this._commonService.post('new', item);
    }

    public filter(filter: ElasticFilterRequest): Observable<any> {
      return this._commonService.post('filter', filter);
    }

    public getSuggestions(search: string, maxCount: number): Observable<UserListItem[]> {
      return this._commonService.get('suggest/' + search +  '?maxCount=' + maxCount.toString());
    }

    public changeLogin(userId: Number, newLogin: string): Observable<void> {
      return this._commonService.post('ChangeLoginAdmin' + '?userId='
                                       + userId.toString() + '&newLogin=' + encodeURIComponent( newLogin ), null);
    }

    public deleteContact(contactId: number): Observable<void> {
      return this._commonService.delete('deleteContact/' + contactId.toString());
    }

    public getOptionTypes(): Observable<OptionType[]> {
      return this._commonService.get('optionTypes');
    }

}
