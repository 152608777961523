import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { Globals } from '../common/globals';
import { CommonService } from './common.service';
import { HttpClient } from '@angular/common/http';


export class AttachmentMetadata {
    public fileName: string;
    public mimeType: string;
    public productAttachmentUID: string;

    public attachmentName: string;
    public attachmentDescription: string;
    public displayOrder: number;

    public created: Date;
    public updateTime: Date;

    public isImage(): boolean {
        if (!this.mimeType) { return false; }
        return this.mimeType.indexOf('image') > -1;
    }

    public isPdf(): boolean {
        if (!this.mimeType) { return false; }
        return this.mimeType.indexOf('pdf') > -1;
    }
}

@Injectable()
export class AttachmentService {

    public progress: any;
    public progressObserver: any;

    private metadata: any;

    private _commonService: CommonService;

    constructor(
        public http: HttpClient,
        private globals: Globals) {

        this.progress = Observable.create(observer => {
            this.progressObserver = observer;
        }).pipe(share());

        this._commonService = new CommonService(http, globals, 'DocumentStorage');
    }

    public getMetadata(recordUid: string): Observable<AttachmentMetadata> {
        return this._commonService.get('metadata/' + recordUid).pipe(map(m => {
            if (!m) { return null; }
            let meta = new AttachmentMetadata();
            Object.assign(meta, m);
            return meta;
        }));
    }

    public metadataForRecords(recordUids: Array<string>): Observable<Array<AttachmentMetadata>> {
        return this._commonService.post('metadataForRecords', recordUids).pipe(map(m => {
            let result = new Array<AttachmentMetadata>();
            for (let i = 0, len = m.length; i < len; i++) {
                let meta = new AttachmentMetadata();
                Object.assign(meta, m[i]);
                result.push(meta);
            }
            return result;
        }));
    }

    public saveFile(cacheGuid: string): Observable<any> {
        return this._commonService.updateItem(cacheGuid, null);
    }

    public uploadFile(files: File[]): Observable<any> {
        return this._commonService.uploadFile('uploadfile', files);
    }
}
