export interface CustomClass {
  name: string;
  class: string;
  tag?: string;
}

export interface Font {
  name: string;
  class: string;
  id: number;
}

export interface AngularEditorConfig {
  editable?: boolean;
  spellcheck?: boolean;
  height?: 'auto' | string;
  minHeight?: '0' | string;
  maxHeight?: 'auto' | string;
  width?: 'auto' | string;
  minWidth?: '0' | string;
  translate?: 'yes' | 'now' | string;
  enableToolbar?: boolean;
  showToolbar?: boolean;
  placeholder?: string;
  defaultParagraphSeparator?: string;
  defaultFontName?: string;
  defaultFontSize?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | string;
  // defaultFontSize?: '4pt' | '5pt' | '6pt' | '7pt' | '8pt' | '9pt' | '10pt' | '12pt' | '14pt' | '18pt' | '24pt' | '36pt' | string;
  uploadUrl?: string;
  fonts?: Font[];
  customClasses?: CustomClass[];
  verify_html?: boolean;
}

export const angularEditorConfig: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: 'auto',
  minHeight: '0',
  maxHeight: 'auto',
  width: 'auto',
  minWidth: '0',
  translate: 'yes',
  enableToolbar: true,
  showToolbar: true,
  placeholder: 'Enter text here...',
  defaultParagraphSeparator: '',
  defaultFontName: 'Arial',
  defaultFontSize: '3',
  fonts: [
    {class: '', name: 'Not set', id: 0},
    {class: 'arial', name: 'Arial', id: 1},
    {class: 'times-new-roman', name: 'Times New Roman', id: 2},
  ],
  uploadUrl: 'v1/image',
  verify_html: false,
};
