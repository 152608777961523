import { Component, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PrintDialogContent } from './dialog.print.content.model';
import { ReportService } from '../shared/report-service';
import { Globals } from '../common/globals';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { ReportPrintFormat } from './report-grid/model/report-print-format.enum';
import { ReportPrintOrientation } from './report-grid/model/report-print-orientation.enum';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * @title Dialog Overview
 */
@Component({
  selector: 'hc-print-dialog',
  templateUrl: './dialog.print.component.html'
})

export class PrintDialogComponent {

    public pageFormats = ReportPrintFormat;
    public pageOrientations = ReportPrintOrientation;
    public pageFormatKeys: Array<Number>;
    public pageOrientationKeys: Array<Number>;
    public previewUrl: SafeResourceUrl;
    public loadingPreview: boolean;
    public errorMessage: string;
    public isError: boolean;

    constructor(
        public reportService: ReportService,
        public sanitizer: DomSanitizer,
        public dialogRef: MatDialogRef<PrintDialogComponent>,
        public globals: Globals,
        @Inject(MAT_DIALOG_DATA) public data: PrintDialogContent
    ) {
        this.pageFormatKeys = new Array<Number>();
        for (let key of Object.keys(ReportPrintFormat).filter(Number)) {
            this.pageFormatKeys.push(Number(key));
        }
        this.pageOrientationKeys = new Array<Number>();
        for (let key of Object.keys(ReportPrintOrientation).filter(Number)) {
            this.pageOrientationKeys.push(Number(key));
        }
        this.loadingPreview = true;
        this.loadPrintPreview();
    }

    getOrientationDisplayValue(value: string): string {
        return 'admin.web.print.orientation.' + value.toLowerCase();
    }

    loadPrintPreview(): void {
        this.loadingPreview = true;
        this.errorMessage = '';
        this.isError = false;
        this.reportService.printReport(this.data.reportContext).subscribe( r => {
            let url = this.reportService.getUrlPrefix() + 'downloadPrintedPreview/' + r.token + '?TenantID=' + this.globals.getTenantID();
            this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            this.loadingPreview = false;
        }, e => {
            this.loadingPreview = false;
            this.errorMessage = e;
            this.isError = true;
        });
    }

    clickOk(): void {
        this.dialogRef.close(true);
    }
    clickCancel(): void {
        this.dialogRef.close(false);
    }
}
