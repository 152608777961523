import { Component, Input, Optional, Host, OnInit, ChangeDetectorRef,
         ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { SatPopover } from '@ncstate/sat-popover';
import { filter } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { AgEditorComponent, ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams, GridOptions } from '@ag-grid-enterprise/all-modules';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
    selector: 'hc-inline-datepicker',
    styleUrls: ['inline-datepicker.component.scss'],
    templateUrl: 'inline-datepicker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineDatepickerComponent implements  ICellEditorAngularComp, AfterViewInit, OnInit {
    columnWidth: string;
    params: ICellEditorParams;
    @Input()
    public value: string;
    @ViewChild('container', {read: MatDatepicker, static: true}) picker: MatDatepicker<Date>;

    constructor() {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {

    }

    isPopup(): boolean {
        return false;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isCancelAfterEnd(): boolean {
        return false;
    }

    agInit(params: any): void {
        this.params = params;
        this.value = params.value;
    }

    getValue(): string {
        return this.value;
    }

    onSelectChange(e): void {
        setTimeout(function () {
            this.params.stopEditing();
            this.picker.close();
        }.bind(this));
    }
}
