import {
    OnInit,
    Component,
    Inject,
    ViewEncapsulation
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MultiLang } from './multi-lang.model';


@Component({
    selector: 'hc-multi-lang-dialog',
    templateUrl: './multi-lang-dialog.component.html',
    styleUrls: ['./multi-lang-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MultiLangDialogComponent implements OnInit {

    public multiValue: MultiLang;

    constructor(
        public dialogRef: MatDialogRef<MultiLangDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    async ngOnInit() {
        this.multiValue = <MultiLang>this.data.multi;
    }

    public save() {
        this.dialogRef.close(this.multiValue.toString());
    }

    public close() {
        this.dialogRef.close(null);
    }

    public toggle(e) {
        if (e.checked) {
            this.multiValue.toMulti();
        } else {
            this.multiValue.toSingle();
        }
    }
}
