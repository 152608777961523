import { Injectable }               from '@angular/core';
import { ISortableFilter }          from '../common/sortable-filter';
import { Globals }                  from '../common/globals';
import { HttpClient }               from '@angular/common/http';
import { Observable }               from 'rxjs';
import { CommonService }            from './common.service';

@Injectable()
export class LocationService {

    private _commonService: CommonService;


    constructor(http: HttpClient, private globals: Globals) {
        this._commonService = new CommonService(http, globals, 'location');
    }

    public getItems(filter: ISortableFilter): Observable<any> {
        return this._commonService.get('list', filter);
    }

    public getDetail(id: number): Observable<any> {
        return this._commonService.get('detail/' + id);
    }

    public insertItem(item: any): Observable<any> {
        return this._commonService.insertItem(item);
    }

    public updateItem(id: string, item: any): Observable<any> {
       return this._commonService.updateItem(id, item);
    }

    public deleteItem(id: string): Observable<any> {
        return this._commonService.deleteItem(id);
    }

    public getNutsDetail(nuts: string): Observable<any> {
        return this._commonService.get('nuts/detail/' + nuts);
    }

    public getNuts(filter: string): Observable<Array<any>> {
        return this._commonService.get('nuts?filter=' + filter);
    }
    public getNutsForParent(level: number, parent: string): Observable<Array<any>> {
        return this._commonService.get('nuts2/' + level + '/' + parent);
    }
    public getAttributeTypes() {
        return this._commonService.get('attributeTypes');
    }
    public getTimeZones(): Observable<any[]> {
        return this._commonService.get('timezones');
    }
}
