import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseComponent } from './base.component';
import { Globals } from './globals';
import { TranslationService } from '../shared/translation.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<BaseComponent<any> | ComponentCanDeactivate> {

  constructor(
    private globals: Globals,
    private trans: TranslationService
  ) {

  }

    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
      this.globals.currentNavData().customData = (<BaseComponent<any>>component).model;
      if (!component || !component.canDeactivate) {
        if (!!component) {
          console.log('Component does not have implemented deactivation:' + component.constructor.name);
        } else {
          console.log('Component not specified');
        }
        return true;
      }

    // if there are no pending changes, just allow deactivation; else confirm first
      let canContinue = component.canDeactivate() ? true : confirm(this.trans.instant('admin.web.ask-leave-unsaved'));

    // if (!canContinue) {
    //   let dialogRef = this.dialog.open(SaveGuardDialogComponent);
    //   canContinue = dialogRef.afterClosed();
    // }

      // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
      // when navigating away from your angular app, the browser will show a generic warning message
      // see http://stackoverflow.com/a/42207299/7307355

      if (!canContinue) {
        this.globals.revertNavigation();
      }
      return canContinue;
  }
}
