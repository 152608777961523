import { Component,
         OnInit,
         Inject,
         ChangeDetectorRef,
         ViewEncapsulation }              from '@angular/core';
import { TemplateService }                from '../../../shared/template.service';
import { TranslationService }             from '../../../shared/translation.service';
import { Observable }                     from 'rxjs';
import { Globals }                        from '../../../common/globals';
import { isNullOrWhitespace }             from '../../../common/functions';
import { DomSanitizer }                   from '@angular/platform-browser';
import { tap }                            from '../../../../../node_modules/rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'hc-template-picker',
  templateUrl: './template-picker.component.html',
  styleUrls: ['./template-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TemplatePickerComponent implements OnInit {

  public templates = new Observable<any>();
  public loading = true;

  constructor(
    public dialogRef: MatDialogRef<TemplatePickerComponent>,
    private service: TemplateService,
    private cd: ChangeDetectorRef,

    private globals: Globals,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
      this.templates =
        this.service.getItems(null).pipe(tap(_ => {
          this.loading = false;
          if (!this.cd['destroyed']) { this.cd.detectChanges(); }
        }));
  }

  private getImageUrl(guid: string) {
    if (isNullOrWhitespace(guid)) { return ''; }

    let tenantIdParam = '?TenantID=' + this.globals.getTenantID();
    // tslint:disable-next-line:max-line-length
    return this.sanitizer.bypassSecurityTrustStyle('url(\'' +  this.globals.GetUrlPrefix() + '/api/documentstorage/' + guid + tenantIdParam + '\')');
  }

  private selectItem(item) {
    this.data = item;
    this.dialogRef.close(item);
  }
}
