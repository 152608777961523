import { FilterDialogType }             from '../enums/filter-dialog-type.enum';
import { FilterDialogItem }             from './filter-dialog-item.model';
import { ElasticFilterRequest }         from '../../elastic-filter-request.model';
import { ElasticFilterElement }         from '../../elastic-filter-element.model';
import { SortDirection }                from '../../sortable-filter';
import { DataTypeEnum }                 from '../../data-type.enum';
import { FilterViewModel }              from '../../../setting/filter/filter-view.model';
import * as moment_                     from 'moment';
import { FilterDialogItemValues } from './filter-dialog-filter-value.model';
import { QuestionAnswerType, AnswerModel } from '../../../survey/survey.model';
import { isNullOrUndefined } from '../../functions';

const moment = moment_;

export class FilterDialog {

    public filterDialogID: number;
    public dialogType: FilterDialogType;
    public name: string;
    public displayOrder: number;
    public filterDialogItems: FilterDialogItem[];
    public filters: FilterViewModel[];

    constructor(
        filterDialogID: number,
        dialogType: FilterDialogType,
        name: string,
        displayOrder: number,
        filterDialogItems: FilterDialogItem[],
        filters: FilterViewModel[]
    ) {
        this.filterDialogID = filterDialogID || 0;
        this.dialogType = dialogType;
        this.name = name || '';
        this.displayOrder = displayOrder || 0;
        this.filterDialogItems = filterDialogItems || [];
        this.filters = filters;
    }

    public static parse(serialized: any, filters: FilterViewModel[]): FilterDialog {
        let filterDialog: FilterDialog = new FilterDialog(
            serialized.filterDialogID,
            serialized.dialogType,
            serialized.name,
            serialized.displayOrder,
            [],
            filters
        );
        if (serialized.filterDialogItems) {
            serialized.filterDialogItems.forEach(filterDialogItem => {
                let filter = filters.find(f => filterDialogItem.filterID === f.filterID);
                filterDialog.filterDialogItems.push(FilterDialogItem.parse(filterDialogItem, filter));
            });
            filterDialog.fixDisplayOrders();
        }
        return filterDialog;
    }

    public add(...items: FilterDialogItem[]) {
        this.filterDialogItems.unshift(...items);
        this.fixDisplayOrders();
    }

    public remove(...itemsToRemove: FilterDialogItem[]) {
        let indexes: number[] = [];
        itemsToRemove.forEach((filterDialogItemToRemove: FilterDialogItem) => {
            let foundIndex: number = this.filterDialogItems.findIndex((filterDialogItem: FilterDialogItem) => {
                return filterDialogItemToRemove.displayOrder === filterDialogItem.displayOrder;
            });
            if (foundIndex > -1) {
                indexes.push(foundIndex);
            }
        });
        indexes.forEach(i => this.filterDialogItems.splice(i, 1));
        this.fixDisplayOrders();
    }

    public fixDisplayOrders() {
        this.filterDialogItems.forEach((filterDialogItem: FilterDialogItem, index: number) => {
            filterDialogItem.displayOrder = index + 1;
        });
    }

    public clear() {
        this.filterDialogItems = [];
    }

    public fixSurveyValues() {
        this.filterDialogItems.forEach(filterDialogItem => {
            filterDialogItem.filterDialogItemValues.forEach(fv => {
                if (fv.filterInputType.dataType === DataTypeEnum.Survey) {
                    fv.value = fv.serializeSurveyValue();
                }
            });
        });
    }

    public get id(): number {
        return this.filterDialogID;
    }

    public set id(id: number) {
        this.filterDialogID = id;
    }

    public getFilter(): ElasticFilterRequest {
        let request = new ElasticFilterRequest('_doc', SortDirection.asc);
        this.filterDialogItems.forEach((cid, index) => {
            let filterElement = new ElasticFilterElement();
            filterElement.filterName = cid.name;
            cid.filterDialogItemValues.forEach(fv => {
                switch (fv.filterInputType.dataType) {
                    case DataTypeEnum.ShortDate:
                        fv.value = this.convertToShortDate(fv.value);
                        filterElement.filterParams[fv.filterInputType.placeholderName] = fv.value;
                        break;
                    case DataTypeEnum.Survey:
                        let key = Object.keys(JSON.parse(fv.value))[0];
                        filterElement.filterParams[fv.filterInputType.placeholderName] = key;
                        let result = JSON.parse(fv.value);
                        if (typeof(result[key]) === 'boolean') {
                            filterElement.filterParams[(fv.filterInputType.placeholderName + '_answers')] = result.key;
                        } else {
                            filterElement.filterParams[(fv.filterInputType.placeholderName + '_answers')] = result[key].join(' ');
                        }
                        break;
                    default: filterElement.filterParams[fv.filterInputType.placeholderName] = fv.value;
                }
                // filterElement.filterParams[fv.filterInputType.placeholderName] = fv.value;
            });
            if (filterElement.filterParams.length === 0 || // either no parameters, or parameter with filled value
                Object.values(filterElement.filterParams).some((paramValue: string) => paramValue.length > 0)) {
                request.filters.push(filterElement);
            }
        });
        return request;
    }

    private convertToShortDate(dateTimeString: string): string {
        if (isNullOrUndefined(dateTimeString) || dateTimeString.length === 0) {
            return '';
        }
        return moment(dateTimeString).format('YYYY-MM-DD');
    }

    private parseSurveyValues(fv: FilterDialogItemValues): string {
        if (fv.surveyQuestion) {
            switch (fv.surveyQuestion.answerType) {
                case QuestionAnswerType.OneToTen:
                    if (fv.range) {
                        let range = fv.range.slice(),
                            result: number[] = [];
                        range.sort((a, b) => a - b);
                        for (let i = range[0]; i <= range[1]; i++) {
                            result.push(i);
                        }
                        return result.join(',');
                    }
                    break;
                case QuestionAnswerType.Stars5:
                    if (fv.range) {
                        let range = fv.range.slice(),
                            result: number[] = [];
                        range.sort((a, b) => a - b);
                        for (let i = range[0]; i <= range[1]; i++) {
                            result.push(i);
                        }
                        return result.join(',');
                    }
                    break;
                case QuestionAnswerType.OneOfOptions:
                    return fv.surveyQuestionAnswers.map(qa => qa.isActive).join(',');
                case QuestionAnswerType.MoreOfOptions:
                    return fv.surveyQuestionAnswers.map(qa => qa.isActive).join(',');
                case QuestionAnswerType.YesNo:
                    return fv.surveyQuestionAnswers[0].isActive.toString();
            }
        } else {
            return '';
        }
    }

    public get serverModel(): any {
        let model = {
            filterDialogID: this.id,
            dialogType: this.dialogType,
            name: this.name,
            displayOrder: this.displayOrder,
            filterDialogItems: []
        };
        model.filterDialogItems = this.filterDialogItems.map(cid => {
            return cid.serverModel;
        });
        return model;
    }
}
