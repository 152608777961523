import { trigger, animate, style, group, animateChild, query, stagger, transition, state } from '@angular/animations';

export const routerTransition = trigger('routerTransition', [
    state('0', style({ })),
    state('1', style({ })),
    transition(
      '* => 0', [ // FORWARD
    /* 1 */ query(':enter', style({ position: 'fixed', flex: 1, opacity: 0 }), { optional: true }),
            query(':leave', style({ position: 'fixed', flex: 1, opacity: 1 }), { optional: true }),
    /* 2 */ group([  // block executes in parallel
      query(':leave', [
            style({ transform: 'translateX(0%)', opacity: 1 }),
            animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)', opacity: 0.5 }))
        ], { optional: true }),
        query(':enter', [
            style({ transform: 'translateX(100%)', opacity: 1}),
            animate('0.5s ease-in-out', style({ transform: 'translateX(0%)', opacity: 1 }))
          ], { optional: true })
    ])
  ]),
  transition(
      '* => 1', [ // BACK
    /* 1 */ query(':enter', style({ position: 'fixed', flex: 1, opacity: 0 }), { optional: true }),
            query(':leave', style({ position: 'fixed', flex: 1, opacity: 1 }), { optional: true }),
    /* 2 */ group([  // block executes in parallel
      query(':leave', [
          style({ transform: 'translateX(0%)', opacity: 1 }),
          animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)', opacity: 0.5 }))
      ], { optional: true }),
      query(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 1 }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)', opacity: 1 }))
      ], { optional: true }),
    ])
  ])
]);

/*
export const routerTransition = trigger('routerTransition', [
    state('0', style({ })),
    state('1', style({ })),
    transition(
      '* => 0', [ // FORWARD
    query(':enter', style({ position: 'fixed', width: '100%', opacity: 0 }), { optional: true }),
            query(':leave', style({ position: 'fixed', width: '100%', opacity: 1 }), { optional: true }),
    group([  // block executes in parallel
      query(':leave', [
            style({ transform: 'translateX(0%)', opacity: 1 }),
            animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)', opacity: 0.5 }))
        ], { optional: true }),
        query(':enter', [
            style({ transform: 'translateX(100%)', opacity: 1}),
            animate('0.5s ease-in-out', style({ transform: 'translateX(0%)', opacity: 1 }))
          ], { optional: true })
    ])
  ]),
  transition(
      '* => 1', [ // BACK
    query(':enter', style({ position: 'fixed', width: '100%', opacity: 0 }), { optional: true }),
            query(':leave', style({ position: 'fixed', width: '100%', opacity: 1 }), { optional: true }),
    group([  // block executes in parallel
      query(':leave', [
          style({ transform: 'translateX(0%)', opacity: 1 }),
          animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)', opacity: 0.5 }))
      ], { optional: true }),
      query(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 1 }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)', opacity: 1 }))
      ], { optional: true }),
    ])
  ])
])
*/
