import { Component,
         Input,
         Optional,
         Host,
         OnInit,
         ChangeDetectionStrategy}         from '@angular/core';
import { SatPopover }     from '@ncstate/sat-popover';
import { filter }         from 'rxjs/operators';

@Component({
  selector: 'hc-inline-textarea',
  styleUrls: ['inline-textarea.component.scss'],
  templateUrl: 'inline-textarea.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineTextareaComponent implements OnInit {

  @Input()
  get value(): string { return this._value; }
  set value(x: string) {
    this.editVal = this._value = x;
  }
  private _value = '';

  /** Form model for the input. */
  editVal = '';

  constructor(@Optional() @Host() public popover: SatPopover) { }

  ngOnInit() {
    // subscribe to cancellations and reset form value
    if (this.popover) {
      this.popover.closed.pipe(filter(val => val == null))
        .subscribe(() => this.editVal = this.value || '');
    }
  }

  onSubmit() {
    if (this.popover) {
      this.popover.close(this.editVal);
    }
  }

  onCancel() {
    if (this.popover) {
      this.popover.close();
    }
  }
}
