export class ElasticFilterElement {

    public filterID: number;
    public filterName = '';
    public filterParams: any = {};   // Key value pairs

    constructor(filterName?: string, filterParams?: any) {
        this.filterName = filterName || '';
        this.filterParams = filterParams || {};
    }
}
