import {
    AfterViewInit,
  ChangeDetectorRef,
    Component
  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { Globals } from '../globals';
import { MultiLangDialogComponent } from './multi-lang-dialog.component';
import { MultiLang } from './multi-lang.model';

  @Component({
    selector: 'hc-multi-lang-editor',
    template: `<div></div>`
  })
  export class MultiLangEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public multiValue: MultiLang;

    constructor(
        public dialog: MatDialog,
        public globals: Globals) {
        this.multiValue = new MultiLang(this.globals.getSupportedLanguages);
    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
        const dialogRef = this.dialog.open(MultiLangDialogComponent, {
            width: '560px',
            data: { multi: this.multiValue }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                this.multiValue.parse(result);
                this.getValue();
                this.params.api.refreshCells();
            }
        });

    }

    agInit(params: any): void {
        this.params = params;
        this.multiValue.parse(params.value);
    }

    getValue(): string {
      const text = this.multiValue.toString();
      this.params.data[this.params.colDef.field] = text;
      this.params.value = text;
      return text;
    }

    onKeyDown(event): void {
        event.stopPropagation();
    }
  }
