import * as moment_ from 'moment';
import { DateRangeEnum } from './date-range.enum';
import { $enum } from 'ts-enum-util';
const moment = moment_;

export class DateRange {
    since: Date;
    till: Date;
    period = DateRangeEnum.today;

    dateRangeEnum = DateRangeEnum;
    dateRangeEnumKeys = [];

    constructor() {
        this.dateRangeEnumKeys = $enum(DateRangeEnum).getKeys();
    }

    public get locKey(): string {
        let dre = DateRangeEnum;
        return 'admin.web.chart-period-' + dre[this.period];
    }

    public initialzeFor( val: DateRangeEnum ) {
        this.period = val;
        let po = this.getDatePeriodOptions();
        this.since = po[this.period].start.toDate();
        this.till = po[this.period].end.toDate();
    }

    public initializeFromRange( val: DateRange ) {
        // due to the different serializations the range can came as e.g 3 or '3' or 'last_week'
        if (typeof val.period === 'string') {
            if (Number(val.period).toString() === val.period) {
                this.period = Number(val.period);
            } else {
                this.period = Number(DateRangeEnum[val.period]);
            }

        } else {
            this.period = val.period;
        }
        if (this.period === DateRangeEnum.custom) {
            this.since = new Date( val.since );
            this.till = new Date( val.till );
        }
        this.initialize();
    }

    public initialize() {
        if (this.period !== DateRangeEnum.custom) {
            let r = this.getDatePeriodOptions()[this.period];
            if (r) {
                this.since = r.start.toDate();
                this.till = r.end.toDate();
            } else {
                this.since = null;
                this.till = null;
            }
        }
    }

    public getDatePeriodOptions(): any {
        let res = {};

        // TCh
        // DEV-2752
        // TimeRange should be always startDay-00:00:00 - endDay-23:59:59 
        // endDay time part is by default replaced by 23:59:59 further in code, so it has no impact for search, but it has imact for UI
        // that's why I have to add -1 second (otherwise +1 day caused confusion in UI for clients)
        // "today" has included tomorrow as well - and that's not correct
        res[DateRangeEnum.today] = { start: moment().startOf('day'), end: moment().startOf('day').add(1, 'day').add(-1, 'second') };
        res[DateRangeEnum.last_days] = { start: moment().startOf('day').subtract(3, 'day'),
                                         end: moment().startOf('day').add(1, 'day').add(-1, 'second') };
        res[DateRangeEnum.this_week] = { start: moment().startOf('isoWeek'),
                                         end: moment().startOf('isoWeek').add(1, 'week').add(-1, 'second') };
        res[DateRangeEnum.last_week] = { start: moment().startOf('isoWeek').subtract(1, 'week'),
                                         end: moment().startOf('isoWeek').subtract(1, 'week').add(1, 'week').add(-1, 'second') };
        res[DateRangeEnum.last_weeks] = { start: moment().startOf('isoWeek').subtract(2, 'week'),
                                          end: moment().startOf('day').add(1, 'day').add(-1, 'second') };
        res[DateRangeEnum.this_month] = { start: moment().startOf('month'),
                                          end: moment().startOf('month').add(1, 'month').add(-1, 'second') };
        res[DateRangeEnum.last_month] = { start: moment().startOf('month').subtract(1, 'month'),
                                          end: moment().startOf('month').subtract(1, 'month').add(1, 'month').add(-1, 'second') };
        res[DateRangeEnum.last_months] = { start: moment().startOf('month').subtract(2, 'month'),
                                           end: moment().startOf('day').add(1, 'day').add(-1, 'second') };
        res[DateRangeEnum.this_year] = { start: moment().startOf('year'),
                                          end: moment().startOf('year').add(1, 'year').add(-1, 'second') };
        res[DateRangeEnum.last_year] = { start: moment().startOf('year').subtract(1, 'year'),
                                         end: moment().startOf('year').subtract(1, 'year').add(1, 'year').add(-1, 'second') };
        res[DateRangeEnum.last_years] = { start: moment().startOf('year').subtract(2, 'year'),
                                          end: moment().startOf('day').add(1, 'day').add(-1, 'second') };
        res[DateRangeEnum.custom] = { unlock: true };

        return res;
/*
        return {
            'today': ,

            'last_days': ,

            'last_week': ,

            'last_weeks': ,

            'last_month': ,

            'last_months': ,

            'last_year': ,

            'last_years': ,

            'custom':
        }; */
    }
}
