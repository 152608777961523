export enum AggregateFunction {
    none = 0,
    groupBy = 1,
    sum = 2,
    max = 3,
    min = 4,
    count = 5,
    avg = 6,
    hide = 7
}
