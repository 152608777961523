import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * @title Dialog Overview
 */
@Component({
  selector: 'hc-error-dialog',
  template:
  `
  <div fxLayout="column" style="min-height: 150px;">
        <p fxFlex [innerHtml]="data.message | keepHtml">
        </p>
        <div fxFlex="none"  fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-around center">
            <button mat-raised-button color="warn" (click)="clickOk()" >{{ 'admin.web.ok' | trans }}</button>
        </div>
  </div>
   `
})

export class ErrorDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    clickOk(): void {
        this.dialogRef.close(true);
    }
}
