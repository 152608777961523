import { Injectable }               from '@angular/core';
import { ISortableFilter, SortableFilter }          from '../common/sortable-filter';
import { Globals }                  from '../common/globals';
import { HttpClient } from '@angular/common/http';
import { Observable }               from 'rxjs';
import { map }                      from 'rxjs/operators';
import { CommonService }            from './common.service';
import { IBaseService }             from './service.interface';
import { ProductSetModel }          from '../product/product-set/product-set.model';


@Injectable()
export class ProductSetService implements IBaseService<ProductSetModel> {

    private _commonService: CommonService;

    constructor(private http: HttpClient, private globals: Globals) {
        this._commonService = new CommonService(http, globals, 'productSet');
    }

    public getDetail(id: string): Observable<ProductSetModel> {
        throw new Error('Method not implemented.');
    }

    public getList(activeProductSetsOnly: boolean): Observable<ProductSetModel[]> {
        return this._commonService.get('?activeOnly=' + activeProductSetsOnly);
    }

    public select(filter: ISortableFilter): Observable<any> {
        return this._commonService.post('search', filter);
    }

    public getProductSet(id: number): Observable<any> {
        return this._commonService.get('detail/' + id).pipe(map(o => {
            return o;
        }));
    }

    public insertItem(item: ProductSetModel): Observable<any> {
        return this._commonService.insertItem(item);
    }

    public updateItem(id: string, item: ProductSetModel): Observable<any> {
       return this._commonService.updateItem(id, item);
    }

    public deleteItem(id: string): Observable<any> {
        return this._commonService.deleteItem(id);
    }

    public getPdf(id, type: string, desiredName: string): Observable<any> {
        return this._commonService.get('pdf/' + id + '?type=' + type + '&desiredName=' + desiredName);
    }

    public create(name: string, productIDs: any[]): Observable<any> {
        let body: any = {
            name: name,
            productIDs: productIDs
        };
        return this._commonService.post('create', body);
    }

    public addToWhiteList(productSetId: string, productIDs: any[]): Observable<any> {
        let body: any = {
            productSetId: productSetId,
            productIDs: productIDs
        };
        return this._commonService.post('addToWhiteList', body);
    }

    public getProducts(productSetId: number, filter: ISortableFilter): Observable<any> {
        let bodyData: any = {
            productSetId: productSetId,
            sortColumn: 'ProductNumber.keyword',
            sortDirection: filter.sortDirection,
            pageSize: filter.pageSize,
            page: filter.page,
            searchString: filter.searchString
        };
        return this._commonService.post('products', bodyData);
    }

    public removeWhiteListMember(productSetId: number, productId: number): Observable<any> {
        return this._commonService.deleteItem('removeWhiteListMember/' + productSetId + '/' + productId);
    }

    public removeWhiteListMembers(productSetId: number, productIds: number[]): Observable<any> {
        return this._commonService.post('removeWhiteListMembers/' + productSetId, productIds);
    }

    public clearWhiteList(productSetId: string): Observable<any> {
        return this._commonService.get('clearWhiteList/' + productSetId);
    }

    public suggest(searchString: string, maxCount: number): Observable<any> {
        return this._commonService.get('suggest/' + searchString + '?maxCount=' + maxCount);
    }
}
