import { Component }              from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'hc-saving-dialog',
  template: `
    <div class="saving-cloak">
      <div class="progress">
        <div style="width:50px; text-align: center">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <!--<div style="width:50px; text-align: center">{{'admin.web.in-progress' | trans}}</div>-->
      </div>
    </div>
  `
})
export class SavingDialogComponent {

  constructor(public dialogRef: MatDialogRef<SavingDialogComponent>) {
  }
}
