import {
    Component,
    ChangeDetectorRef,
    Input,
    Output,
    EventEmitter,
    OnChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Globals } from '../globals';
import { MultiLangDialogComponent } from './multi-lang-dialog.component';
import { MultiLang } from './multi-lang.model';



@Component({
    selector: 'hc-multi-lang',
    templateUrl: './multi-lang.component.html'
})
export class MultiLangComponent implements OnChanges {

    @Input()
    public placeholder: string;

    @Input()
    public required: boolean;

    @Input()
    public disabled: boolean;

    @Input()
    public value: string;

    @Output() valueChange = new EventEmitter<string>();

    public multiValue: MultiLang;

    constructor(
        public dialog: MatDialog,
        public globals: Globals,
        public cd: ChangeDetectorRef,
      ) {
        this.multiValue = new MultiLang(this.globals.getSupportedLanguages);
      }

    ngOnChanges() {
        this.multiValue.parse(this.value || '');
        this.cd.detectChanges();
    }

    change(event) {
        let content = event.srcElement.value;
        this.multiValue.parse(content);
        this.valueChange.emit(content);
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(MultiLangDialogComponent, {
            width: '560px',
            data: { multi: this.multiValue }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                this.value = result;
                this.valueChange.emit(result);
            }
          });

    }
}