import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { switchMap, debounceTime, tap, finalize } from 'rxjs/operators';
import { ProductListItem, ProductImageModel } from '../product.model';
import { ProductsService } from 'src/app/shared/products.service';
import { TranslationService } from 'src/app/shared/translation.service';
import { Globals } from '../globals';
import { HCurrencyPipe } from 'src/app/shared/hcurrency.pipe';
import { HcLocalStorage } from '../local-storage';

@Component({
    selector: 'hc-product-suggester',
    templateUrl: './product-suggester-component.html',
    styleUrls: ['./product-suggester-component.scss']
})
export class ProductSuggesterComponent implements OnInit {

    @Output() public onProductSelected: EventEmitter<ProductListItem> = new EventEmitter();
    @Input() public label = 'admin.web.search';
    @Input() public clearAfterSelection = true;
    @Input() public display: any;

    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    foundProducts: ProductListItem[] = [];
    searchForm: FormGroup;
    isLoading = false;
    @Input() public focusSearch = true;

    constructor(private fb: FormBuilder,
                private service: ProductsService,
                private trans: TranslationService,
                private hcurrency: HCurrencyPipe,
                private hcLocalStorage: HcLocalStorage,
                private cd: ChangeDetectorRef,
                private globals: Globals) {
    }

    ngOnInit() {
        this.searchForm = this.fb.group({
            searchInput: this.display
        });

        this.searchForm
            .get('searchInput')
            .valueChanges
            .pipe(
                debounceTime(200),
                tap(() => this.isLoading = true),
                switchMap(async value => {
                        if (typeof value === 'object') {
                            this.isLoading = false;
                            return [];
                        }
                        return await this.service.getSuggestions(value, 20)
                        .pipe(
                            finalize(() => {
                                this.isLoading = false;
                            }),
                        ).toPromise()
                        .catch(_ => { return []; });
                    }
                )
            )
            .subscribe(res => {
                this.foundProducts = res;
                this.cd.detectChanges();
            }, err => {
            }
            );
    }

    async onFocus($event) {
        if (!this.focusSearch) {
            return;
        }
        this.isLoading = true;
        this.cd.markForCheck();
        this.cd.detectChanges();

        this.foundProducts = await this.service.getSuggestions(' ', 20)
                               .toPromise()
                               .catch(_ => { return []; });
        this.isLoading = false;
        this.cd.markForCheck();
        this.cd.detectChanges();
    }

    public valueSelected($event) {
        if (!$event.option) {
            return;
        }
        let product = <ProductListItem>$event.option.value;
        if (this.onProductSelected) {
            this.onProductSelected.emit(product);
        }
        if (this.clearAfterSelection) {
            this.searchInput.nativeElement.value = '';
            this.searchInput.nativeElement.blur();
        } else {
            // this.searchInput.nativeElement.value = $event.option.viewValue; // this can be object, not a string
        }
    }

    public displayFn(product: ProductListItem) {
        if (product) { return product.productNumber + ' - ' + product.name; }
    }

    public getImageURL(product: ProductListItem) {
        if (!product.productImages || product.productImages.length === 0) {
            return '';
        }
        let p = product.productImages.find( pi => pi.isPrimary);
        if (p) {
            return this.imageUrl(p);
        }
        return this.imageUrl(product.productImages[0]);
    }

    public getPrice(product: ProductListItem) {
        if (!product.productVariants || product.productVariants.length === 0) {
            return '';
        }
        let v = product.productVariants.find( pv => pv.isPrimary);
        if (v) {
            return this.hcurrency.transform(v.retailPrice || 0, this.hcLocalStorage.companyCurrencyISOCode);
        }
        return '';
    }

    private imageUrl(image: ProductImageModel): string {
        if (!image) { return null; }

        if (image.imageRecordUID) {
            let tenantIdParam = '&TenantID=' + this.globals.getTenantID();
            let requestGuid = image.imageRecordUID;
            return this.globals.GetUrlPrefix() + '/api/documentstorage/' + requestGuid
                + '?anticache=' + '0' + tenantIdParam;
        } else {
            return image.imageURL;
        }
    }
}
