import { ISortableFilter, SortableFilter, SortDirection }          from '../common/sortable-filter';
import { IBaseService }             from './service.interface';
import { Observable }               from 'rxjs';
import { CommonService }            from './common.service';
import { HttpClient }               from '@angular/common/http';
import { Globals }                  from '../common/globals';
import { ProductVariableViewModel } from '../planner/slotplan/slotplan-view.model';
import { Injectable }               from '@angular/core';

@Injectable()
export class ProductVariableService implements IBaseService<ProductVariableViewModel> {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'productvariable');
    }

    public getItems(): Observable<Array<ProductVariableViewModel>> {
        let filter = new SortableFilter('name', SortDirection.asc);
        filter.pageSize = 1000;
        return this._commonService.get('list', filter);
    }

    deleteItem(id: any): Observable<any> {
        return this._commonService.deleteItem(id + '');
    }
    insertItem(item: any): Observable<any> {
        return this._commonService.post('', item);
    }
    updateItem(id: any, item: any): Observable<any> {
        return this._commonService.updateItem(id + '', item);
    }
    getDetail(id: string): Observable<ProductVariableViewModel> {
        return this._commonService.get('detail/' + id);
    }
}
