import { FilterDialog }             from './filter-dialog.model';
import { FilterViewModel }          from '../../../setting/filter/filter-view.model';
import { FilterInputType }          from './filter-input-type.model';
import { EventEmitter } from 'events';

export class FilterDialogConfig {

    public filterDialog: FilterDialog;
    public filterTypes?: FilterViewModel[];
    public filterInputTypes?: FilterInputType[];
    public isNew?: boolean;
    public filterMethod: Function;
    public onSearch?: Function;
    public onSaved?: Function;
}
