export enum TableColumnType {
    Text,
    Number,
    Money,
    Date,
    Time,
    DateTime,
    DateTime2,
    Guid,
    Bool,
    DayOfMonth,
    Array,
    HTML,
    None,
    Button,
    LocalizableString,
    Image,
    MenuButton,
    Selector,
    ClickCell
}
