import { Injectable }                   from '@angular/core';
import { Router,
         CanActivate,
         ActivatedRouteSnapshot }       from '@angular/router';
import { Globals }                      from './globals';
import { NgxPermissionsService }        from 'ngx-permissions';
import { AuthService }                  from '../shared/auth.service';
import { JwtHelperService }             from '@auth0/angular-jwt';
import { MatSnackBar } from '@angular/material/snack-bar';

const jwtHelper = new JwtHelperService();

@Injectable()
export class AuthGuard implements CanActivate {

  private lastTimestamp: number = null;
  private snackTimeout: any;
  private logoutTimeout: any;
  private logoutTimeoutSnack: any;

  private minutesToLogout: 5;

  constructor(
    private router: Router,
    private globals: Globals,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private permissionService: NgxPermissionsService
    ) {
      this.lastTimestamp = new Date().getTime();
    }

  tokenValid() {
    return !jwtHelper.isTokenExpired(this.globals.getToken());
  }

  canActivate(route: ActivatedRouteSnapshot) {
    if (!!this.snackTimeout) {
      clearTimeout(this.snackTimeout);
    }
    if (!!this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
    }

    if (!!this.logoutTimeoutSnack) {
      this.logoutTimeoutSnack.dismiss();
    }

    let token = this.globals.getToken();
    if (this.tokenValid()) {
      this.handleSlidingExpiration();

      if (!!route.data.Permission) {
        if (!this.permissionService.hasPermission(route.data.Permission.Only)) {
          this.router.navigate(['/login']);
          return false;
        }
      }

      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }

  private handleSlidingExpiration() {
    let token = this.globals.getToken();
    if (!token) { return; }
    let dToken = jwtHelper.decodeToken(token);
    let refreshLimit = new Date((dToken.iat + (dToken.exp - dToken.iat) / 2) * 1000);

    if (new Date() > refreshLimit) {
      // this.authService.refreshToken().subscribe(res => console.log("Token refreshed"));
    }
  }
}
