import { Component, OnInit, ChangeDetectorRef, Output,
         EventEmitter, Input, ViewChild, ElementRef, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { switchMap, debounceTime, tap, finalize, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserListItem } from '../user.model';

@Component({
    selector: 'hc-universal-suggester',
    templateUrl: './universal-suggester-component.html',
    styleUrls: ['./universal-suggester-component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UniversalSuggesterComponent implements OnInit, AfterViewInit {

    @Output() public onItemSelected: EventEmitter<UserListItem> = new EventEmitter();
    @Input() public label = 'admin.web.search';
    @Input() public clearAfterSelection = true;
    @Input() public searchCallback = (input, maxCount) => <Observable<any>>{};
    @Input() public displayFn = (input) => { return this.display; };
    @Input() public imageFn = (input) => { return this.imageUrl; };
    @Input() public maxCount = 20;
    @Input() public focusSearch = true;
    @Input() public disabled = false;

    @Input() public display: any;
    @Input() public imageUrl: any;

    @ViewChild('searchInput', {static: true}) searchInputElem: ElementRef;
    items: any[] = [];
    public searchForm = new FormGroup(
        {
            searchInput: new FormControl()
        }
    );
    isLoading = false;

    constructor(private cd: ChangeDetectorRef) {
    }

    ngOnInit() {
    }

    async onFocus($event) {
        if (!this.focusSearch || this.disabled) {
            return;
        }
        this.isLoading = true;
        this.cd.markForCheck();
        this.cd.detectChanges();
        this.items = await this.searchCallback(' ', this.maxCount)
                               .toPromise()
                               .catch(_ => { return []; });
        this.isLoading = false;
        this.cd.markForCheck();
        this.cd.detectChanges();
    }

    ngAfterViewInit() {
        this.setInput(this.display);
        const inputControl = this.searchForm.get('searchInput');
        let changeRef = this.cd;

        let htmlElem = document.getElementById(this.searchInputElem.nativeElement.id) as HTMLInputElement;

        if (!!htmlElem) { // htmlElem can be null when not on active tab
            htmlElem.value = this.display;
        }

        inputControl.valueChanges
            .pipe(
                debounceTime(200),
                tap(() => {
                    this.isLoading = true;
                    }
                ),
                switchMap(async value =>
                    this.items = await this.searchCallback(value, this.maxCount)
                    .pipe(
                        finalize(() => {
                            this.isLoading = false;
                        }),
                    ).toPromise()
                    .catch(_ => { return []; })
                )
            )
            .subscribe(res => {
                this.items = res;
                changeRef.detectChanges();
            }, err => {
            }
            );

        this.cd.markForCheck();
        this.cd.detectChanges();
    }

    public setInput(display: string) {
        const inputControl = this.searchForm.get('searchInput');
        inputControl.setValue(display);
        inputControl.patchValue(display);
        this.searchInputElem.nativeElement.value = display;
    }

    public valueSelected($event) {
        if (!$event.option || this.disabled) {
            return;
        }
        let selectedValue = $event.option.value;
        if (this.onItemSelected) {
            this.onItemSelected.emit(selectedValue);
        }

        const inputControl = this.searchForm.get('searchInput');
        let htmlElem = document.getElementById(this.searchInputElem.nativeElement.id) as HTMLInputElement;

        if (this.clearAfterSelection) {
            inputControl.setValue('');
            this.searchInputElem.nativeElement.value = '';
            // this.searchInputElem.nativeElement.focus();
            this.searchInputElem.nativeElement.blur();
            htmlElem.value = '';
        } else {
            inputControl.setValue($event.option.viewValue);
            this.searchInputElem.nativeElement.value = $event.option.viewValue;
            htmlElem.value = $event.option.viewValue;
        }
    }
}
