import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtModule } from '@auth0/angular-jwt';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { TranslationService } from './shared/translation.service';
import { BrowserAnimationsModule }  from '@angular/platform-browser/animations';
import { ActionRowComponent } from './action-row/action-row.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HelpDialogComponent } from './common/help-dialog/help-dialog.component';
import { MenuComponent } from './menu/menu.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './shared/auth.service';
import { Globals } from './common/globals';
import { HcLocalStorage } from './common/local-storage';
import { KeyValueService } from './shared/keyvalue.service';
import { AuthGuard } from './common/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyService } from './shared/company.service';
import { ComponentService } from './shared/component.service';
import { SharedModule } from './shared.module';
import { TreeModule } from 'angular-tree-component';
import { FilterService } from './shared/filter.service';
import { MediaService } from './shared/media.service';
import { SettingService } from './shared/setting.service';
import { InterfaceService } from './shared/interfaces.service';
import { SlotPlanService } from './shared/slot-plan.service';
import { EventSourceService } from './shared/event-source.service';
import { ProductVariableService } from './shared/product-variable.service';
import { ActionRowSaveOptionsComponent } from './action-row-save-options/action-row-save-options.component';
import { ApiCaller } from './shared/honeycomb-api/api-caller';
import { Honeycomb } from './shared/honeycomb-api/honeycomb-api';
import { MenuDynamicComponent } from './menu-dynamic/menu-dynamic.component';
import { MenuDynamicItemComponent } from './menu-dynamic/menu-dynamic-item.component';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { AGGridLicenser } from './common/report-grid/tools/ag-grid-license';
import { ReportGridSetup } from './common/report-grid/tools/report-grid-setup';

export function tokenGetter() {
  return sessionStorage.id_token;
}

@NgModule({
    declarations: [
        AppComponent,
        // Pages
        HomeComponent,
        LoginComponent,
        NavigationComponent,
        // Small components
        MenuComponent,
        MenuDynamicComponent,
        MenuDynamicItemComponent,
        // Pipes
        // Dialogs
        HelpDialogComponent,
        ActionRowComponent,
        ActionRowSaveOptionsComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: ['localhost:8371', 'localhost:8377'] // ports 80 and 443 are whitelisted by default
            }
        }),
        HttpClientModule,
        NgxPermissionsModule.forRoot(),
        TreeModule.forRoot(),
        // Material
        BrowserAnimationsModule,
        MatNativeDateModule,
        SharedModule.forRoot()
    ],
    providers: [
        Globals,
        TranslationService,
        KeyValueService,
        HcLocalStorage,
        AGGridLicenser,
        FilterService,
        AuthService,
        AuthGuard,
        CompanyService,
        ComponentService,
        MediaService,
        SettingService,
        InterfaceService,
        SlotPlanService,
        EventSourceService,
        ProductVariableService,
        ReportGridSetup,
        {
            provide: 'PBIReportController',
            useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Reports.IService.PBIController(apiCaller)),
            deps: [ApiCaller]
        },
        {
          provide: 'ClubService',
          useFactory: (apiCaller: ApiCaller) => (new Honeycomb.Tenant.Contact.IService.Controller.ClubController(apiCaller)),
          deps: [ApiCaller]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

  /*
  constructor(public appRef: ApplicationRef) {}
  hmrOnInit(store) {
    console.log('HMR store', store);
  }
  hmrOnDestroy(store) {
    let cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
    // recreate elements
    store.disposeOldHosts = createNewHosts(cmpLocation);
    // remove styles
    removeNgStyles();
  }
  hmrAfterDestroy(store) {
    // display new elements
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }
  */
}
