
import { Report } from '../model/report.model';
import { GridDataSource } from '../tools/report-grid.datasource';
import { ReportDataModel } from '../model/report-data-model.enum';
import { ReportColumnSortDirection } from '../model/report-column-sort-direction.enum';
import { AggregateFunction } from '../model/aggregate-function.enum';
import { ReportColumnPinType } from '../model/report-column-pin-type.enum';
import { TranslationService } from '../../../shared/translation.service';
import { ReportGridComponent } from '../report-grid.component';
import { DataTypeEnum } from '../../data-type.enum';
import { Globals } from '../../globals';
import { DetailRouting } from './detail-routing';
import { ReportColumn } from '../model/report-column.model';
import { ReportAction } from '../model/report-action-model';
import { CreateClubDialogComponent } from '../../../user/club/create-club-dialog.component';
import { ClubCreationDataModel } from '../../../user/club/club-creation-data-model';
import { CreateProductSetDialogComponent } from '../../../product/product-set/creation/create-product-set-dialog.component';
import { EmailDialogModel } from '../../email-dialog-model';
import { EmailDialogComponent } from '../../dialog.email.component';
import { GridOptions, ColDef, GetContextMenuItemsParams, CellClickedEvent,
         CellClassParams, ValueGetterParams, GridReadyEvent, RowNode, GridApi, ColumnState } from '@ag-grid-enterprise/all-modules';
import { Honeycomb } from 'src/app/shared/honeycomb-api/honeycomb-api';
import { MatDialog } from '@angular/material/dialog';

export class DetailReportGridHandler {

    public paginationPageSize = 1000;
    public currentRecordCount = 0;
    public columns: ColDef[] = [];
    private lastGridOptions: GridOptions;

    constructor(
        private detailFilterMapping: {},
        private component: ReportGridComponent,
        private globals: Globals,
        private detailReport: Report,
        private trans: TranslationService,
        private dialog: MatDialog
        ) {
    }

    public getDetailRowData( e ) {

        let fixedFilterData = {};
        // the detail filter map contains name of the source column in the key and ID of the queryable column in the value
        Object.keys( this.detailFilterMapping ).forEach (k => {
            fixedFilterData[this.detailFilterMapping[k]] = e.data[k];
        });
        // the detail report data source can't be used as a class member
        // because there can be several details opened at once and the detail content
        // is loaded dynamically when the detail grid is shown
        // this is the reason, why the datasource is created dynamically
        let dataSource = new GridDataSource( this.component,
                                             this.detailReport,
                                             this.paginationPageSize,
                                             () => {}, // the load callback of the detail is not used
                                             () => {}); // the load callback of the detail is not used
                                     // detail report, run immediately
        dataSource.fixedFilterData = fixedFilterData;
        if (this.detailReport.reportDataModel === ReportDataModel.serverSide) {
            e.node.gridApi.setServerSideDatasource(dataSource);
        } else {
            dataSource.getAllData( (result) => {
                e.successCallback( result );
            });
        }
        // the datasource contains formatting options which are used in the cells Style and Class calbacks
        // the proble here can be, that the datasource will stay in memory foreever, at this moment, there is no way how to find out
        // that detail grid has been closed and the datasource can be destroyed

        this.lastGridOptions.context.dataSource = dataSource;
    }

    public createGridOptions(): GridOptions {
        let serverSideModel = (this.detailReport.reportDataModel === ReportDataModel.serverSide);
        let options = Object.assign( this.component.gridSetup.getDefaultOptions(), <GridOptions> {
            animateRows: true,
            rowSelection: 'multiple',
            onGridReady : (params: GridReadyEvent) => {
                this.finalizeGridBuild(params);
            },
            getChildCount : (data) => {
                if (data) {
                    return data['_count'];
                }
                return 0;
            },
            onCellClicked: (event: CellClickedEvent) => {
                if (event.colDef.type && event.colDef.type.indexOf( 'detail' ) > 0) {
                    this.processCellClickEvent(event);
                }
            },
            getContextMenuItems: (params: GetContextMenuItemsParams) => {
                let items = this.component.gridSetup.getContextMenuItems(undefined, params);
                if (this.detailReport.reportActions) {
                    this.detailReport.reportActions.forEach( a => {
                        let type = Honeycomb.Common.Enums.ReportActionType;
                        let ids = this.getSelectedRowIDs(params.api);
                        if (this.isSingleRowAction(a.action)) {
                            items.push({
                                name: this.trans.instant('admin.web.rep.action.' + type[a.action].toString()),
                                action: () => {
                                    this.processContextMenuActionForSingle(params, a);
                                }
                            });
                        } else {
                            if (ids.length > 0) {
                                items.push({
                                    name: this.trans.instant('admin.web.rep.action.' + type[a.action].toString()) +
                                            ' (' + ids.length.toString() + ')',
                                    action: () => {
                                        this.processContextMenuActionForSelected(params, a);
                                    }
                                });
                            }
                            items.push({
                                name: this.trans.instant('admin.web.rep.action.' + type[a.action].toString() + '.all') +
                                            ' (' + params.api.getModel().getRowCount() + ')',
                                action: () => {
                                    this.processContextMenuActionForAll(params, a);
                                }
                            });
                        }
                    });
                }
                return items;
            },
            rowGroupPanelShow : 'always',
            rowModelType: serverSideModel ? 'serverSide' : 'clientSide',
            cacheOverflowSize : 2,
            maxConcurrentDatasourceRequests : 1,
            infiniteInitialRowCount : this.paginationPageSize,
            paginationPageSize : this.paginationPageSize,
            cacheBlockSize : this.paginationPageSize,
            blockLoadDebounceMillis: 500,
            maxBlocksInCache : 10,
            defaultColDef : {
                enableValue: true,
                enableRowGroup: true,
                enablePivot: true,
                sortable: true,
                resizable: true
            },
            groupIncludeFooter: this.detailReport.showTotals,
            groupIncludeTotalFooter: this.detailReport.showTotals,

            masterDetail: false,
            columnTypes : <any>this.component.gridSetup.getColumnTypes()
        });
        options.context = this.detailReport;
        if (this.detailReport.rowHeight && this.detailReport.rowHeight !== 0) {
            options.rowHeight = this.detailReport.rowHeight;
        }
        this.lastGridOptions = options;
        return options;
    }

    public getSelectedRowIDs(api: GridApi): any[] {
        return this.gerRowIDsInternal(api.getSelectedNodes());
    }

    public getAllRowIDs(api: GridApi): any[] {
        let nodes = [];
        api.getModel().forEachNode( n => {
            nodes.push(n);
        });
        return this.gerRowIDsInternal(nodes);
    }

    private gerRowIDsInternal(nodes: RowNode[]) {
        let idcol = this.detailReport.reportColumns.find( c => c.rowIDColumn );
        if (!idcol) {
            throw 'the report with no ID column specified is being asked for selected IDs';
        }
        let res = [];
        nodes.forEach( n => {
            res.push( n.data[idcol.uniqueColumnName] );
        });
        return res;
    }

    private finalizeGridBuild(readyEvent: GridReadyEvent) {
        // build columns
        this.columns = [];
        let aggFunction = AggregateFunction;
        this.detailReport.reportColumns.forEach( (e, index) => {
            if (!e.hidden) {
                let cd = <ColDef>{
                    headerName: this.component.trans.instant( e.description ),
                    field: e.uniqueColumnName,
                    rowGroup: e.defaultGroupBy,
                    autoHeight: e.dynamicHeight,
                    type: this.component.gridSetup.getColumnType(e.dataType)
                };
                if (e.defaultValue) {
                    // when the default value is specified, setup a getter function
                    // which first checks the value and when empty, returns the default value
                    // watch out! the getter fuction is always called prior the formatter function

                    cd.valueGetter = (params: ValueGetterParams) => {
                        if (!params.data) {
                            return;
                        }
                        let val = params.data[params.colDef.field];
                        if (!val) {
                            return e.defaultValue;
                        }
                        return val;
                    };
                }
                if (e.reportDetailType) {
                    cd.type += ',detail';
                }
                if (e.aggregateFunction !== AggregateFunction.none &&
                    e.aggregateFunction !== AggregateFunction.groupBy &&
                    e.aggregateFunction !== AggregateFunction.hide) {
                    cd.aggFunc = aggFunction[e.aggregateFunction];
                }
                if (e.pinType !== ReportColumnPinType.none) {
                    let pt = ReportColumnPinType;
                    cd.pinned = pt[e.pinType];
                }
                if (e.dataType === DataTypeEnum.CodeList) {
                    cd.refData = this.component.gridSetup.getCodeListRefData(e.codeListID);
                }

                cd.cellStyle = (params: any) => {
                    if (!params.context.dataSource) {
                        return null;
                    }
                    return params.context.dataSource.getStyle( index, params.node.rowIndex );
                };
                let origClass = cd.cellClass;
                if (!origClass) {
                    origClass = '';
                } else {
                    origClass += ',';
                }
                origClass += this.component.gridSetup.getColumnTypeClasses( cd.type );
                cd.cellClass = (params: CellClassParams) => {
                    if (!params.context.dataSource) {
                        return origClass;
                    }
                    return params.context.dataSource.getClass( index, params.rowIndex, origClass );
                };
                this.columns.push(cd);
            }
        });
        this.paginationPageSize = this.detailReport.defaultPageSize;
        if (this.detailReport.showSelectionCheckbox) {
            this.columns[0].checkboxSelection = true;
            if (this.detailReport.reportDataModel === ReportDataModel.clientSide) {
                this.columns[0].headerCheckboxSelection = true;
            }
        }
        if (this.component.onColumnsInitialized) {
            this.component.onColumnsInitialized.emit(<any>this.columns);
        }
        readyEvent.api.setColumnDefs( this.columns );
        readyEvent.api.sizeColumnsToFit();
        let sortByColumns = this.detailReport.reportColumns.filter( c => c.defaultSortDirection !== ReportColumnSortDirection.undefined )
                                                     .sort( c => c.defaultSortOrder );

        let sortDir = ReportColumnSortDirection;
        if (sortByColumns.length > 0) {
            let defaultSortModel = {
                state: []
            };
            defaultSortModel.state = [];
            sortByColumns.forEach( c => {
                defaultSortModel.state.push( {
                    colId: c.uniqueColumnName,
                    sort: sortDir[c.defaultSortDirection]
                });
            });
            readyEvent.columnApi.applyColumnState(defaultSortModel);
        }
    }

    private processCellClickEvent(event: CellClickedEvent) {
        let repCol = this.detailReport.reportColumns.find( rc => rc.uniqueColumnName === event.colDef.field);
        if (!repCol.reportDetailType) {
            return;
        }

        let dr = DetailRouting.getDetailRoute( repCol.reportDetailType );
        let idCol = this.findColumnByColName( dr.idCol );
        if (!idCol) {
            throw 'The required detail column: ' + dr.idCol + ' not found';
        }
        let row = event.api.getModel().getRow(event.rowIndex);
        let id = row.data[idCol.uniqueColumnName];
        let url = dr.url.replace('{id}', id);
        this.globals.navigateTo(url);
    }


    private isSingleRowAction( action: Honeycomb.Common.Enums.ReportActionType): boolean {
        if (action === Honeycomb.Common.Enums.ReportActionType.showEmailDetail) {
            return true;
        }
        return false;
    }

    private processContextMenuActionForSingle( params: GetContextMenuItemsParams, action: ReportAction ) {
        let cell = params.api.getFocusedCell();
        let node = params.api.getModel().getRow(cell.rowIndex);

        switch (action.action) {
            case Honeycomb.Common.Enums.ReportActionType.showEmailDetail:
                this.showEmailDetail(node.data);
                break;
        }
    }

    private processContextMenuActionForSelected( params: GetContextMenuItemsParams, action: ReportAction ) {
        let count = this.getSelectedRowIDs(params.api).length;
        if (count === 0) {
            let cell = params.api.getFocusedCell();
            let node = params.api.getModel().getRow(cell.rowIndex);
            node.setSelected(true);
            count++;
        }

        switch (action.action) {
            case Honeycomb.Common.Enums.ReportActionType.addUserToClub:
                this.addUsersToClub(params, false);
                break;
            case Honeycomb.Common.Enums.ReportActionType.addProductToSet:
                this.addProductsToSet(params, false);
                break;
        }
    }


    private processContextMenuActionForAll( params: GetContextMenuItemsParams, action: ReportAction ) {
        let count = params.api.getModel().getRowCount();

        switch (action.action) {
            case Honeycomb.Common.Enums.ReportActionType.addUserToClub:
                this.addUsersToClub(params, true);
                break;
            case Honeycomb.Common.Enums.ReportActionType.addProductToSet:
                this.addProductsToSet(params, true);
                break;
        }
    }

    private addUsersToClub(params: GetContextMenuItemsParams, addAll: boolean) {
        let ids: any[];
        if (addAll) {
            ids = this.getAllRowIDs(params.api);
        } else {
            ids = this.getSelectedRowIDs(params.api);
        }
        let dialogRef = this.dialog.open(CreateClubDialogComponent,
                                        // never create temp table for the detail
                                        { data: <ClubCreationDataModel>{ rowIDs: ids,
                                                                         createTempTable: false }});
    }

    private addProductsToSet(params: GetContextMenuItemsParams, addAll: boolean) {
        let dialogRef = this.dialog.open(CreateProductSetDialogComponent);
        if (addAll) {
            dialogRef.componentInstance['productIDs'] = this.getAllRowIDs(params.api);
        } else {
            dialogRef.componentInstance['productIDs'] = this.getSelectedRowIDs(params.api);
        }
    }

    private showEmailDetail(rowData: any) {
        let col = this.findColumnByColName('DistributionBatchItemID');
        if (!col) {
            console.log('Can not show email detail beacuse the DistributionBatchItemID column is missing');
        }
        let itemId = rowData[col.uniqueColumnName];
        this.component.mediaService.distributedMediaDetail(itemId).subscribe( r => {
            let dialogRef = this.dialog.open(EmailDialogComponent,
                {data: <EmailDialogModel>{
                    subject: r.subject,
                    sender: r.sender,
                    htmlContentSrc: this.component.sanitizer.bypassSecurityTrustResourceUrl(// 'url(\'' +
                                    this.component.mediaService.getUrlPrefix() +
                                    'distributedMediaContent/' +
                                    r.encryptedDistributionItemID +
                                    '?tenantID=' + this.globals.getTenantID() )// + '\')')
                }});
        });
    }

    private findColumnByColName(name: string): ReportColumn {
        return this.detailReport.reportColumns.find(c => c.queryableColumn.name.toLowerCase() === name.toLowerCase());
    }
}

