import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from '../common/nav-item.model';
import { PortalAgenda } from '../interface/interface.model';
import { DeleteDialogComponent } from '../common/dialog.delete.component';
import { ErrorDialogComponent } from '../common/dialog.error.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'hc-navigation-options',
    templateUrl: './navigation-options.component.html',
    styleUrls: ['./navigation-options.component.scss']
})
export class NavigationOptionsComponent implements OnInit {

    @Input() items: MenuItem[];

    @Input() selectedIDs: any[];

    @Input() agenda: PortalAgenda;

    @Input() hideExports = false;

    @ViewChild('childMenu', {static: true}) public childMenu;

    constructor(
      public dialog: MatDialog
    ) {
    }

    ngOnInit() {
    }

    public async menuItemClick(options: MenuItem) {
        if (options.confirmText == null) {
            options.callback();
            return;
        }

        let deleteDialogRef = this.dialog.open(DeleteDialogComponent, { data: { confirmText: options.confirmText } });
        let res = await deleteDialogRef.afterClosed().toPromise();
        if (!!res) {
          try {
            options.callback();
          } catch {
            this.dialog.open(ErrorDialogComponent, { data: { message: 'admin.web.delete-failed' } });
          }
        }
    }
}
