import {
  OnInit,
  Component,
  Output,
  Inject,
  Input,
  forwardRef,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  EventEmitter,
  ViewChild,
  ViewContainerRef,
  ChangeDetectorRef
} from '@angular/core';
import { MultilangInputDialogComponent } from './multilang-input-dialog.component';
import { MultilangInputModel, LangRow } from './multilang-input.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslationService } from 'src/app/shared/translation.service';
import { Globals } from '../globals';
import { isNullOrUndefined, isNullOrWhitespace } from '../functions';
import { catchError, map, tap } from 'rxjs/operators';
import { Honeycomb } from 'src/app/shared/honeycomb-api/honeycomb-api';
import { GridApi } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'hc-multilang-input',
  templateUrl: './multilang-input.component.html',
  styleUrls: ['./multilang-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // tslint:disable-next-line: no-use-before-declare no-forward-ref
      useExisting: forwardRef(() => MultilangInputComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultilangInputComponent implements OnInit, ControlValueAccessor {

  @Input()
  public placeholder: string;

  @Input()
  public locKeyPrefix: string;

  @Input()
  public entityID: string;

  @Input() disabled = false;

  @Input() simpleInput = false;

  public inputValue: string;

  @Output()
  public dialogClosed: EventEmitter<MultilangInputModel> = new EventEmitter();

  @Output()
  public valueChanged: EventEmitter<MultilangInputModel> = new EventEmitter();

  langModel: MultilangInputModel;


  @ViewChild('searchInput', { read: ViewContainerRef, static: false }) public searchInput;

  @ViewChild('simpleInputElement', { read: ViewContainerRef, static: false }) public simpleInputElement;

  public gridApi: GridApi;

  constructor(
    public dialog: MatDialog,
    public trans: TranslationService,
    public globals: Globals,
    public cd: ChangeDetectorRef,
    // private locService: TenantLocalizationService,
    // tslint:disable-next-line: max-line-length
    @Inject('TenantLocalizationController') private tenantLocController: Honeycomb.Tenant.LookupTables.IService.TenantLocalizationController,
  ) {
    // this.currentLang = this.globals.getLanguage();
  }

  async ngOnInit() {

  }

  async onMultilangIconClick($event: FocusEvent) {
    if (!this.langModel || !this.langModel.langs || this.langModel.langs.length === 0) {

      if (!this.langModel) {
        this.langModel = <MultilangInputModel>{};
      }

      this.langModel.langs = this.globals.getSupportedLanguages.map(l => {
        if (!!this.langModel.langs) {
          let origLang = this.langModel.langs.find(ll => ll.lang === l);
          if (!!origLang) {
            return origLang;
          }
        }

        let lRow = <LangRow>{
          lang: l,
          text: this.langModel.pureText
        };
        return lRow;
      });
    }
    let dialogRef = this.dialog.open(MultilangInputDialogComponent, {
      hasBackdrop: true,
      data: {
        position: {
          left: '10px',
          top: '10px'
        },
        placeholder: this.placeholder,
        inputs: this.langModel
      }
    });

    let dialogRes = await lastValueFrom(dialogRef.beforeClosed());

    if (!!dialogRes) {
       let res = <Array<LangRow>>dialogRes;
      this.inputValue = res[0].text;
      this.langModel.pureText = res[0].text;
      this.writeValue(this.langModel);
      if (!!this.dialogClosed) {
        this.dialogClosed.emit(this.langModel);
        if (!!this.langModel) {
          this.onChange(this.langModel);
        }
        this.cd.markForCheck();
      }
      if(this.gridApi) {
        this.gridApi.redrawRows();
      }
    }
  }

  // Function to call when the rating changes.
  onChange = (model: MultilangInputModel) => { 
    if (!model.locKey) { // create loc key, if multilanf input dialog show over null input
      model.locKey = this.locKeyPrefix + '.' + this.entityID;
    }
    this.valueChanged.emit(model);
  };

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => { };

  // Allows Angular to update the model (rating).
  // Update the model and changes needed for the view here.
  async writeValue(value: any) {
    if (!value) {
      return;
    }
    if (!!value && typeof value !== 'object' && value.startsWith(this.locKeyPrefix)) {
      // this.inputValue = this.trans.instant(value);
      let locResult = await this.tenantLocController.Get(this.globals.getLanguage(), value)
                                      .toPromise();

      if (!!locResult) {
        this.inputValue = locResult.value;
      }
      let langRows = this.globals.getSupportedLanguages;

      this.langModel = <MultilangInputModel>{
        langs: [],
        locKey: this.locKeyPrefix + '.' + this.entityID
      };

      langRows.forEach(async l => {
        const text = await this.tenantLocController.Get(l, encodeURI(this.langModel.locKey)).toPromise();
        let lRow = <LangRow>{
          lang: l,
          text: !!text ? text.value : value
        };
        this.langModel.langs.push(lRow); // async, can take some time
        this.setInputEnabled();
      });
    } else {
      if ( typeof value !== 'object' && value.startsWith(this.locKeyPrefix)) {
        this.inputValue = this.trans.instant(value);
        let langRows = this.globals.getSupportedLanguages.map(l => {
          let lRow = <LangRow>{
            lang: l,
            text: value
          };
          return lRow;
        });
        this.langModel = <MultilangInputModel>{
          locKey: this.locKeyPrefix + '.' + this.entityID,
          langs: langRows
        };
        this.setInputEnabled();
      } else if (Array.isArray(value)) {
        this.langModel.pureText = value[0].text;
        this.langModel.langs = value;
      } else if (!!value && value.pureText) {
        this.langModel = value;
        this.inputValue = this.trans.instant(value.pureText);
      } else if (value.langs) {
          this.langModel = value;
          this.inputValue = this.getTranslatedSelectedLabel(value);
      } else {
          this.langModel = <MultilangInputModel>{
            pureText: value,
            locKey: this.locKeyPrefix + '.' + this.entityID,
          };
          this.inputValue = value;
      }
    }

    this.setInputEnabled();

    if (!!this.langModel) {
      this.onChange(this.langModel);
    }
    this.cd.markForCheck();
  }
  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (rating: MultilangInputModel) => void): void {
    this.onChange = fn;
  }
  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private getTranslatedSelectedLabel(model: MultilangInputModel): string {
    if (model.langs && model.langs.length > 0) {
      const selectedLang = model.langs.find(l => l.lang === this.globals.getLanguage());
      if (!selectedLang) {
        return '';
      }
      if (!!selectedLang.text && selectedLang.text.startsWith(this.locKeyPrefix)) {
        selectedLang.text = this.trans.instant(selectedLang.text);
      }
      return selectedLang.text;
    }
    return '';
  }

  public inputChanged($event) {
    console.log($event);

    if (!!this.langModel && !!this.langModel.langs) {
      this.langModel.langs[0].text = $event.target.value;
      this.langModel.pureText = $event.target.value;
    } else {
      this.langModel = <MultilangInputModel>{
        pureText: $event.target.value
      };
    }
    this.onChange($event.target.value);
  }

  private setInputEnabled() {
    if (this.langModel.langs && this.langModel.langs.length > 0) {
      let el = this.searchInput.element.nativeElement as HTMLElement;
      this.disabled = true;
    } else {
      this.disabled = false;
    }
    this.cd.detectChanges();
  }

  public simpleInputChanged($event) {
    if (!!this.langModel && !!this.langModel.langs) {
      this.langModel.langs[0].text = $event.target.value;
      this.langModel.pureText = $event.target.value;
    } else {
      this.langModel = <MultilangInputModel>{
        pureText: $event.target.value
      };
    }
    this.onChange($event.target.value);
  }

  public async storeLocalization() {
    if (!this.langModel || !this.langModel.langs || this.langModel.langs.length === 0 ||
      isNullOrWhitespace(this.locKeyPrefix) || isNullOrUndefined(this.entityID)) {
        return;
    }
    let list = this.langModel.langs.map(l => {
      return <Honeycomb.LookupTables.IService.Model.Localization>{
        key: this.langModel.locKey,
        languageISOCode: l.lang,
        value: l.text
      };
    });

    return this.tenantLocController.UpdateList({ list })
      .pipe(
        catchError((err) => {
            console.error(err);
            return null;
        }),
        map(() => {
            return this.langModel.locKey;
        })
    ).pipe(
      tap(l => {
        const lang = this.globals.getLanguage();
        this.globals.langChanged.emit({ lang, noCache: 1, disableContentUpdate: true });
    })).toPromise();
  }
}
