import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    AfterViewInit,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    ViewEncapsulation
} from '@angular/core';

@Component({
    selector: 'hc-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FileUploadComponent implements OnInit, AfterViewInit {

    @Input() showFileName = true;
    
    private _assignedFileName: string = null;

    @Input() set assignedFileName(assignedFileName: string) {
        if (this._assignedFileName !== assignedFileName) {
            this.cd.markForCheck();
        }
        this._assignedFileName = assignedFileName;
        this.model.newFileName = assignedFileName;
    }

    @Input() public showDragAndDrop = true;
    @Input() public accept = '';
    @Input() public icon = 'cloud_upload';
    @Input() public label = 'admin.web.choose-file';
    @Output() public fileUploaded = new EventEmitter<File>();

    @ViewChild('dragDropHolder', {static: false}) dragDropHolder: ElementRef;

    @ViewChild('uploadprogress', {static: true}) uploadprogress: ElementRef;

    @ViewChild('textInput', {static: true}) textInput: ElementRef;


    public placeholderText: string = null;

    public progress: number;

    constructor(
        private cd: ChangeDetectorRef) {

    }

    public model: any = {
        newFileName: null
    };

    private tests = {
        filereader: false,
        dnd: false,
        formdata: false,
        progress: false
    };

    public inputClick(fileElem) {
        fileElem.value = '';
        fileElem.click();
    }

    public fileChanged(event) {
        let files = event.currentTarget.files;
        if (files[0]) {
            this.model.newFileName = files[0].name;
            this.fileUploaded.emit(files[0] as File);
        } else {
            this.model.newFileName = null;
        }
    }

    ngOnInit(): void {
        this.tests.filereader = typeof FileReader !== 'undefined';
        this.tests.dnd = 'draggable' in document.createElement('span');
        this.tests.formdata = !!(<any>window).FormData;
        this.tests.progress = 'upload' in new XMLHttpRequest;
    }

    ngAfterViewInit(): void {
    }

    previewfile(file) {
        if (this.tests.filereader === true) {
            let reader = new FileReader();
            let dragDropHolder = this.dragDropHolder;
            reader.onload = function () {
                let image = new Image();
                // image.src = event.target.result;
                image.width = 250; // a fake resize
                dragDropHolder.nativeElement.appendChild(image);
            };
            reader.readAsDataURL(file);
        } else {
            // tslint:disable-next-line:no-bitwise
            this.dragDropHolder.nativeElement.innerHTML += '<p>Uploaded ' + file.name + ' '
                                                            + (file.size ? (file.size / 1024) + 'K' : '');
            console.log(file);
        }
    }

    private readfiles(files) {
        let formData = this.tests.formdata ? new FormData() : null;
        for (let i = 0; i < files.length; i++) {
            if (this.tests.formdata) {
                formData.append('file', files[i]);
            }
            this.fileUploaded.emit(files[i]);
            // previewfile(files[i])
        }
    }

    public onDrop(event) {
        this.dragDropHolder.nativeElement.className = '';
        event.preventDefault();
        this.readfiles(event.dataTransfer.files);
    }

    public onDragOver(event) {
        this.dragDropHolder.nativeElement.className = 'hover';
        event.stopPropagation();
        event.preventDefault();
    }

    public onDragEnd(event) {
        this.dragDropHolder.nativeElement.className = '';
        event.stopPropagation();
        event.preventDefault();
    }
}
