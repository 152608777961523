import { Component,
         Input,
         Optional,
         Host,
         OnInit,
         ChangeDetectionStrategy,
         ChangeDetectorRef}         from '@angular/core';
import { SatPopover }     from '@ncstate/sat-popover';
import { filter }         from 'rxjs/operators';
import { DataTypeEnum } from './data-type.enum';
import { HttpClient } from '@angular/common/http';
import { Globals } from './globals';
import { CommonService } from '../shared/common.service';
import { isNullOrWhitespace } from './functions';

@Component({
  selector: 'hc-inline-universal',
  styleUrls: ['inline-universal.component.scss'],
  templateUrl: 'inline-universal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineUniversalComponent implements OnInit {

  @Input()
  get value(): any { return this._value; }
  set value(x: any) {

    if (this.editorType === DataTypeEnum.Boolean) {
      if (x === 'true') { x = true; }
      if (x === 'false') { x = false; }
      this.boolEditVal = x;
    }

    if (this.editorType === DataTypeEnum.Text) {
      this.textEditVal = x;
    }

    if (this.editorType === DataTypeEnum.Number ||
        this.editorType === DataTypeEnum.IntegerNumber ||
        this.editorType === DataTypeEnum.Money) {
      this.numberEditVal = Number(x);
    }

    if (this.editorType === DataTypeEnum.CodeList) {
      this.codeListEditVal = x;
    }
  }
  private _value = null;

  public dataType = DataTypeEnum;

  private supportedDataTypes =
    [
      DataTypeEnum.Boolean,
      DataTypeEnum.Text,
      DataTypeEnum.Number,
      DataTypeEnum.IntegerNumber,
      DataTypeEnum.Money]
    ;

  @Input()
  caption = '';

  @Input()
  public blob = false;

  @Input()
  public codeListId: number;

  /** Form model for the input. */
  public textEditVal = null;

  public boolEditVal = null;

  public numberEditVal = null;

  public codeListEditVal = null;

  public codes: any[];

  public service: CommonService;

  public fallback = false;


  @Input()
  editorType: DataTypeEnum = DataTypeEnum.Text;

  constructor(
    @Optional()
    @Host()
    public popover: SatPopover,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private globals: Globals,
  ) {
  }

  async ngOnInit() {

    // subscribe to cancellations and reset form value
    if (this.popover) {
      this.popover.closed.pipe(filter(val => val == null))
        .subscribe( () => {
          this.cd.markForCheck();
        }
        // () => this.editVal = !!this.value
        );

      this.popover.opened.subscribe(async _ => {
        if (this.editorType === DataTypeEnum.CodeList) {
          if (Number.isSafeInteger(this.codeListId)) {
            this.service = new CommonService(this.http, this.globals, 'codelist');
            await this.service.get('detail/' + this.codeListId).toPromise().catch(() => this.codes = []).then(v => this.codes = v.codes);
          } else {
            this.codes = [];
          }
        }

        this.fallback = true;

        if (this.editorType !== DataTypeEnum.Text && this.supportedDataTypes.indexOf(this.editorType) > -1) {
          this.fallback = false;
        }
        this.cd.detectChanges();
      });
    }
  }

  onSubmit() {
    if (this.popover) {

      if (this.editorType === DataTypeEnum.Boolean) {
        this._value = this.boolEditVal ? 'true' : 'false';
      }

      if (this.editorType === DataTypeEnum.Number ||
          this.editorType === DataTypeEnum.IntegerNumber ||
          this.editorType === DataTypeEnum.Money) {
            this._value = this.numberEditVal + '';
      }

      if (this.editorType === DataTypeEnum.CodeList) {
        this._value = this.codeListEditVal;
      }

      if (this.editorType === DataTypeEnum.Text || this.fallback) {
        this._value = this.textEditVal;
      }

      this.popover.close(this._value);
    }
  }

  onCancel() {
    if (this.popover) {
      this.popover.close();
    }
  }

  public getCodeKeyDisplay(code: any): string {
    if (!!code.codeValues) {
      let cVal = code.codeValues[this.globals.getLanguage()];
      if (!!cVal && !isNullOrWhitespace(cVal.displayValue)) { return cVal.displayValue; }
    }
    return code.codeKey;

  }
}
